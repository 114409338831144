import React, { useState, useRef, useEffect } from 'react';
import { Paper, Tabs, Tab, Box, Tooltip } from '@material-ui/core';
import {
  Dropzone,
  Draggable,
  state,
} from '../../../../libraries/react-page-maker';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import { getComputedStyle, getStyle } from '../../utils/style';
import { makeStyles } from '@material-ui/core/styles';
import { tabBar as property } from './property';
import TabBarPreview from './preview/NavBar';
import { GROUP_COMPONENT_NAMES } from '../../utils/configuration/componentName.contants';
import getIcon from '../../utils/configuration/icons';
import { TooltipWrapper } from '../common';
import { gBottomBarDefaultSchema } from './schema';
import { COMPONENT_OPTIONS_KEY } from '../../../../constants';

const defaultOption = [
  { label: 'GPS', icon: 'MyLocation' },
  { label: 'Travel', icon: 'CardTravel' },
  { label: 'Flight', icon: 'Flight' },
  { label: 'List', icon: 'FeaturedPlayList' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.color.white,
  },
  alignLeft: {
    alignItems: "start"
  },
  alignRight: {
    alignItems: "end"
  },
  scroller: {
    '& div': {
      overflowX: "scroll"
    }
  }
}));

const tabItem = React.forwardRef((props, tabbarRef) => {
  const propertyValue = props.propertyValue;
  const elementLable = propertyValue?.component?.placeholder;
  const [label, setLabel] = useState(
    propertyValue?.component?.placeholder ??
      defaultOption[props.i - 1]?.label ??
      'Label'
  );
  const [isEdit, setEdit] = useState(false);
  let textAlign =
    props.propertyValue?.text?.textAlign !== undefined
      ? props.propertyValue?.text?.textAlign
      : '';
  let alignText =
    textAlign === 'right'
      ? { justifyContent: 'flex-end', display: 'grid' }
      : textAlign === 'left'
      ? { justifyContent: 'flex-start', display: 'grid' }
      : {};
  React.useEffect(() => {
    if (tabbarRef && tabbarRef.current && !props.propertyValue.style) {
      const { value } = getComputedStyle({
        computedStyle: window.getComputedStyle(tabbarRef.current, null),
        componentName: props.j,
        componentId: props.i,
        gComponentName: GROUP_COMPONENT_NAMES.NAVBAR,
      });
      
      
      var val = value;
      if (defaultOption[props.i - 1]?.icon) {
        val.component.iconName = defaultOption[props.i - 1].icon;
        val.component.placeholder = defaultOption[props.i - 1].label;
      }
      props.updateCellPropertyValue(props.id, val, true);
    }
  }, [tabbarRef]);
  const onChange = async (value) => {
    await setLabel(value);
    await setEdit(true);
  };

  const updatelabel = async () => {
    if (isEdit) {
      if (label !== elementLable) {
        await setEdit(false);
        var newPropsvalue = propertyValue;
        newPropsvalue.component.placeholder = label;
        props.updateCellPropertyValue(props.id, newPropsvalue);
      }
    }
  };
  return (
    <>
    <Tooltip  
      arrow
      title = {props.propertyValue?.tooltip?.title || ""}
      placement = {props.propertyValue?.tooltip?.position || ""}
      PopperProps={{ disablePortal: true }}
    >
      <Tab
        ref={tabbarRef}
        dataId={props.id}
        id={props.propertyValue?.component?.id}
        className={props.propertyValue?.component?.classes || ''}
        onClick={(e) => {
          e.stopPropagation();
          props.onCellClick({...props,gComponentName: GROUP_COMPONENT_NAMES.NAVBAR}, tabbarRef, props.triggerAction.index);
        }}
        onDoubleClick={(e) => {
          e.stopPropagation();
          //props.triggerAction.handleChange(null,props.triggerAction.index);
        }}
        label={
          <>
            <span
              onDoubleClick={async (e) => {
                e.persist();
                const event = e;
                await setEdit(true);
                event.target.focus();
              }}
              contentEditable={isEdit ? 'true' : 'false'}
              onBlur={(e) => updatelabel()}
              onInput={(e) => onChange(e.currentTarget.innerText)}
            >
              {elementLable}
            </span>
          </>
        }
        icon={getIcon(propertyValue?.component?.iconName, getStyle(props.propertyValue, ['text']))}
        style={{
          ...alignText,
          textTransform: 'none',
          border: props.triggerAction.highlightGrid
            ? '2px solid #97C1FF'
            : '2px solid transparent',
          ...getStyle(props.propertyValue, ['text', 'layout', 'style']),
        }}
      />
    </Tooltip>
      {props.triggerAction.highlightGrid && (
        <MoreActionButton
          type={'Item'}
          showParentSelection={true}
          selectParent={(e) => {
            e.stopPropagation();
            props.triggerAction.selectParent();
          }}
          showActionButton={false}
          anchorElement={tabbarRef}
        />
      )}
    </>
  );
});

const DraggableTabBar = (props) => {
  const {
    id,
    uniquekey,
    showBasicContent,
    showPreview,
    dropzoneID,
    parentID,
    onSelect,
    type,
    iconComponent,
    setState,
    propertyValue,
    idocument,
    dropzoneProps,
    onDropZone,
    initialElements,
    fields,
    options = [1, 2, 3, 4],
    selectedComponent,
    ...rest
  } = props;
  const [label, setLabel] = useState(props.label);
  const [selectedTab, setSelectedTab] = useState(0);
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [isHover, setHover] = useState(false);
  const tabBarRef = useRef();
  const classes = useStyles();

  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    data.options = [{[COMPONENT_OPTIONS_KEY]:0,label: '',value: '',checked: false},
      {[COMPONENT_OPTIONS_KEY]:1,label: '',value: '',checked: false},
      {[COMPONENT_OPTIONS_KEY]:2,label: '',value: '',checked: false},
      {[COMPONENT_OPTIONS_KEY]:3,label: '',value: '',checked: false},
  ]
    return (
      <Draggable {...data}>
        {iconComponent}
        <TabBarPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const updateCellPropertyValue = async (cellId, newValue, initflag) => {
    if (!initflag) {
      var newPvalue = propertyValue;
      newPvalue[cellId] = newValue;
      await setState({ addAction: true });
      state.updateElement(
        id,
        dropzoneID,
        parentID,
        { propertyValue: newPvalue },
        () => {
          onSelect(
            {
              id,
              dropzoneID,
              parentID,
              cellId,
              type,
              label,
              property,
              parentPropertyValue: newPvalue,
              propertyValue: newPvalue[cellId],
            },
            false
          );
        }
      );
    } else {
      var newPvalue = propertyValue;
      newPvalue[cellId] = newValue;
      await setState({ addAction: true });
      state.updateElement(
        id,
        dropzoneID,
        parentID,
        { propertyValue: newPvalue },
        () => {}
      );
    }
  };

  const onCellClick = (target, ref, index = null) => {
    // console.warn('on cell click', ref);
    let cellId = target.id;
    let gComponentName= target.gComponentName;
    const property = {
      componentSection: [
        'ID',
        'Classes',
        'Label',
        'IconList',
        'IconPosition',
        'Link',
        'Enabled',
      ],
      gridSection: [],
      dataSource: [{ name: 'LinkTo' }],
      textSection: ['Size', 'Alignment', 'Decoration'],
      styleSection: ['Background', 'Opacity', 'Custom CSS'],
      layoutSection: ['Layout', 'Width', 'Height'],
      tooltipSection: ["event", "position", "title"],
      interactionSection: gBottomBarDefaultSchema({}).events
    };
    let styles = { ...(propertyValue ? propertyValue : defaultPropertyValue) };
    if (index !== null) {
      handleChange(null, index);
    }
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        cellId,
        type,
        label,
        property,
        parentPropertyValue: styles,
        propertyValue: styles[cellId],
        gComponentName
      },
      false
    );
  };

  if (showPreview) {
    const renderOptions = () => {

      let overideClasses = {}
      let textAlignClassName ;
      const textStyle = getStyle(props?.menuPagePropertyValue, ['text']);
      if (textStyle.textAlign === "left"){
        textAlignClassName = classes.alignLeft
      } else if(textStyle.textAlign === "right"){
        textAlignClassName = classes.alignRight
      }

      if (textAlignClassName){
        overideClasses.wrapper = textAlignClassName
      }

      if (props?.menuPagePropertyValue?.component?.menuStyle) {
        return props.menuPagePropertyValue.component.links.map((e, i) => (
          <Tab
            label={
              <span>
                {e.label}
              </span>
            }
            icon={e.iconName ? getIcon(e.iconName, getStyle(props?.menuPagePropertyValue, ['text'])) : null}
            style={{
              textTransform: 'none',
              ...(getStyle(props?.menuPagePropertyValue, [ 'text', 'layout', 'style'])),
            }}
            classes={overideClasses}
          />
        ))
      }

      return options.map((e, i) => (
        <Tab
          className={`${propertyValue['tab-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id]?.component?.classes || ''}`}
          id={propertyValue['tab-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id]?.component?.id}
          label={
            <span>
              {propertyValue['tab-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id]?.component
                ?.placeholder ??
                defaultOption[(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY])]?.label ??
                'Label ' + ((e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + 1)}
            </span>
          }
          icon={getIcon(
            propertyValue['tab-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id]?.component
              ?.iconName ??
            defaultOption[(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY])]?.icon ??
            'Image',
            getStyle(propertyValue['tab-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id], ['text'])
          )}
          style={{
            textTransform: 'none',
            ...(propertyValue['tab-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id]
              ? getStyle(propertyValue['tab-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id], [
                'text',
                'layout',
                'style',
              ])
              : {}),
          }}
        />
      ))
    }

    const getPropertyValue = () => {
      if (props?.menuPagePropertyValue?.component?.menuStyle) {
        return props?.menuPagePropertyValue
      }
      return propertyValue
    }

    return (
        <Paper 
          className={`${classes.root} ${getPropertyValue().component.classes??''}`} 
          style={{...getStyle(getPropertyValue(),['layout']),paddingTop: 0,paddingLeft: 0,paddingRight: 0,paddingBottom: 0}}
          square>
          <Tabs 
            style={{...getStyle(getPropertyValue(),['text','layout','style']),marginTop: 0,marginLeft: 0,marginRight: 0,marginBottom: 0}}
            value={selectedTab} onChange={handleChange} 
            indicatorColor="primary" 
            textColor="primary" 
            variant="scrollable" 
            scrollButtons="auto"
            centered={true}
            >
            {renderOptions()}
        </Tabs>
      </Paper>
    );
  }

  const onClick = (e) => {
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        uniquekey,
        type,
        label,
        property,
        propertyValue,
        options,
      },
      false,
      e
    );
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        uniquekey,
        type,
        label,
        property,
        propertyValue,
        isActions: true,
        position,
        options,
      },
      false,
      e
    );
  };

  const selectParent = () => {
    onClick(null);
  };

  return (
    <Tooltip  arrow
      title = {propertyValue?.tooltip?.title || ""}
      placement = {propertyValue?.tooltip?.position || ""}
      PopperProps={{ disablePortal: true }}
    >
    <Paper
      square
      id={`${id}_parent`}
      ref={tabBarRef}
      onMouseOut={(e) => {
        e.stopPropagation();
        setHover(false);
      }}
      onMouseOver={(e) => {
        e.stopPropagation();
        setHover(true);
      }}
      dataid={`${id}_parent`}
      className={`${classes.root} ${isHover && 'draggable-hover'} ${!selectedComponent?.cellId && selectedComponent?.id === id && 'highlight-component'} DTabbar ${propertyValue.component.classes??''}`}
      onClick={onClick}
      style={{
        ...getStyle(propertyValue, ['layout']),
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
        overflowX: "scroll"
      }}
    >
      <Tabs
        style={{
          ...getStyle(propertyValue, ['text', 'layout', 'style']),
          marginTop: 0,
          marginLeft: 0,
          marginRight: 0,
          marginBottom: 0,
          overflowX: "scroll"
        }}
        dropzoneID={dropzoneID}
        properties={JSON.stringify({ id, dropzoneID, parentID, label, type })}
        id={propertyValue ? propertyValue.component.id : `navbar_${uniquekey}`}
        dataid={`${id}_parent`}
        className={`${id} ${type} ${classes.scroller}`}
        value={selectedTab}
        indicatorColor='primary'
        textColor='primary'
        variant='fullWidth'
        scrollButtons='auto'
        onChange={handleChange}
        centered={true}
      >
        {options.map((e, i) => (
          <Dropzone
            {...dropzoneProps}
            initialElements={[]}
            id={'tab-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id}
            onDrop={onDropZone}
            onSelect={onSelect}
            key={`${id}-${(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY])}`}
            i={(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + 1}
            j={`navbar${uniquekey}_item`}
            setState={setState}
            onCellClick={onCellClick}
            updateCellPropertyValue={updateCellPropertyValue}
            placeholder=' '
            component={tabItem}
            className={'tab-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id}
            type={`Tab Item`}
            dropzoneCellID={`tab-header-${id}`}
            properties={JSON.stringify({
              id: 'tab-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id,
              dropzoneID: id,
              parentID: id,
              label: `Nav Item ${(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + 1}`,
              type: 'Nav Item',
            })}
            selectedComponent={selectedComponent}
            triggerAction={{
              options,
              index: i,
              highlightGrid:
                'tab-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id === selectedComponent?.cellId,
              selectParent: selectParent,
              handleChange: handleChange,
            }}
            propertyValue={
              propertyValue && propertyValue['tab-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id]
                ? propertyValue['tab-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id]
                : { component: { id: `NavBar${uniquekey}_item_${i + 1}` } }
            }
          />
        ))}
      </Tabs>
      {!selectedComponent?.cellId && selectedComponent?.id === id && (
        <MoreActionButton
          handleActions={handleActions}
          type={'NavBar'}
          anchorElement={tabBarRef}
        />
      )}
    </Paper>
    </Tooltip>
  );
};

export default DraggableTabBar;
