import React,{useRef, useState} from 'react';
import {Box,List,ListItem,ListSubheader, Tooltip} from '@material-ui/core';
import {Draggable,Dropzone,state} from '../../../../libraries/react-page-maker';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import {getComputedStyle,getStyle,replaceTemplateValues} from '../../utils/style';
import {listView as property} from './property';
import ListViewPreview from './preview/Listview';
import {textTemplate,listGridTemplate} from '../../template';
import {GROUP_COMPONENT_NAMES} from '../../utils/configuration/componentName.contants';
import { getMaxIDByType } from '../../../../helpers/app-designer';
import core from '../../../../libraries/react-page-maker/src/core/core';
import { TooltipWrapper } from '../common';
import { gListViewDefaultSchema } from './schema';
import { EVENTS_SCHEMA } from '../../utils/configuration/schema';
import { COMPONENT_OPTIONS_KEY } from '../../../../constants';

const NUMBER_OF_SHADOW_ITEMS = 3;
const MlistView = React.forwardRef((props,ref) => {
  const [isHover,setHover] = useState(false);
  let defaultStyle = {
    border: '2px solid #F0F2F7', // The default border color
    display: "block",
    ...getStyle(props.propertyValue, ['text', 'style', 'layout']),
    position: 'relative',
  };

  const [itemStyle, setItemStyle] = useState(defaultStyle);
  React.useEffect(() => {
    if(ref && ref.current && !props.propertyValue.style) {
        const {value} = getComputedStyle({
          computedStyle: window.getComputedStyle(ref.current,null),
          componentName: props.j,
          componentId: props.i,
          gComponentName: GROUP_COMPONENT_NAMES.LIST_VIEW,
        });
      var val=value;
      props.updateCellPropertyValue(props.id,val,true)
    }
  },[ref]);

  React.useEffect(() => {
    let _style = defaultStyle;
    if (props?.triggerAction?.highlightList || isHover) {
      _style.border = '2px solid #97C1FF';
    }
    setItemStyle(_style);
  }, [isHover, props?.triggerAction?.highlightList]);

  return (
    <ListItem
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
        props.onCellClick && props.onCellClick(props,ref);
      }}
      onMouseOut={(e) => {
        e.stopPropagation();
        setHover(false);
      }}
      onMouseOver={(e) => {
        e.stopPropagation();
        setHover(true);
      }}
      // style={
      //   props.propertyValue
      //     ? {
      //       ...getStyle(props.propertyValue,['text','style','layout']),
      //       position: 'relative',
      //       border: props.triggerAction?.highlightList || isHover ? '2px solid #97C1FF' : '2px dotted #F0F2F7',
      //     }
      //     : {
      //       position: 'relative',
      //       border: props.triggerAction?.highlightList || isHover ? '2px solid #97C1FF' : '2px dotted #F0F2F7',
      //     }
      // }
      style={itemStyle}
      {...props}
      className={`${props.className ? props.className : ''} ${props.propertyValue?.component?.classes || ''}`}
    >
      <div id={props.propertyValue?.component?.id} dataId={props.id} style={{display: 'none'}} />
      {props.children}
      {props.triggerAction?.highlightList && (
        <MoreActionButton
          type={'List-Item'}
          showParentSelection={true}
          showActionButton={false}
          selectParent={(e) => {
            e.stopPropagation();
            props.triggerAction.selectParent();
          }}
          anchorElement={ref}
        />
      )}
    </ListItem>
  );
});

const MListSubheader = React.forwardRef((props,ref) => {
  React.useEffect(() => {
    if(ref && ref.current && !props.propertyValue.style) {
        const {value} = getComputedStyle({
          computedStyle: window.getComputedStyle(ref.current,null),
          componentName: props.j,
          componentId: props.i,
          gComponentName: GROUP_COMPONENT_NAMES.LIST_VIEW,
        });
      var val=value;
      props.updateCellPropertyValue(props.id,val,true)
    }
  },[ref]);
  return (
    <ListSubheader
      onClick={(e) => {
        e.stopPropagation();
        props.onCellClick(props,ref);
      }}
      ref={ref}
      style={
        props.propertyValue
          ? {
            ...getStyle(props.propertyValue,['text','style','layout']),
            position: 'relative',
            border: props.triggerAction.highlightItem ? '2px solid #97C1FF' : '2px solid transparent',
          }
          : {
            position: 'relative',
            border: props.triggerAction.highlightItem ? '2px solid #97C1FF' : '2px solid transparent',
          }
      }
      {...props}
      className={`${props.className ? props.className : ''} ${props.propertyValue?.component?.classes || ''}`}
    >
      <div id={props.propertyValue?.component?.id} dataId={props.id} style={{display: 'none'}} />
      {props.children}
      {props.triggerAction.highlightItem && (
        <MoreActionButton
          type={'List-Header'}
          showParentSelection={true}
          selectParent={(e) => {
            e.stopPropagation();
            props.triggerAction.selectParent();
          }}
          anchorElement={ref}
          showActionButton={false}
        />
      )}
    </ListSubheader>
  );
});
const DraggableListView = (props) => {
  const {id,uniquekey,showBasicContent,fields,showPreview,dropzoneID,dropzoneProps,initialElements,onDropZone,parentID,onSelect,type,iconComponent,idocument,options = [{label: '',value: '',checked: false}],propertyValue,selectedComponent,setState,...rest} = props;
//
  const [label,setLabel] = useState(props.label);
  const [isEdit,setEdit] = useState(false);
  const [defaultPropertyValue,setDefaultPropertyValue] = useState(null);
  const [highlightList,setHighlightList] = useState(null);
  const [isHover,setHover] = useState(false);
  const listRef=useRef(null);

  if(showBasicContent) {
    let data = {...props};
    data.propertyValue = defaultPropertyValue;
    data.options = [{[COMPONENT_OPTIONS_KEY]:0,label: '',value: '',checked: false}];
    return (
      <Draggable {...data}>
        {iconComponent}
        <ListViewPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  if(showPreview) {
    return (
      <div>
        <List
          style={getStyle(propertyValue,['text','style','layout'])}
          id={propertyValue ? propertyValue.component.id : `listview_${uniquekey}`}
          className={`${type} ${propertyValue?.['list-header-' + id] && propertyValue['list-header-' + id]?.component?.classes || ''}`}
          subheader={<ListSubheader style={propertyValue && propertyValue['list-header-' + id] ? getStyle(propertyValue['list-header-' + id],['text','style','layout']) : null}>{rest.childNode['list-header-' + id]}</ListSubheader>}
        >
          {
            options.map((e,i) => {
            return  <ListItem 
                      style={{position: 'relative', display:"block",...(propertyValue && propertyValue['list-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id] ? getStyle(propertyValue['list-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id],['text','style','layout']) : {})}}
                      className={`${propertyValue && propertyValue['list-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id] ? propertyValue['list-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id]?.component?.classes : ''}`}
                    >
                      {rest.childNode['list-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id]}
                    </ListItem>;
          })}
        </List>
      </div>
    );
  }

  const onClick = (e) => {
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        uniquekey,
        type,
        label,
        property,
        propertyValue,
        options,
      },
      false,
      e
    );
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = {x: e.clientX,y: e.clientY};
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        uniquekey,
        type,
        label,
        property,
        propertyValue,
        isActions: true,
        position,
        options,
      },
      false,
      e
    );
  };

  const border = {
    border: selectedComponent?.id === id ? '2px solid #97C1FF' : '2px solid transparent',
  };

  const filterInitialElements = (dID, loopEndIndex = options.length) => {

    let elem = null

    if(initialElements && fields && initialElements.length > 0 && initialElements.length > 0) {
      elem = initialElements.filter((e) => e.dropzoneID === dID) || [];
      return elem;
    }
    // if (elem && elem.length > 0){
    //   return elem
    // }

    
    // var uniquekey = idocument.getElementsByClassName('TEXT').length + 1;
    // var grid_uniquekey = idocument.getElementsByClassName('GRID').length + 1;
    // const imageKey = idocument.getElementsByClassName('IMAGE').length + 1;
    
    var uniquekey = getMaxIDByType(idocument,'TEXT');
    var grid_uniquekey = getMaxIDByType(idocument, 'GRID');
    const imageKey = getMaxIDByType(idocument, 'IMAGE');
    
    
    var uuid = Math.floor(Date.now() / 1000);

    // Todo: Need to remove these unused parts, if the new listview layout confirms
    var text1Id = `grid${grid_uniquekey}_text1_${uuid}`;
    var text2Id = `grid${grid_uniquekey}_text2_${uuid}`;
    var imageId = `grid${grid_uniquekey}_image1_${uuid}`;

    var d = [
      {
        ...replaceTemplateValues(textTemplate,{textColor: '#808080',TextId: uniquekey}),
        uniquekey: uniquekey,
        id: 'list-header-lable-' + id,
        key: 'list-header-lable-' + id,
        dropzoneID: 'list-header-' + id,
        label: 'List Header',
        parentID: id,
      },
    ];
    if(d) {
      for (let i = 0; i < loopEndIndex; i++) {
        d = [
          ...d,
          {
            ...replaceTemplateValues(listGridTemplate, {
              rootId: 'grid-' + i + '-' + id,
              rootKey: grid_uniquekey,
              gridKey2: grid_uniquekey + 1,
              gridKey3: grid_uniquekey + 2,
              rootText1Key: i === 0 ? uniquekey + 1 : uniquekey,
              rootText2Key: i === 0 ? uniquekey + 2 : uniquekey + 1,
              rootText3Key: i === 0 ? uniquekey + 3 : uniquekey + 2,
              imageKey, text1Id, text2Id, imageId
            }),
            dropzoneID: 'list-item-' + i + '-' + id,
            parentID: id,
          },
        ];
        
      }
    }
    return d.filter((e) => e.dropzoneID === dID) || [];
  };
  const updateCellPropertyValue=async (cellId,newValue,initflag)=>{
    if(!initflag){
      var newPvalue=propertyValue;
      newPvalue[cellId]=newValue;
      await setState({ addAction: true });
      state.updateElement(id, dropzoneID, parentID, { propertyValue: newPvalue }, () => {
        onSelect(
          {
            id,
            dropzoneID,
            parentID,
            cellId,
            type,
            label,
            property,
            parentPropertyValue: newPvalue,
            propertyValue: newPvalue[cellId],
          },
          false
        );
      });
    }else{
      var newPvalue=propertyValue;
      newPvalue[cellId]=newValue;
      await setState({ addAction: true });
      state.updateElement(id, dropzoneID, parentID, { propertyValue: newPvalue }, () => {});
    }
    
  }
  const onCellClick = (target,ref) => {
    let cellId = target.id;
    let property;
    if(cellId.includes('list-item-')) {
      property = {
        componentSection: ['ID','Classes','Link'],
        gridSection: [],
        textSection: true,
        styleSection: ['Background','Opacity','Custom CSS'],
        layoutSection: ['Layout','Width','Height'],
        tooltipSection: ["event", "position", "title"],
        interactionSection: gListViewDefaultSchema({}).events
      };
    } else {
      property = {
        componentSection: ['ID','Classes'],
        gridSection: [],
        textSection: true,
        styleSection: ['Background','Opacity','Custom CSS'],
        layoutSection: ['Layout','Width','Height'],
        tooltipSection: ["event", "position", "title"],
        interactionSection: [EVENTS_SCHEMA.CLICK]
      };
    }
    let styles = {...(propertyValue ? propertyValue : defaultPropertyValue)};
    
    setHighlightList(cellId);
    // setShowMoreActions(null);
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        cellId,
        type,
        label,
        property,
        parentPropertyValue: styles,
        propertyValue: styles[cellId],
      },
      false
    );
  };

  const selectParent = () => {
    setHighlightList(null);
    onClick(null);
  };

  const _hasDS = () => {
    if (propertyValue && propertyValue.dataSource){
      const hasDS = propertyValue?.dataSource[0].Data.selfVal;
      if (hasDS) {
        return true;
      }
    }
    return false;
  }

  /*
  * Render elems without Dropzone, so they will not be selective and they will not be a part of the property json data, they will only work like disabled items
  */

  const _renderElementsWithoutDropzone = (element) => {
    const children = {};
    const elementData = core.getRegisteredPaletteElements()
      .find(e => e.type === element.type);
    if (Array.isArray(element.initialElements)) {
      element.initialElements.forEach((field) => {
        children[field.dropzoneID] = children[field.dropzoneID] || [];
        children[field.dropzoneID] = children[field.dropzoneID]
          .concat(_renderElementsWithoutDropzone(field));
      });
    }
    if (elementData?.component) {
      return (
        <elementData.component
          childNode={children}
          {...element}
          setState={()=>{}}
          onSelect={()=>{}}
        />
      );
    }
    return null
  }

  /*
  * Count number of existing list-items in the property json data
  */
  const _countItemsInJSONData = () => {
    let k = Object.keys(propertyValue).filter(key => key.startsWith("list-item-")).length;
    return k;
  }

  /*
  * Render static items as shadow items to indicate that the main listing component is attached with data-source
  */
  const _renderShadowItems = () =>{ 
    if (_hasDS()) { // Render only if listing component is attached with data-source
      let elems = [];
      const pv = { ...JSON.parse(JSON.stringify(propertyValue['list-item-0-' + id])) }; // Copy first item style...
      let loopStartIndex = _countItemsInJSONData();
      let loopEndIndex = loopStartIndex + NUMBER_OF_SHADOW_ITEMS;
      for (let j = loopStartIndex; j < loopEndIndex; j++) {
        const contents = filterInitialElements('list-item-0-' + id, loopEndIndex)
        const children = contents.map(_renderElementsWithoutDropzone);
        elems.push(
          <MlistView
            propertyValue={{
              ...pv,
              style: { // Override the shadow list-item with disabled style
                ...pv.style,
                backgroundColor: "#DFDADA",
                opacity: "0.5"
              }
            }}
            children={children}
          />
        )
      }
      return elems;
    }
    return null;
  }

  return (
    <Draggable {...props} dataid={`${id}_parent`} onClick={onClick}>
      <Tooltip  arrow
          title = {propertyValue?.tooltip?.title || ""}
          placement = {propertyValue?.tooltip?.position || ""}
          PopperProps={{ disablePortal: true }}
        >
        <Box style={{paddingTop:2,paddingBottom:2}}>
        <List
          ref={listRef}
          style={{...getStyle(propertyValue,['text','style','layout'])}}
          id={propertyValue.component.id}
          dataid={`${id}_parent`}
          properties={JSON.stringify({id,dropzoneID,parentID,label,type})}
          dropzoneID={dropzoneID}
          compid={propertyValue.component.id}
          onMouseOut={(e) => {
            e.stopPropagation();
            setHover(false);
          }}
          onMouseOver={(e) => {
            e.stopPropagation();
            setHover(true);
          }}
          className={` ${id} ${type} draggable-component ${isHover && 'draggable-hover'} ${!selectedComponent?.cellId && selectedComponent?.id === id && 'highlight-component'} ${propertyValue?.component?.classes || ''}`}
          subheader={
            <Dropzone
              {...dropzoneProps}
              initialElements={filterInitialElements('list-header-' + id)}
              id={'list-header-' + id}
              onDrop={onDropZone}
              i={uniquekey}
              j={`listview${uniquekey}_header`}
              selectedComponent={selectedComponent}
              onSelect={(value) => {
                setHighlightList(null);
                // setShowMoreActions(null);
                onSelect(value);
              }}
              onCellClick={onCellClick}
              updateCellPropertyValue={updateCellPropertyValue}
              setState={setState}
              className={`draggable-component ${isHover && 'draggable-hover'} ${'list-header-' + id}`}
              placeholder="Drop here"
              component={MListSubheader}
              type={`List Header`}
              dropzoneCellID={id}
              triggerAction={{
                highlightItem: 'list-header-' + id === selectedComponent?.cellId,
                selectParent: selectParent,
              }}
              properties={JSON.stringify({id: 'list-header-' + id,dropzoneID: id,parentID: id,label: `List Header`,type: 'List Header'})}
              propertyValue={propertyValue && propertyValue['list-header-' + id] ? propertyValue['list-header-' + id] : {component: {id: `listview${uniquekey}_header_${uniquekey}`}}}
            />
          }
        >
          <Box style={{display: 'none'}} dataid={`${id}_parent`} id={propertyValue ? propertyValue.component.id : 'listview_' + uniquekey} />
          {options.map((e,i) => (
            <Dropzone
              {...dropzoneProps}
              initialElements={filterInitialElements('list-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id)}
              id={'list-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id}
              onDrop={onDropZone}
              onSelect={(value) => {
                setHighlightList(null);
                // setShowMoreActions(null);
                onSelect(value);
              }}
              selectedComponent={selectedComponent}
              key={`${id}-${(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY])}`}
              i={(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + 1}
              j={`listview${uniquekey}_item`}
              onCellClick={onCellClick}
              updateCellPropertyValue={updateCellPropertyValue}
              setState={setState}
              placeholder=" "
              component={MlistView}
              triggerAction={{
                highlightList: 'list-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id === selectedComponent?.cellId,
                selectParent: selectParent,
              }}
              className={'list-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id}
              type={`List Item ${(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + 1}`}
              dropzoneCellID={id}
              properties={JSON.stringify({id: 'list-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id,dropzoneID: id,parentID: id,label: `List Item ${(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + 1}`,type: 'List Item'})}
              // className={`draggable-component ${isHover && 'draggable-hover'}`}
              propertyValue={propertyValue && propertyValue['list-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id] ? propertyValue['list-item-' +(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id] : {component: {id: `listview${uniquekey}_item_${(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + 1}`}}}
            />
          ))}
          {/*_renderShadowItems()*/}
          {!selectedComponent?.cellId && selectedComponent?.id === id && <MoreActionButton handleActions={handleActions} type={'List View'} showParentSelection={true}
            idocument={idocument}
            parentID={dropzoneID} anchorElement={listRef} />}
        </List>
        </Box>
      </Tooltip>
    </Draggable>
  );
};

export default DraggableListView;
