import React, { useEffect } from 'react'
import './newUserDialog.css'
import { useForm } from '../../../../components/forms/Form';
import DeleteIcon from "../../../../images/delete.png"
import {validateSystemObj } from './newUserValidation';


const System = ({item,index,systemList,handleDelete,handleUpdate,Error,uuid}) => {

    const onWatch = (errors, data) => {
        item[data.name] = data.value;
        if(validateSystemObj(errors,data.name,data.value))
            handleUpdate(item,index);
    }

    const deleteItem = () =>{
        handleDelete(item,index);
    }
    
    const {Form,Input,Select} = useForm(item,()=>null,onWatch);
    return (
        <>
            <Form>
            <div className='form-field-varied'>
                <div className='form-field-varied'>
                    <div>
                        <Select
                            style={{ width: "100px" }}
                            placeholder='Select'
                            defaultValue={item.connectionId}
                            data-option-testid="system-select"
                            id="connectionId"
                            name={"connectionId_"+uuid}>
                            <option value='' disabled>Systems</option>
                            {systemList?.map((system, index) => {
                                return (
                                    <option
                                        key={index}
                                        value={system.id}
                                        selected={item["connectionId_"+uuid] === system.id ?true:false}
                                        id={system.id}
                                    >
                                        {system.name}
                                    </option>
                                )
                            })}
                        </Select>
                        <Error name={"connectionId_"+uuid} />
                    </div>
                    <div className='form-select-varied-hide'>
                        <Input
                            className='input-align'
                            placeholder="Enter text"
                            name={"ssUserId_"+uuid}
                            id="ssUserId"
                            data-input-testid="system-input"
                            defaultValue={item["ssUserId_"+uuid]}
                        />
                        <Error name={"ssUserId_"+uuid} />
                    </div>
                    <span style={{ cursor: "pointer" }}>
                        <img
                            data-clickable-testid="system-delete"
                            src={DeleteIcon}
                            alt = "Delete System"
                            onClick={deleteItem} />
                    </span>
                </div>
            </div>
            </Form>
            </>
    )
}

export default System;