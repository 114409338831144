import React,{useState,useRef} from 'react';
import {Box,Table,TableBody,TableCell,TableContainer,TableHead,Paper,TableRow,Card,TableFooter,Tooltip} from '@material-ui/core';
import {Draggable,state,Dropzone} from '../../../../libraries/react-page-maker';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import {getComputedStyle,getStyle,replaceTemplateValues} from '../../utils/style';
import { gTableCellDefaultSchema, gTableRowDefaultSchema } from './schema';
import {withStyles} from '@material-ui/core/styles';
import {table as property} from './property';
import TablePreview from './preview/Table';
import {textTemplate} from '../../template';
import {GROUP_COMPONENT_NAMES} from '../../utils/configuration/componentName.contants';
import { getMaxIDByType } from '../../../../helpers/app-designer';
import { TooltipWrapper } from '../common';
import { COMPONENT_OPTIONS_KEY } from '../../../../constants';
const rows = [
  {name: 'Item 1',color: 'red'},
  {name: 'Item 2',color: 'blue'},
  {name: 'Item 3',color: 'yellow'},
];

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.background.primary,
    color: theme.color.white,
  },
}))(TableCell);

const MStyledTableCell = React.forwardRef((props,ref) => {
  React.useEffect(() => {
    if(ref && ref.current && !props.propertyValue.style) {
        const {value} = getComputedStyle({
          computedStyle: window.getComputedStyle(ref.current,null),
          componentName: props.j,
          componentId: props.i,
          gComponentName: GROUP_COMPONENT_NAMES.TABLE_CELL,
        });
      var val=value;
      props.updateCellPropertyValue(props.id,val,true)
    }
  },[ref]);
  return(
  <StyledTableCell
    ref={ref}
    onClick={(e) => {
      e.stopPropagation();
      props.onCellClick(props,ref);
    }}
    style={{...getStyle(props.propertyValue,['text','style','layout']),border: props.triggerAction.highlightGrid ? '2px solid #97C1FF' : '2px solid transparent'}}
    {...props}
    className={`${props.className ? props.className : ''} ${props.propertyValue?.component?.classes || ''}`}
  >
    <div id={props.propertyValue?.component?.id} dataId={props.id} style={{display: 'none'}} />
    {props.children}
    {props.triggerAction.highlightGrid && (
      <MoreActionButton
        type={'T-Cell'}
        showParentSelection={true}
        selectParent={(e) => {
          e.stopPropagation();
          props.triggerAction.selectParent();
        }}
        showActionButton={false}
        anchorElement={ref}
      />
    )}
  </StyledTableCell>
)});

const MTableCell = React.forwardRef((props,ref) => {
  React.useEffect(() => {
    if(ref && ref.current && !props.propertyValue.style) {
        const {value} = getComputedStyle({
          computedStyle: window.getComputedStyle(ref.current,null),
          componentName: props.j,
          componentId: props.i,
          gComponentName: GROUP_COMPONENT_NAMES.TABLE_CELL,
        });
      var val=value;
      props.updateCellPropertyValue(props.id,val,true)
    }
  },[ref]);
  return(
  <TableCell
    ref={ref}
    onClick={(e) => {
      e.stopPropagation();
      props.onCellClick(props,ref);
    }}
    style={{...getStyle(props.propertyValue,['text','style','layout']),border: props.triggerAction.highlightGrid ? '2px solid #97C1FF' : '0px solid transparent'}}
    {...props}
    className={`${props.className ? props.className : ''} ${props.propertyValue?.component?.classes || ''}`}
  >
    <div id={props.propertyValue?.component?.id} dataId={props.id} style={{display: 'none'}} />
    {props.children}
    {props.triggerAction.highlightGrid && (
      <MoreActionButton
        type={'T-Cell'}
        showParentSelection={true}
        selectParent={(e) => {
          e.stopPropagation();
          props.triggerAction.selectParent();
        }}
        showActionButton={false}
        anchorElement={ref}
      />
    )}
  </TableCell>
)});

const DraggableTable = (props) => {
  const {
    id,
    uniquekey,
    showBasicContent,
    showPreview,
    dropzoneID,
    onDropZone,
    dropzoneProps,
    initialElements,
    parentID,
    onSelect,
    type,
    iconComponent,
    fields,
    setState,
    options = [
      {label: '',value: '',checked: false},
      {label: '',value: '',checked: false},
    ],
    propertyValue,
    idocument,
    selectedComponent,
    ...rest
  } = props;
  const [label,setLabel] = useState(props.label);
  const [isEdit,setEdit] = useState(false);
  const [defaultPropertyValue,setDefaultPropertyValue] = useState(null);
  const [highlightGrid,setHighlightGrid] = useState(null);
  const [isHover,setHover] = useState(false);
  //const row = new Array(propertyValue && propertyValue.grid && propertyValue.grid.rows ? propertyValue.grid.rows : 1).fill('row');
  //const col = new Array(propertyValue && propertyValue.grid && propertyValue.grid.columns ? propertyValue.grid.columns : 2).fill('col');

  const tableRef = useRef(null);
  const row1Ref = useRef(null);
  const row2Ref = useRef(null);
  React.useEffect(() => {
    if(row2Ref && row2Ref.current && !propertyValue['table-body-row-1_' + id]) {
        const {value} = getComputedStyle({
          computedStyle: window.getComputedStyle(row2Ref.current,null),
          componentName: `table${uniquekey}_tr_row`,
          componentId: 1,
          gComponentName: GROUP_COMPONENT_NAMES.TABLE_ROW
        });
      updateCellPropertyValue('table-body-row-1_' + id,value,true)
    }
  },[row2Ref]);

  React.useEffect(() => {
    if(row1Ref && row1Ref.current && !propertyValue['table-heading-row-1_' + id]) {
        const {value} = getComputedStyle({
          computedStyle: window.getComputedStyle(row1Ref.current,null),
          componentName: `table${uniquekey}_th_row`,
          componentId: 1,
          gComponentName: GROUP_COMPONENT_NAMES.TABLE_ROW
        });
      updateCellPropertyValue('table-heading-row-1_' + id,value,true)
    }
  },[row1Ref]);

  if(showBasicContent) {
    let data = {...props};
    data.propertyValue = defaultPropertyValue;
    data.options = [
      {[COMPONENT_OPTIONS_KEY]:0,label: '',value: '',checked: false},
      {[COMPONENT_OPTIONS_KEY]:1,label: '',value: '',checked: false},
    ];
    return (
      <Draggable {...data}>
        {iconComponent}
        <TablePreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  const onClick = (e) => {
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        uniquekey,
        type,
        label,
        options,
        property,
        propertyValue,
      },
      false,
      e
    );
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = {x: e.clientX,y: e.clientY};
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        uniquekey,
        type,
        label,
        property,
        propertyValue,
        isActions: true,
        position,
      },
      false,
      e
    );
  };

  const filterInitialElements = (dID) => {
    let items = [];
    if (initialElements && initialElements.length > 0) {
    // if(initialElements && fields && initialElements.length > 0 && initialElements.length >= options.length*2) {
      items = initialElements.filter((e) => e.dropzoneID === dID) || [];
    }

    if(items.length > 0){
      return items;
    }
    

    var d = [];
    if(d && d.length === 0) {
      // var uniquekey = idocument.getElementsByClassName('TEXT').length + 1;
      var uniquekey = getMaxIDByType(idocument, 'TEXT');
      options.map((e,i) => {
        d = [
          ...d,
          {
            ...replaceTemplateValues(textTemplate,{textColor: '#fafafa',TextId: uniquekey + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + 1}),
            uniquekey: uniquekey + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + 1,
            id: 'table-lable-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id,
            key: 'table-lable-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id,
            dropzoneID: 'table-heading-col-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id,
            label: 'Heading',
            parentID: id,
          },
          {
            ...replaceTemplateValues(textTemplate,{textColor: '#000',TextId: uniquekey + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + 2}),
            uniquekey: uniquekey + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + 2,
            id: 'lable-0-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id,
            key: 'lable-0-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id,
            dropzoneID: 'table-body-col-0-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id,
            label: 'Label',
            parentID: id,
          },
        ];
        uniquekey++;
        return e;
      });
    }
    return d.filter((e) => e.dropzoneID === dID) || [];
  };
  const updateCellPropertyValue=async (cellId,newValue,initflag)=>{
    if(!initflag){
      var newPvalue=propertyValue;
      newPvalue[cellId]=newValue;
      await setState({ addAction: true });
      state.updateElement(id, dropzoneID, parentID, { propertyValue: newPvalue }, () => {
        onSelect(
          {
            id,
            dropzoneID,
            parentID,
            cellId,
            type,
            label,
            property,
            parentPropertyValue: newPvalue,
            propertyValue: newPvalue[cellId],
          },
          false
        );
      });
    }else{
      var newPvalue=propertyValue;
      newPvalue[cellId]=newValue;
      await setState({ addAction: true });
      state.updateElement(id, dropzoneID, parentID, { propertyValue: newPvalue }, () => {});
    }
    
  }
  const onCellClick = (target,ref) => {
    let cellId = target.id;
    const property = {
      componentSection: ['ID','Classes'],
      gridSection: [],
      textSection: true,
      styleSection: ['Background','Opacity','Custom CSS'],
      layoutSection: ['Layout','Width','Height'],
      tooltipSection: ["event", "position", "title"],
      interactionSection: gTableCellDefaultSchema({}).events
    };
    let styles = {...(propertyValue ? propertyValue : defaultPropertyValue)};
    setHighlightGrid(cellId);
    // setShowMoreActions(null);
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        cellId,
        type,
        label,
        property,
        parentPropertyValue: styles,
        propertyValue: styles[cellId],
		gComponentName: GROUP_COMPONENT_NAMES.TABLE_CELL,
      },
      false
    );
  };

  const onRowClick = (rowId,tableRef,i) => {
    const property = {
      componentSection: ['ID','Classes'],
      gridSection: [],
      textSection: [],
      styleSection: ['Background','Opacity','Custom CSS'],
      layoutSection: [],
      interactionSection: gTableRowDefaultSchema({}).events
    };
    let styles = {...(propertyValue ? propertyValue : defaultPropertyValue)};
    setHighlightGrid(rowId);
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        cellId: rowId,
        type,
        label,
        property,
        parentPropertyValue: styles,
        propertyValue: styles[rowId],
		gComponentName: GROUP_COMPONENT_NAMES.TABLE_ROW,
      },
      false
    );
  };

  const selectParent = (id,ref,i) => {
    setHighlightGrid(id);
    onRowClick(id,ref,i);
  };

  if(showPreview) {
    return (
      <TableContainer id={propertyValue ? propertyValue.component.id : `table_${uniquekey}`} style={getStyle(propertyValue,['layout'])} className={`${type} ${propertyValue?.component?.classes || ''}`} component={Paper}>
        <Table style={getStyle(propertyValue,['text'])}>
          <TableHead>
            <TableRow style={{...getStyle(propertyValue ? propertyValue['table-heading-row-1_' + id] : null,['style'])}} className={propertyValue['table-heading-row-1_' + id]?.component?.classes || ''}>
              {options.map((e,j) => (
                <StyledTableCell style={({position: 'relative'},propertyValue && propertyValue['table-heading-col-' + j + '-' + id] ? getStyle(propertyValue['table-heading-col-' + j + '-' + id],['text','style','layout']) : null)} className={propertyValue['table-heading-col-' + j + '-' + id]?.component?.classes || ''}>
                  {rest.childNode['table-heading-col-' + j + '-' + id]}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow style={{...getStyle(propertyValue ? propertyValue['table-body-row-1_' + id] : null,['style'])}} className={propertyValue['table-body-row-1_' + id]?.component?.classes || ''}>
              {options.map((e,j) => (
                <TableCell style={({position: 'relative'},propertyValue && propertyValue['table-body-col-0-' + j + '-' + id] ? getStyle(propertyValue['table-body-col-0-' + j + '-' + id],['text','style','layout']) : null)} className={propertyValue['table-body-col-0-' + j + '-' + id]?.component?.classes || ''}>{rest.childNode['table-body-col-0-' + j + '-' + id]}</TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  //console.warn('inside table component', selectedComponent);
  return (
    <Draggable {...props} dataid={`${id}_parent`} style={{boxShadow: 'none'}} onClick={onClick}>
      <Tooltip  arrow
        title = {propertyValue?.tooltip?.title || ""}
        placement = {propertyValue?.tooltip?.position || ""}
        PopperProps={{ disablePortal: true }}
      >
      <Box style={{paddingTop:2,paddingBottom:2}}>
        <TableContainer
          onMouseOut={(e) => {
            e.stopPropagation();
            setHover(false);
          }}
          onMouseOver={(e) => {
            e.stopPropagation();
            setHover(true);
          }}
          id={propertyValue ? propertyValue.component.id : `table_${uniquekey}`}
          dataid={`${id}_parent`}
          compid={propertyValue.component.id}
          style={getStyle(propertyValue,['layout'])}
          className={`${id} ${type} draggable-component ${isHover && 'draggable-hover'} ${!selectedComponent?.cellId && selectedComponent?.id === id && 'highlight-component'} ${propertyValue?.component?.classes}`}
          dropzoneID={dropzoneID}
          properties={JSON.stringify({id,dropzoneID,parentID,label,type})}
          component={Card}
          ref={tableRef}
        >
          <Table style={{minWidth: 150,...getStyle(propertyValue,['text'])}}>
            <TableHead>
              <div
                id={'table-heading-row-1_' + id}
                style={{
                  position: 'relative',
                  color: 'inherit',
                  display: 'table-row',
                  width: '100%',
                  border: 'table-heading-row-1_' + id === selectedComponent?.cellId ? '3px solid #97C1FF' : '0px solid transparent',
                  ...getStyle(propertyValue ? propertyValue['table-heading-row-1_' + id] : null,['style']),
                }}
                onClick={(e) => e.stopPropagation()}
                className={`TABLEROW1 ${propertyValue['table-heading-row-1_' + id]?.component?.classes}` }
                ref={row1Ref}
              >
                {options.map((e,j) => (
                  <Dropzone
                    {...dropzoneProps}
                    initialElements={filterInitialElements('table-heading-col-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?j:e[COMPONENT_OPTIONS_KEY])+ '-' + id)}
                    id={'table-heading-col-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?j:e[COMPONENT_OPTIONS_KEY]) + '-' + id}
                    onDrop={onDropZone}
                    onSelect={(value) => {
                      setHighlightGrid(null);
                      // setShowMoreActions(null);
                      onSelect(value);
                    }}
                    onCellClick={onCellClick}
                    updateCellPropertyValue={updateCellPropertyValue}
                    setState={setState}
                    placeholder="Drop Here"
                    key={'table-heading-col-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?j:e[COMPONENT_OPTIONS_KEY]) + '-' + id}
                    col={options}
                    i={(e?.[COMPONENT_OPTIONS_KEY]==undefined?j:e[COMPONENT_OPTIONS_KEY]) + 1}
                    j={`table${uniquekey}_th_td`}
                    selectedComponent={selectedComponent}
                    component={MStyledTableCell}
                    className={'table-heading-col-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?j:e[COMPONENT_OPTIONS_KEY]) + '-' + id}
                    type={`Table Header Cell`}
                    dropzoneCellID={id}
                    properties={JSON.stringify({id: 'table-heading-col-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?j:e[COMPONENT_OPTIONS_KEY]) + '-' + id,dropzoneID: id,parentID: id,label: `Table Header Cell ${(e?.[COMPONENT_OPTIONS_KEY]==undefined?j:e[COMPONENT_OPTIONS_KEY]) + 1}`,type: 'Table Header Cell'})}
                    triggerAction={{
                      highlightGrid: 'table-heading-col-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?j:e[COMPONENT_OPTIONS_KEY]) + '-' + id === selectedComponent?.cellId,
                      selectParent: () => selectParent('table-heading-row-1_' + id,row1Ref,1),
                    }}
                    propertyValue={propertyValue && propertyValue['table-heading-col-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?j:e[COMPONENT_OPTIONS_KEY]) + '-' + id] ? propertyValue['table-heading-col-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?j:e[COMPONENT_OPTIONS_KEY]) + '-' + id] : {component: {id: `table${uniquekey}_th_${(e?.[COMPONENT_OPTIONS_KEY]==undefined?j:e[COMPONENT_OPTIONS_KEY]) + 1}`}}}
                  />
                ))}
                {'table-heading-row-1_' + id === selectedComponent?.cellId && (
                  <MoreActionButton
                    type={'T-Row'}
                    showParentSelection={true}
                    parentID={id}
                    isCustomStyle={{
                      top: '-6px',
                      right: 0,
                    }}
                    typeStyle={{
                      top: '-6px',
                    }}
                    showActionButton={false}
                    selectParent={onClick}
                    anchorElement={row1Ref}
                  />
                )}
              </div>
            </TableHead>
            <TableBody>
              <div
                id={'table-body-row-1_' + id}
                className={` ${"TABLEROW2"} ${propertyValue['table-body-row-1_' + id]?.component?.classes || ''}`}
                style={{
                  position: 'relative',
                  color: 'inherit',
                  display: 'table-row',
                  width: '100%',
                  border: 'table-body-row-1_' + id === selectedComponent?.cellId ? '3px solid #97C1FF' : '0px solid transparent',
                  ...getStyle(propertyValue ? propertyValue['table-body-row-1_' + id] : null,['style']),
                }}
                onClick={(e) => e.stopPropagation()}
                ref={row2Ref}
              >
                {options.map((e,j) => (
                  <Dropzone
                    {...dropzoneProps}
                    initialElements={filterInitialElements('table-body-col-0-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?j:e[COMPONENT_OPTIONS_KEY])+ '-' + id)}
                    id={'table-body-col-0-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?j:e[COMPONENT_OPTIONS_KEY]) + '-' + id}
                    onDrop={onDropZone}
                    onSelect={(value) => {
                      setHighlightGrid(null);
                      // setShowMoreActions(null);
                      onSelect(value);
                    }}
                    onCellClick={onCellClick}
                    updateCellPropertyValue={updateCellPropertyValue}
                    selectedComponent={selectedComponent}
                    setState={setState}
                    placeholder="Drop Here"
                    key={'table-body-col-0-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?j:e[COMPONENT_OPTIONS_KEY]) + '-' + id}
                    col={options}
                    i={(e?.[COMPONENT_OPTIONS_KEY]==undefined?j:e[COMPONENT_OPTIONS_KEY]) + 1}
                    j={`table${uniquekey}_tr_td`}
                    component={MTableCell}
                    className={'table-body-col-0-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?j:e[COMPONENT_OPTIONS_KEY]) + '-' + id}
                    type={`Table Row Cell`}
                    dropzoneCellID={id}
                    properties={JSON.stringify({id: 'table-body-col-0-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?j:e[COMPONENT_OPTIONS_KEY]) + '-' + id,dropzoneID: id,parentID: id,label: `Table Row Cell ${(e?.[COMPONENT_OPTIONS_KEY]==undefined?j:e[COMPONENT_OPTIONS_KEY]) + 1}`,type: 'Table Row Cell'})}
                    triggerAction={{
                      highlightGrid: 'table-body-col-0-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?j:e[COMPONENT_OPTIONS_KEY]) + '-' + id === selectedComponent?.cellId,
                      selectParent: () => selectParent('table-body-row-1_' + id,row2Ref,2),
                    }}
                    propertyValue={propertyValue && propertyValue['table-body-col-0-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?j:e[COMPONENT_OPTIONS_KEY]) + '-' + id] ? propertyValue['table-body-col-0-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?j:e[COMPONENT_OPTIONS_KEY]) + '-' + id] : {component: {id: `table${uniquekey}_tr_${e[COMPONENT_OPTIONS_KEY] + 1}`}}}
                  />
                ))}
                {'table-body-row-1_' + id === selectedComponent?.cellId && (
                  <MoreActionButton
                    type={'T-Row'}
                    showParentSelection={true}
                    parentID={id}
                    isCustomStyle={{
                      top: 56,
                      right: 0,
                    }}
                    typeStyle={{
                      top: 56,
                    }}
                    showActionButton={false}
                    selectParent={onClick}
                    anchorElement={row2Ref}
                  />
                )}
              </div>
            </TableBody>
          </Table>
        </TableContainer>
        {!selectedComponent?.cellId && selectedComponent?.id === id && <MoreActionButton handleActions={handleActions} type={'Table'} anchorElement={tableRef} />}
      </Box>
      </Tooltip>
    </Draggable>
  );
};

export default DraggableTable;
