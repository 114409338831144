import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Tooltip,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import React, { useRef, useState, useEffect } from 'react';
import {
  Draggable,
  Dropzone,
  state,
} from '../../../../libraries/react-page-maker';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import { GROUP_COMPONENT_NAMES } from '../../utils/configuration/componentName.contants';
import { getComputedStyle, getStyle } from '../../utils/style';
import NavBarPreview from './preview/BottomBar';
import { navBar as property } from './property';
import getIcon from '../../utils/configuration/icons';
import { TooltipWrapper } from '../common';
import { gNavbarDefaultSchema } from './schema';
import { COMPONENT_OPTIONS_KEY } from '../../../../constants';

const defaultOption = [
  { label: 'Favorite', icon: 'FavoriteBorder' },
  { label: 'List', icon: 'FeaturedPlayList' },
  { label: 'Note', icon: 'MusicNote' },
  { label: 'Location', icon: 'LocationOn' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: '0px',
    left: '0px',
    flex: 1,
    width: '100%',
    zIndex: 999,
    backgroundColor: theme.palette.background.primary,
  },
  alignLeft:{
    alignItems: "start"
  },
  alignRight:{
    alignItems: "end"
  }
}));

const StyledBottomNavigationAction = withStyles((theme) => ({
  selected: {
    color: '#fff !important',
  },
}))(BottomNavigationAction);

const NavItem = React.forwardRef((props, navbarRef) => {
  //console.log('props description',props);
  const propertyValue = props.propertyValue;
  const elementLable = propertyValue?.component?.placeholder;
  const [label, setLabel] = useState(
    propertyValue?.component?.placeholder ??
      defaultOption[props.i - 1]?.label ??
      'Label'
  );
  const [isEdit, setEdit] = useState(false);
  const onChange = async (value) => {
    await setLabel(value);
    await setEdit(true);
  };
  React.useEffect(() => {
    if (navbarRef && navbarRef.current && !props.propertyValue.style) {
      const { value } = getComputedStyle({
        computedStyle: window.getComputedStyle(navbarRef.current, null),
        componentName: props.j,
        componentId: props.i,
        gComponentName: GROUP_COMPONENT_NAMES.BOTTOM_BAR_ITEM,
      });
      var val = value;
      if (defaultOption[props.i - 1]?.icon) {
        val.component.iconName = defaultOption[props.i - 1].icon;
        val.component.placeholder = defaultOption[props.i - 1].label;
      }
      props.updateCellPropertyValue(props.id, val, true);
    }
  }, [navbarRef]);

  const updatelabel = async () => {
    if (isEdit) {
      if (label !== elementLable) {
        await setEdit(false);
        var newPropsvalue = propertyValue;
        newPropsvalue.component.placeholder = label;
        props.updateCellPropertyValue(props.id, newPropsvalue);
        //await setState({ addAction: true });
        //await setEdit(false);
        // onSelect({ id, dropzoneID, uniquekey, propertyValue, parentID, type, label: label, property }, false);
      }
    }
  };
  return (
    <>
      {/* <Tooltip  arrow
          title = {propertyValue?.tooltip?.title || ""}
          placement = {propertyValue?.tooltip?.position || ""}
          PopperProps={{ disablePortal: true }}
        > */}
      <BottomNavigationAction
        ref={navbarRef}
        showLabel={true}
        id={props.propertyValue?.component?.id}
        className={`${props.propertyValue?.component?.classes || ''}`}
        dataId={props.id}
        label={
          <span
            onDoubleClick={async (e) => {
              e.persist();
              const event = e;
              await setEdit(true);
              event.target.focus();
            }}
            contentEditable={isEdit ? 'true' : 'false'}
            onBlur={(e) => updatelabel()}
            onInput={(e) => onChange(e.currentTarget.innerText)}
          >
            {elementLable}
          </span>
        }
        icon={getIcon(propertyValue?.component?.iconName)}
        onClick={(e) => {
          e.stopPropagation();
          props.onCellClick({...props,gComponentName: GROUP_COMPONENT_NAMES.BOTTOM_BAR_ITEM}, navbarRef);
        }}
        style={{
          position: 'relative',
          color: 'white',
          width: `${100 / props.triggerAction.options.length}%`,
          height: 56,
          border: props.triggerAction.highlightGrid
            ? '2px solid #97C1FF'
            : '2px solid transparent',
          ...getStyle(props.propertyValue, ['text', 'layout', 'style']),
        }}
      />
      {props.triggerAction.highlightGrid && (
        <MoreActionButton
          type={'Item'}
          showParentSelection={true}
          showActionButton={false}
          selectParent={(e) => {
            e.stopPropagation();
            props.triggerAction.selectParent();
          }}
          anchorElement={navbarRef}
        />
      )}
    </>
    // </Tooltip>
  );
});

const DraggableBottomBar = (props) => {
  const {
    id,
    uniquekey,
    showBasicContent,
    showPreview,
    dropzoneID,
    parentID,
    onSelect,
    type,
    iconComponent,
    idocument,
    fields,
    propertyValue,
    setState,
    dropzoneProps,
    initialElements,
    onDropZone,
    options = [{[COMPONENT_OPTIONS_KEY]:0,label: '',value: '',checked: false},
      {[COMPONENT_OPTIONS_KEY]:1,label: '',value: '',checked: false},
      {[COMPONENT_OPTIONS_KEY]:2,label: '',value: '',checked: false},
      {[COMPONENT_OPTIONS_KEY]:3,label: '',value: '',checked: false},
    ],
    selectedComponent,
    ...rest
  } = props;
  const elementLable = props.label;
  const [label, setLabel] = useState(props.label);
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [isHover, setHover] = useState(false);
  const bottomBarRef = useRef();

  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    data.options =   [{[COMPONENT_OPTIONS_KEY]:0,label: '',value: '',checked: false},
    {[COMPONENT_OPTIONS_KEY]:1,label: '',value: '',checked: false},
    {[COMPONENT_OPTIONS_KEY]:2,label: '',value: '',checked: false},
    {[COMPONENT_OPTIONS_KEY]:3,label: '',value: '',checked: false},
  ]
    return (
      <Draggable {...data}>
        {iconComponent}
        <NavBarPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }
  if (showPreview) {
    const renderOptions =  () =>{
      if (props?.menuPagePropertyValue?.component?.menuStyle){

        let overideClasses = {}
        let textAlignClassName ;
        const textStyle = getStyle(props?.menuPagePropertyValue, ['text']);
        if (textStyle.textAlign === "left"){
          textAlignClassName = classes.alignLeft
        } else if(textStyle.textAlign === "right"){
          textAlignClassName = classes.alignRight
        }

        if (textAlignClassName){
          overideClasses.wrapper = textAlignClassName
        }

        return props.menuPagePropertyValue.component.links.map((e, i) => (
          <BottomNavigationAction
            showLabel={true}
            label={<span style={{ color: 'white',...getStyle(props?.menuPagePropertyValue, ['text', 'layout', 'style'])}} >{e.label}</span>}
            icon={e.iconName ? getIcon(e.iconName, getStyle(props?.menuPagePropertyValue, ['text'])) : null}
            style={{
              position: 'relative',
              color: 'white',
              width: `${100 / options.length}%`,
              height: 56,
              ...(getStyle(props?.menuPagePropertyValue, [ 'text', 'layout', 'style'])),
            }}
            classes={overideClasses}
          />
        ))
      }

      return options.map((e, i) => (
        <BottomNavigationAction
          showLabel={true}
          id={propertyValue['nav-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id]?.component?.id}
          label={
            propertyValue['nav-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id]?.component
              ?.placeholder
          }
          icon={getIcon(
            propertyValue['nav-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id]?.component?.iconName
          )}
          style={{
            position: 'relative',
            color: 'white',
            width: `${100 / options.length}%`,
            height: 56,
            ...(propertyValue['nav-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id] ? getStyle(propertyValue['nav-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id], [
              'text',
              'layout',
              'style',
            ])
              : {}),
          }}
          className={
            propertyValue['nav-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id]?.component?.classes ||
            ''
          }
        />
      ))
    }

    const getPropertyValue = () =>{
      if (props?.menuPagePropertyValue?.component?.menuStyle){
        return props?.menuPagePropertyValue
      }
      return propertyValue
    }

    return (
      <Box>
        <BottomNavigation
          style={getStyle(getPropertyValue(), ['text', 'layout', 'style'])}
          className={`${classes.root} ${getPropertyValue().component.classes ?? ''}`}
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
        >
          {renderOptions()}
        </BottomNavigation>
      </Box>
    );
  }

  const onClick = (e) => {
    e && e.stopPropagation();
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        uniquekey,
        type,
        label,
        property,
        propertyValue,
        options,
      },
      false,
      e
    );
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        uniquekey,
        type,
        label,
        property,
        propertyValue,
        isActions: true,
        position,
        options,
      },
      false,
      e
    );
  };
  const updateCellPropertyValue = async (cellId, newValue, initflag) => {
    if (!initflag) {
      var newPvalue = propertyValue;
      newPvalue[cellId] = newValue;
      await setState({ addAction: true });
      state.updateElement(
        id,
        dropzoneID,
        parentID,
        { propertyValue: newPvalue },
        () => {
          onSelect(
            {
              id,
              dropzoneID,
              parentID,
              cellId,
              type,
              label,
              property,
              parentPropertyValue: newPvalue,
              propertyValue: newPvalue[cellId],
            },
            false
          );
        }
      );
    } else {
      var newPvalue = propertyValue;
      newPvalue[cellId] = newValue;
      await setState({ addAction: true });
      state.updateElement(
        id,
        dropzoneID,
        parentID,
        { propertyValue: newPvalue },
        () => {}
      );
    }
  };
  const onCellClick = (target, ref) => {
    // console.warn('on cell click', ref);
    let cellId = target.id;
    let gComponentName = target.gComponentName;
    const property = {
      componentSection: [
        'ID',
        'Classes',
        'Label',
        'IconList',
        'IconPosition',
        'Link',
        'Enabled',
      ],
      gridSection: [],
      dataSource: [{ name: 'LinkTo' }],
      textSection: ['Size', 'Alignment', 'Decoration'],
      styleSection: ['Background', 'Opacity', 'Custom CSS'],
      layoutSection: ['Layout', 'Width', 'Height'],
      tooltipSection: ['event', 'position', 'title'],
      interactionSection: gNavbarDefaultSchema({}).events
    };
    let styles = { ...(propertyValue ? propertyValue : defaultPropertyValue) };
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        cellId,
        type,
        label,
        property,
        parentPropertyValue: styles,
        propertyValue: styles[cellId],
        gComponentName,
      },
      false
    );
  };

  const selectParent = () => {
    onClick(null);
  };

  return (
    <Tooltip
      arrow
      title={propertyValue?.tooltip?.title || ''}
      placement={propertyValue?.tooltip?.position || ''}
      PopperProps={{ disablePortal: true }}
    >
      <Box
        className={'DFooter'}
        id={`${id}_parent`}
        onClick={onClick}
        style={{ overflow: 'scroll' }}
        onMouseOut={(e) => {
          e.stopPropagation();
          setHover(false);
        }}
        onMouseOver={(e) => {
          e.stopPropagation();
          setHover(true);
        }}
      >
        <BottomNavigation
          ref={bottomBarRef}
          style={{
            justifyContent: 'unset',
            overflow: 'scroll',
            position:'sticky',
            ...getStyle(propertyValue, ['text', 'layout', 'style']),
          }}
          id={
            propertyValue
              ? propertyValue.component.id
              : `bottom_bar_${uniquekey}`
          }
          dataid={`${id}_parent`}
          className={`${classes.root} ${id} ${type} ${
            isHover && 'draggable-hover'
          } ${
            !selectedComponent?.cellId &&
            selectedComponent?.id === id &&
            'highlight_component'
          } ${propertyValue?.component?.classes || ''}`}
          dropzoneID={dropzoneID}
          properties={JSON.stringify({ id, dropzoneID, parentID, label, type })}
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels={true}
          onClick={onClick}
        >
          {options.map((e, i) => (
            <Dropzone
              {...dropzoneProps}
              initialElements={[]}
              id={'nav-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id}
              onSelect={onSelect}
              key={`${id}-${(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY])}`}
              i={(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + 1}
              j={`bottom_bar${uniquekey}_item`}
              selectedComponent={selectedComponent}
              setState={setState}
              onCellClick={onCellClick}
              updateCellPropertyValue={updateCellPropertyValue}
              placeholder=' '
              component={NavItem}
              className={'nav-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id}
              type={`Nav Item ${(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + 1}`}
              dropzoneCellID={id}
              properties={JSON.stringify({
                id: 'nav-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id,
                dropzoneID: id,
                parentID: id,
                label: `Bottom Bar Item ${(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + 1}`,
                type: 'Bottom Bar Item',
              })}
              triggerAction={{
                highlightGrid:
                  'nav-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id === selectedComponent?.cellId,
                options,
                selectParent: selectParent,
              }}
              propertyValue={
                propertyValue && propertyValue['nav-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id]
                  ? propertyValue['nav-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id]
                  : {
                      component: { id: `bottom_bar${uniquekey}_item_${(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + 1}` },
                    }
              }
            />
          ))}
        </BottomNavigation>

        {!selectedComponent?.cellId && selectedComponent?.id === id && (
          <MoreActionButton
            handleActions={handleActions}
            type={'BottomBar'}
            isCustomStyle={{
              top: 'auto',
              bottom: propertyValue
                ? 44 + Number(propertyValue.layout.marginBottom)
                : 44,
              right: 4,
              zIndex: 9999,
              position: 'fixed',
            }}
            typeStyle={{
              top: 'auto',
              bottom: propertyValue
                ? 56 + Number(propertyValue.layout.marginBottom)
                : 56,
              right: 10,
              zIndex: 9999,
              position: 'fixed',
            }}
            anchorElement={bottomBarRef}
          />
        )}
      </Box>
    </Tooltip>
  );
};

export default DraggableBottomBar;
