import React,{useRef, useState} from 'react';
import {Paper,Tabs,Tab,Box,Typography,Tooltip, Button} from '@material-ui/core';
import {Draggable,Dropzone,state} from '../../../../libraries/react-page-maker';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import {getComputedStyle,getStyle,replaceTemplateValues} from '../../utils/style';
import {makeStyles} from '@material-ui/core/styles';
import {tab as property} from './property';
import TabPreview from './preview/Tab';
import {tabTemplate,textTemplate} from '../../template';
import {GROUP_COMPONENT_NAMES} from '../../utils/configuration/componentName.contants';
import { getMaxIDByType } from '../../../../helpers/app-designer';
import { TooltipWrapper } from '../common';
import { gTabDefaultSchema } from './schema';
import { COMPONENT_OPTIONS_KEY } from '../../../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 3,
    backgroundColor: theme.color.white,
  },
}));

const tabItem = React.forwardRef((props,tabbarRef) => {
  React.useEffect(() => {
    if(tabbarRef && tabbarRef.current && !props.propertyValue.style) {
        const {value} = getComputedStyle({
          computedStyle: window.getComputedStyle(tabbarRef.current,null),
          componentName: props.j,
          componentId: props.i,
          gComponentName: GROUP_COMPONENT_NAMES.TAB,
        });
      var val=value;
      props.updateCellPropertyValue(props.id,val,true)
    }
  },[tabbarRef]);
  return (
        <div style={{border: props.triggerAction.highlightGrid ? '2px solid #97C1FF' : '2px solid transparent',width: `${100 / props.triggerAction.options.length}%`,...getStyle(props.propertyValue,['text','layout','style'])}} className={props.propertyValue?.component?.classes}>
      <Tab
        ref={tabbarRef}
        {...props}
        onClick={(e) => {
          e.stopPropagation();
          props.onCellClick(props,tabbarRef,props.triggerAction.index);
          props.triggerAction.handleChange(null,props.triggerAction.index);
        }}
        onDoubleClick={(e) => {
          e.stopPropagation();
          props.triggerAction.handleChange(null,props.triggerAction.index);
        }}
        label={<>{props.children}</>}
        className={props.propertyValue?.component?.classes}
        style={{width: `100%`,maxWidth: 'inherit',height: 59,padding: 0,...getStyle(props.propertyValue,['text','layout','style'])}}
      />
      <div id={props.propertyValue?.component?.id} dataId={props.id} style={{display: 'none'}} />
      {props.triggerAction.highlightGrid && (
        <MoreActionButton
          type={'Tab Item'}
          showParentSelection={true}
          selectParent={(e) => {
            e.stopPropagation();
            props.triggerAction.selectParent();
          }}
          showActionButton={false}
          anchorElement={tabbarRef}
        />
      )}
    </div>
  );
});

const tabContenttem = React.forwardRef((props,tabbarRef) => {
  React.useEffect(() => {
    if(tabbarRef && tabbarRef.current && !props.propertyValue.style) {
        const {value} = getComputedStyle({
          computedStyle: window.getComputedStyle(tabbarRef.current,null),
          componentName: props.j,
          componentId: props.i,
          gComponentName: GROUP_COMPONENT_NAMES.TAB,
        });
      var val=value;
      props.updateCellPropertyValue(props.id,val,true)
    }
  },[tabbarRef]);
  return (
    <div
      ref={tabbarRef}
      {...props}
      className = {props.propertyValue?.component?.classes || ''}
      style={
        props.propertyValue
          ? {
            padding: 12,
            position: 'relative',
            ...getStyle(props.propertyValue,['text','style','layout']),
            border: props.triggerAction.highlightContent ? '2px solid #97C1FF' : '2px solid transparent',
            ...(props.triggerAction.value === props.triggerAction.index ? {} : {display: 'none'}),
          }
          : {position: 'relative',padding: 12,border: props.triggerAction.highlightContent ? '2px solid #97C1FF' : '2px solid transparent',...(props.triggerAction.value === props.triggerAction.index ? {} : {display: 'none'})}
      }
      onClick={(e) => {
        e.stopPropagation();
        props.onCellClick(props,tabbarRef);
      }}
    >
      <div id={props.propertyValue?.component?.id} dataId={props.id} style={{display: 'none'}} />
      {props.children}
      {props.triggerAction.highlightContent && (
        <MoreActionButton
          type={'Tab Content'}
          showParentSelection={true}
          selectParent={(e) => {
            e.stopPropagation();
            props.triggerAction.selectParent();
          }}
          showActionButton={false}
          anchorElement={tabbarRef}
        />
      )}
    </div>
  );
});

const DraggableTab = (props) => {
  const {
    id,
    uniquekey,
    showBasicContent,
    showPreview,
    dropzoneID,
    parentID,
    onSelect,
    type,
    iconComponent,
    setState,
    propertyValue,
    idocument,
    dropzoneProps,
    onDropZone,
    initialElements,
    fields,
    options = [
      {label: '',value: '',checked: false},
      {label: '',value: '',checked: false},
    ],
    selectedComponent,
    ...rest
  } = props;
  const [label,setLabel] = useState(props.label);
  const [selectedTab,setSelectedTab] = useState(0);
  const [isEdit,setEdit] = useState(false);
  const [defaultPropertyValue,setDefaultPropertyValue] = useState(null);
  const [isHover,setHover] = useState(false);
  const tapRef=useRef(null);
  const classes = useStyles();

  if(showBasicContent) {
    let data = {...props};
    data.propertyValue = defaultPropertyValue;
    data.options = [
      {[COMPONENT_OPTIONS_KEY]:0,label: '',value: '',checked: false},
      {[COMPONENT_OPTIONS_KEY]:1,label: '',value: '',checked: false},
    ];
    return (
      <Draggable {...data}>
        {iconComponent}
        <TabPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  const handleChange = (event,newValue) => {
    setSelectedTab(newValue);
  };

  const TabPanel = (props) => {
    const {children,value,index,style,...other} = props;

    return (
      <div role="tabpanel" hidden={value !== index} style={{position: 'relative',padding: 12,...style,...(value === index ? {} : {display: 'none'})}} className={props.propertyValue?.component?.classes || ''}{...other}>
        {children}
      </div>
    );
  };

  if(showPreview) {
    return (
      <div className={classes.root}>
        <Paper square>
          <Tabs style={{height: 50,...getStyle(propertyValue,['text','style','layout'])}} id={propertyValue ? propertyValue.component.id : `tab-bar_${uniquekey}`} value={selectedTab} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto" onChange={handleChange} className={`${propertyValue?.component?.classes || ''}`}>
            {options.map((e,i) => {
              return (
                <div style={{width: `${100 / options.length}%`,...getStyle(propertyValue['tab-item-' + i + '-' + id],['text','layout','style'])}} className = {propertyValue['tab-item-' + i + '-' + id]?.component?.classes || ''}>
                  <Tab
                    index={i}
                    label={<>{rest.childNode['tab-item-' + i + '-' + id]}</>}
                    style={{width: `100%`,maxWidth: 'inherit',height: 59,padding: 0,...(propertyValue && propertyValue['tab-item-' + i + '-' + id] ? getStyle(propertyValue['tab-item-' + i + '-' + id],['text','style','layout']) : {})}}
                    className = {propertyValue['tab-item-' + i + '-' + id]?.component?.classes || ''}
                    onClick={() => {
                      handleChange(null,i);
                    }}
                  ></Tab>
                </div>
              );
            })}
          </Tabs>
        </Paper>
        {options.map((e,i) => {
          return (
            <TabPanel value={selectedTab} index={i} style={propertyValue && propertyValue['tab-content-item-' + i + '-' + id] ? getStyle(propertyValue['tab-content-item-' + i + '-' + id],['text','style','layout']) : null} className={propertyValue['tab-content-item-' + i + '-' + id]?.component?.classes || ''}>
              <>{rest.childNode['tab-content-item-' + i + '-' + id]}</>
            </TabPanel>
          );
        })}
      </div>
    );
  }

  const onClick = (e) => {
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        uniquekey,
        type,
        label,
        property,
        propertyValue,
        options,
      },
      false,
      e
    );
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = {x: e.clientX,y: e.clientY};
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        uniquekey,
        type,
        label,
        property,
        propertyValue,
        isActions: true,
        position,
        options,
      },
      false,
      e
    );
  };

  const filterInitialElements = (dID) => {
    if(initialElements && fields && initialElements.length > 0 && initialElements.length >= options.length * 2) {
      return initialElements.filter((e) => e.dropzoneID === dID) || [];
    }

    // var uniquekey = idocument.getElementsByClassName('BUTTON').length + 1;
    // var uniquekey_text = idocument.getElementsByClassName('TEXT').length + 1;
    
    var uniquekey = getMaxIDByType(idocument, 'BUTTON');
    var uniquekey_text = getMaxIDByType(idocument, 'TEXT');

    var d = [];

    if(d) {
      options.map((e,i) => {
        d = [
          ...d,
          {
            ...replaceTemplateValues(tabTemplate,{buttonId: 'tab-button-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id,uniqueKey: uniquekey + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]),index: (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + 1}),
            dropzoneID: 'tab-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id,
            parentID: id,
          },
          {
            ...replaceTemplateValues(textTemplate,{textColor: '#000',TextId: uniquekey_text + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + 1}),
            uniquekey: uniquekey + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + 2,
            id: 'tab-content-label-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id,
            key: 'tab-content-label-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id,
            dropzoneID: 'tab-content-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id,
            label: 'Content ' + ((e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + 1),
            parentID: id,
          },
        ];
        return e;
      });
    }
    return d.filter((e) => e.dropzoneID === dID) || [];
  };
  const updateCellPropertyValue=async (cellId,newValue,initflag)=>{
    if(!initflag){
      var newPvalue=propertyValue;
      newPvalue[cellId]=newValue;
      await setState({ addAction: true });
      state.updateElement(id, dropzoneID, parentID, { propertyValue: newPvalue }, () => {
        onSelect(
          {
            id,
            dropzoneID,
            parentID,
            cellId,
            type,
            label,
            property,
            parentPropertyValue: newPvalue,
            propertyValue: newPvalue[cellId],
          },
          false
        );
      });
    }else{
      var newPvalue=propertyValue;
      newPvalue[cellId]=newValue;
      await setState({ addAction: true });
      state.updateElement(id, dropzoneID, parentID, { propertyValue: newPvalue }, () => {});
    }
    
  }
  const onCellClick = (target,ref,index = null) => {
    // console.warn('on cell click',ref);
    let cellId = target.id;
    let property;
    if(index !== null) {
      property = {
        componentSection: ['ID','Classes','Enabled'], //'Title','IconList','IconPosition','Source','Enabled'],
        gridSection: [],
        textSection: ['Size','Alignment','Decoration'],
        styleSection: ['Background','Opacity','Custom CSS'],
        layoutSection: ['Layout','Width','Height'],
        tooltipSection: ["event", "position", "title"],
        interactionSection: gTabDefaultSchema({}).events
      };
    } else {
      property = {
        componentSection: ['ID','Classes'],
        gridSection: [],
        textSection: [],
        styleSection: ['Background','Opacity','Custom CSS'],
        layoutSection: ['Layout','Width','Height'],
        tooltipSection: ["event", "position", "title"],
        interactionSection: gTabDefaultSchema({}).events
      };
    }
    let styles = {...(propertyValue ? propertyValue : defaultPropertyValue)};
    if(index !== null) {
      handleChange(null,index);
    }
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        cellId,
        type,
        label,
        property,
        parentPropertyValue: styles,
        propertyValue: styles[cellId],
      },
      false
    );
  };

  const selectParent = () => {
    onClick(null);
  };

  return (
    <Draggable {...props} dataid={`${id}_parent`} onClick={onClick}>
      <Tooltip  arrow
        title = {propertyValue?.tooltip?.title || ""}
        placement = {propertyValue?.tooltip?.position || ""}
        PopperProps={{ disablePortal: true }}
      >
      <Box style={{paddingTop:2,paddingBottom:2}}>
        <Box ref={tapRef} className={`${classes.root} ${!selectedComponent.cellId && selectedComponent?.id === id && 'highlight-component'} ${propertyValue?.component?.classes || ''}`} style={{...getStyle(propertyValue,['layout']),paddingTop: 0,paddingLeft: 0,paddingRight: 0,paddingBottom: 0}} >
          <Paper square>
            <Tabs
              style={{...getStyle(propertyValue,['text','layout','style']),marginTop: 0,marginLeft: 0,marginRight: 0,marginBottom: 0}}
              id={propertyValue ? propertyValue.component.id : `tab_${uniquekey}`}
              dataid={`${id}_parent`}
              onMouseOut={(e) => {
                e.stopPropagation();
                setHover(false);
              }}
              onMouseOver={(e) => {
                e.stopPropagation();
                setHover(true);
              }}
              compid={propertyValue.component.id}
              className={`${id} ${type} draggable-component ${isHover && 'draggable-hover'} ${propertyValue?.component?.classes || ''}`}
              dropzoneID={dropzoneID}
              properties={JSON.stringify({id,dropzoneID,parentID,label,type})}
              value={selectedTab}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              onChange={handleChange}
            >
              {options.map((e,i) => (
                <Dropzone
                  {...dropzoneProps}
                  initialElements={filterInitialElements('tab-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id)}
                  id={'tab-item-' +(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id}
                  onDrop={onDropZone}
                  onSelect={onSelect}
                  key={`${id}-${(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY])}`}
                  i={(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + 1}
                  j={`tab${uniquekey}_item`}
                  setState={setState}
                  onCellClick={onCellClick}
                  updateCellPropertyValue={updateCellPropertyValue}
                  placeholder=" "
                  selectedComponent={selectedComponent}
                  component={tabItem}
                  className={'tab-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id}
                  type={`Tab Item`}
                  dropzoneCellID={id}
                  properties={JSON.stringify({id: 'tab-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id,dropzoneID: id,parentID: id,label: `Tab Item ${(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + 1}`,type: 'Tab Item'})}
                  triggerAction={{
                    options,
                    index: i,
                    highlightGrid:'tab-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id === selectedComponent?.cellId,
                    handleChange: handleChange,
                    selectParent: selectParent,
                  }}
                  propertyValue={propertyValue && propertyValue['tab-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id] ? propertyValue['tab-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id] : {component: {id: `tab${uniquekey}_item_${(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + 1}`}}}
                />
              ))}
            </Tabs>
          </Paper>
          {options.map((e,i) => (
            <Dropzone
              {...dropzoneProps}
              initialElements={filterInitialElements('tab-content-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id)}
              id={'tab-content-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id}
              onDrop={onDropZone}
              onSelect={onSelect}
              key={`${id}-${(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY])}`}
              i={(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + 1}
              j={`tab${uniquekey}_content`}
              selectedComponent={selectedComponent}
              setState={setState}
              onCellClick={onCellClick}
              updateCellPropertyValue={updateCellPropertyValue}
              placeholder=" "
              component={tabContenttem}
              className={'tab-content-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id}
              type={`Tab Content`}
              dropzoneCellID={id}
              properties={JSON.stringify({id: 'tab-content-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id,dropzoneID: id,parentID: id,label: `Tab Content ${(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + 1}`,type: 'Tab Content'})}
              triggerAction={{
                value: selectedTab,
                index: i,
                highlightContent: 'tab-content-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id === selectedComponent?.cellId,
                selectParent: selectParent,
              }}
              propertyValue={propertyValue && propertyValue['tab-content-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id] ? propertyValue['tab-content-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id] : {component: {id: `tab${uniquekey}_content_${(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + 1}`}}}
            />
          ))}
          {!selectedComponent.cellId && selectedComponent?.id === id && <MoreActionButton handleActions={handleActions} type={'Tab'} anchorElement={tapRef} />}
        </Box>
        </Box>
      </Tooltip>
    </Draggable>
  );
};

export default DraggableTab;
