import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';


import './index.scss';
import { BsFillCircleFill } from 'react-icons/bs';
import clock from '../../assets/images/clock.svg';
import dot from '../../assets/images/dot.svg';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';


const TaskList =(props)=>
{
      return(
        <Container className="tasklist-head">
          <Grid xs={12} sm={12} md={12} lg={12}>
        <Box>
          <h4 className="head-name">Your task list</h4>
        </Box>
        <Box component="div" className="list-view">
            {props.tasklist.map((data, i) =>
              <Box className="list-grid" key={i} onClick={((e) => this.handleClick(e, data))}>
                
                <Typography variant="label" className="task-name">
                 <BsFillCircleFill className={data.priority}/>
                  {data.name}</Typography>
                <label className="task-desc">
                  <img className="clock" alt="clock" src={clock} />
                  <span>{data.status}</span>
                  <img className="dot" alt="dot" src={dot} />
                  <span>&nbsp;{data.project}</span>
                </label>
              </Box>
            )}

        </Box>
        </Grid>

      </Container>

      );
}
export default TaskList;