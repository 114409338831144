import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  state,
  registerPaletteElements,
} from '../../libraries/react-page-maker';
import AppDesignerLayout from './layout';
import NetworkDetectorHOC from '../../common/components/HOC/NetworkDetector';
import constants from './const';
import palleteElements from './elements/';
import { COMPONENT_NAMES } from './utils/configuration/componentName.contants';
import { addActions, clearPageState, addNonUndoableAction } from '../../actions/app-designer';
import { fetchComponent } from '../../actions/jsbuilder';
import { ActionCreators as UndoActionCreators } from 'redux-undo';
import { connect } from 'react-redux';
import { getComputedStyle, replaceTemplateValues } from './utils/style';

import {
  updateJsBuilderComponent,
  fetchJsBuilderComponent,
  createBehaviour,
  searchBlocks,
  deleteBehaviour,
} from '../../helpers/jsbuilder';
import { autoSaveVariable } from '../../helpers/user-task';
import ChangeImageDialog from './components/change-image-dialog';
import {
  createPage,
  updatePage,
  getPage,
  updateComponentHelper,
  getVarType,
  getImage,
  alertShow,
  getMaxIDByType,
} from '../../helpers/app-designer';
import { debounce } from '@material-ui/core';
import { getTaskDetail, updateTaskDetail } from '../../helpers/user-task';
import { fetchBusinessFunctionDetails, saveVariables, saveGraphJson, getPageMenuAsync, updateUIJson } from '../../helpers/business-function';
import {getCSSAssetsUsedInProj} from "../../helpers/assets";
import { loadTaskVariable,loadApiMessage } from '../../actions/user-task';
import { saveProjectPathUrl } from '../../helpers/project-detail';
import { textTemplate } from './template';
import {  getPermissions, s3Url, pageViewPermissions, _deepCloneJSON, setSessionVal} from '../../utils/common';
import { fetchUserPropertyList } from '../../helpers/settings/user-properties';
import { fetchAllVariableList as fetchGlobalVar } from '../../helpers/global-variable';
import { fetchVariableList as fetchProjectVar } from '../../helpers/project-settings';
import { SHAPE_TYPES } from '../../libraries/mxgraph-wrapper/shape/types';
import { EVENTS_SCHEMA } from './utils/configuration/schema';
import { COMPONENT_OPTIONS_KEY } from '../../constants';


const PAGE_BOTTOM_PADDING_WITH_BOTTOMBAR = 60;
const DEFAULT_MARGIN = 12;
const DEFAULT_PROGRESSBAR_TITLE = "Progressing..."
const styles = (theme) => ({
  root: {
    margin: theme.spacing(3, 0, 3),
  },
});

const defaultPropertySection = {
  doesComponentSectionEnabled: true,
  doesGridSectionEnabled: true,
  doesTextSectionEnabled: true,
  doesStyleSectionEnabled: true,
  doesLayoutSectionEnabled: true,
};
let variableType = '';
let currentProjectName = '';
let skipActionFromUndo = false;
let params = {};
let LanguageTokens={};
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentState: {},
      exposeVarSuccess: {},
      selectedComponent: {
        property: defaultPropertySection,
      },
      isActions: false,
      position: null,
      clipboard: null,
      addElement: null,
      isPage: true,
      propertyValue: {},
      shouldCanvasRefreshed: false,
      addAction: false,
      deviceLayout: 'iPhone', // values - iPhone / iPad / Tablet / Android,
      deviceOrientation: 'Portrait', // values - Portrait / Landscape
      lock:this.props.location?.state?.behaviourData?.orientation ?? 'both',//Screen orientation lock from app settings
      activeBreadCrumb: [],
      showChangeImageDialog: false,
      isFirstTimeCreatePage: true,
      previewOpen: false,
      fetchJsBuilderData: false,
      snapshotImage: null,
      adVarList: [],
      initialVarCount: -1,
      pageLaneHasMenu: false,
      pageName: this.props.location?.state?.behaviourData?.pageName ?? 'page',
    };
    this._snapshot = null;
    this._onSelect = this._onSelect.bind(this);
    this._onDrop = this._onDrop.bind(this);
    this.refreshPageState = this.refreshPageState.bind(this);
    this.saveExposedVariable = this.saveExposedVariable.bind(this);
    this.setBackgroundImage = this.setBackgroundImage.bind(this);
    this.toDataURL = this.toDataURL.bind(this);
    this._adjustPageBottomPadding = this._adjustPageBottomPadding.bind(this);
    // register all palette elements
    registerPaletteElements(palleteElements);
    this.pageViewPolicy = pageViewPermissions();
  }
  _adjustPageBottomPadding = (presentState) => {
    /*
    https://appsfreedom.atlassian.net/wiki/spaces/EngDesign/pages/2703556640/TDD+UAT+-+Issue+I
    Issue: Last component is hiding behind the Footer/Bottom bar so can't edit that component and because of that can't place another component after the last added component.
    Fix: When user drags and drops footer /bottom bar. Add padding bottom of 50 px to the page style
    */
    if (!this.state.propertyValue.layout.paddingBottom) {
      var propsVal = this.state.propertyValue;
      propsVal.layout.paddingBottom = PAGE_BOTTOM_PADDING_WITH_BOTTOMBAR;
      this.setState({ propertyValue: propsVal });
    }
  };

  componentDidMount() {
    currentProjectName = this.props.match.params.id;
    saveProjectPathUrl(currentProjectName, `${this.props.history.location.pathname}`);
    fetchUserPropertyList();
    fetchGlobalVar('dmnList');
    fetchProjectVar(this.props.match.params.id);
    //this._unsubscribe = this.props.history.addListener('focus', () => {
    let openBehavior =
      this.props.history.location?.state?.openBehavior ?? 'false';
    let behaviourData = this.props.history.location?.state?.behaviourData;
    saveProjectPathUrl(
      currentProjectName,
      `${this.props.history.location.pathname}`,
      openBehavior,
      behaviourData
    );
    fetchBusinessFunctionDetails(
      this.props.match.params.id,
      this.props.match.params.ProcessId
    );

    // do something
    this.setState({ currentState: [], propertyValue: {} }, () => {
      this.props.clearPageState();
      this.props.clearHistory();
      state.clearState(() => {
        this.getPageDetails();

        let taskName = this.props.match.params?.TaskId ||  this.props.location?.state?.TaskName || "";
        if(taskName){
          getTaskDetail(
            this.props.match.params?.id,
            this.props.match.params?.ProcessId,
            taskName
          );
        }
        state.addEventListener('change', this._stateChange);
        LanguageTokens={};
        document.addEventListener('tokenGen', this._tokenGen);
      });
    });

    if (!this.pageViewPolicy.allowView()) {
      this.props.history.push('/forbidden');
    }
  }
  _tokenGen=(e)=>{
    if(e.detail.label){
      LanguageTokens[e.detail.id]=e.detail.label;
      if(e.detail?.placeholder){
        LanguageTokens[`${e.detail.id}_plh`]=e.detail?.placeholder;
      }
    }
  }

  componentDidUpdate = (prevPorps, prevState) => {
    //Project tab navigations
    if (
      prevPorps.match.params.id != null &&
      prevPorps.match.params.id !== this.props.match.params.id &&
      prevPorps != null
    ) {
      state.removeEventListener('change', this._stateChange);
      LanguageTokens={};
      document.removeEventListener('tokenGen',this._tokenGen);
      currentProjectName = this.props.match.params.id;
      this.setState(
        { currentState: [], propertyValue: {}, isFirstTimeCreatePage: true,pageName: this.props.location?.state?.behaviourData?.pageName ?? 'page'},
        () => {
          let openBehavior =
            this.props.history.location?.state?.openBehavior ?? 'false';
          let behaviourData = this.props.history.location?.state?.behaviourData;
          saveProjectPathUrl(
            currentProjectName,
            `${this.props.history.location.pathname}`,
            openBehavior,
            behaviourData
          );
          fetchBusinessFunctionDetails(
            this.props.match.params.id,
            this.props.match.params.ProcessId
          );

          // do something
          this.props.clearPageState();
          this.props.clearHistory();
          state.clearState(() => {
            this.getPageDetails();
            //getTaskDetail(this.props.match.params?.id, this.props.match.params?.ProcessId, this.props.match.params?.TaskId);
            state.addEventListener('change', this._stateChange);
            document.addEventListener('tokenGen', this._tokenGen);
          });
        }
      );
    }

    if(prevPorps.presentState !== this.props.presentState){
      let prevPropsPresent = prevPorps.presentState;
      let newPropsPresent = this.props.presentState;
      if(prevPropsPresent.length < newPropsPresent.length){                    //redo action // normal action
        let reduxVarList = newPropsPresent.length ? newPropsPresent[newPropsPresent.length - 1].varList : [];
        if(JSON.stringify(reduxVarList) !== JSON.stringify(this.state.adVarList)){
          this.setState({ adVarList: reduxVarList });
        }
      }
      if(prevPropsPresent.length > newPropsPresent.length){                     //undo action
        let reduxVarList = newPropsPresent.length ? newPropsPresent[newPropsPresent.length - 1].varList : [];
        if(JSON.stringify(reduxVarList) !== JSON.stringify(this.state.adVarList)){
          this.setState({ adVarList: reduxVarList });
        }
      }
    }

    if(JSON.stringify(prevPorps.varList) !== JSON.stringify(this.props.varList)){
      if(JSON.stringify(this.state.adVarList) !== JSON.stringify(this.props.varList?.variable)){
        let variableList = this.props.varList?.variable || [];
        this.setState({ 
          adVarList: variableList,
          initialVarCount: this.state.initialVarCount === -1 ? variableList.length : this.state.initialVarCount
        });
      }
    }

    if(JSON.stringify(prevState.adVarList) !== JSON.stringify(this.state.adVarList)){
      if(
          this.state.adVarList.length >= this.state.initialVarCount && 
          JSON.stringify(this.state.adVarList) !== JSON.stringify(this.props.varList?.variable)
        ){
          this.saveExposedVariable(this.state.adVarList);
      }
    }
    /*
    Issue: Since user is able to continue the development without internet connectivity, In the integration builder, it throws a message saying 'Unable to process, please contact admin'
    Fix: 
      1. We need to show an alert when network goes offline and allow him to continue designing,
      2. Sync the offline data (page state) when network comes online
    */
    if (this.props.isOffline) {
      // Offline
      if (!prevPorps.isOffline) {
        // console.log("network: internet disconnect alert....")
        alertShow({
          type: 'error',
          message:
            'You have lost your network connection!',
        });
      }
    } else {
      // Online
      /*if (prevPorps.isOffline) {
        // console.log("network: syncing offline data to online....")
        this.setState({ addAction: true }, () => {
          this._stateChange();
          alertShow({
            type: 'success',
            message: 'Syncing offline data...!',
          });
        });
      }*/
    }

    if(prevPorps.graphJson !== this.props.graphJson){
      this.checkPageLaneHasMenu(this.props.graphJson);
    }
  };

  hasMenuIfSamePage = (node = {}, laneNodes = []) => {
    const { PageId } = this.props.match.params;
    let menu = null;
    if(node.uid === PageId){
      menu = laneNodes.find(e => e.type === SHAPE_TYPES.MENU) || null;
    }
    return !!menu;
  }

  checkPageLaneHasMenu = (graphJson) => {
    const { lanes } = graphJson?.graph || {};
    let menuEnabledInLane = false;
    lanes &&
    lanes.forEach((lane) => {
      lane &&
      lane.children &&
      lane.children.forEach((node) => {
        if(node.type === SHAPE_TYPES.TASK){
          node &&
          node.childTask &&
          node.childTask.forEach(f => {
            if(this.hasMenuIfSamePage(f, lane.children)){
              menuEnabledInLane = true;
            }
          })
        }else {
          if(this.hasMenuIfSamePage(node, lane.children)){
            menuEnabledInLane = true;
          }
        }
      })
    })

    this.setState({ pageLaneHasMenu: menuEnabledInLane });
  }

  importExternalCSSFile = () => {
    getCSSAssetsUsedInProj(currentProjectName).then((data) => {
      if (data) {
        data.map((d) => {
          let link = s3Url(d.object);
          const createCSSLink = (externallink) => {
            let cssLink = document.createElement('link');
            cssLink.href = externallink;
            cssLink.rel = 'stylesheet';
            cssLink.type = 'text/css';
            return cssLink;
          };
          let openBehavior =
            this.props.history.location?.state?.openBehavior ?? 'false';
          if (openBehavior != 'true') {
            let ad =
              document.getElementById('iframe')?.contentDocument?.head ?? null;
            let adPreview =
              document.getElementById('iframepreview')?.contentDocument?.head ??
              null;
            if (ad) {
              ad.appendChild(createCSSLink(link));
            }
            if (adPreview) {
              adPreview.appendChild(createCSSLink(link));
            }
          }
        });
      }
    });
  };

  refactorOptions = (res) => {
    let arr = res.data.page.content
    arr.forEach(e => {
      if(e?.options){
        if(e.options[0]?.[COMPONENT_OPTIONS_KEY]==undefined){
          e.options.forEach((j,idx)=>{ 
            if(typeof j == "string" || typeof j == "number"){
              e.options[idx] = {[COMPONENT_OPTIONS_KEY]:idx}
            }else{
              j[COMPONENT_OPTIONS_KEY] = idx
            }
          })
        }
      }
    })
  }

  getPageDetails = () => {
    getPage({
      projectName: this.props.match.params?.id,
      pageName: this.props.match.params?.PageId,
      functionName: this.props.match.params?.ProcessId,
      pageFlowName: this.props.match.params?.TaskId,
    })
      .then((pageDetails) => {
        fetch(pageDetails.ui)
          .then((response) => response.json())
          .then(async (response) => {
            this.refactorOptions(response) // added for backward compatability for options with id V6X-1207
            let menuPageJson;
            let pagePropertyValue = response.data.page.propertyValue;
            let menuEnabled = true; // By default let menu is enabled for all page
            if (pagePropertyValue?.component?.hasOwnProperty("enableMenu")) {
              menuEnabled = pagePropertyValue.component.enableMenu === "enable";
            }

            if (!pagePropertyValue?.component?.menuStyle && menuEnabled) { // If menu is enabled and the page itself is not a menu page, then we need to inject menu component into the page
              menuPageJson = await getPageMenuAsync(this.props.match.params?.id, this.props.match.params?.ProcessId, this.props.match.params?.PageId)
            }

            this.setState(
              { addAction: false, isFirstTimeCreatePage: false, menuPageJson },
              () => {
                let res = response;
                if (menuPageJson) { // Injecting menu component into page json 
                  let [menuCom] = menuPageJson.data.page.content
                  res.data.page.content = [
                    ...res.data.page.content,
                    {
                      ...menuCom,
                      showPreview: true,
                      menuPagePropertyValue: menuPageJson.data.page.propertyValue,
                      shadowElement: true
                    }
                  ]
                }

                this.props.addActions(response, this.state.adVarList, this.props.graphJson);
                state.clearState(() => {
                  this.setState({ shouldCanvasRefreshed: true });
                  this.props.clearHistory();
                  //Applay External CSS to
                  this.importExternalCSSFile();
                });
                
              }
            );
          });
      })
      .catch((error) => {
        this.createPage();
      });
  };

  toggleScreenMenu = async (data) =>{

    let isEnabled = data.propertyValue.component.enableMenu === "enable";
    let menuPageJson = this.state.menuPageJson;
    if (!menuPageJson) { // If we don't have menuPageJson locally, then get it from API
      menuPageJson = await getPageMenuAsync(this.props.match.params?.id, this.props.match.params?.ProcessId, this.props.match.params?.PageId)
    }
    
    if (!menuPageJson && isEnabled) {
      alertShow({
        message: "To use this feature enable menu for the app!",
        type: 'error',
      });
      return ;
    }

    
    this.setState({selectedComponent: data}, () =>{
      let menuElement;
      if(menuPageJson){
        let [menuCom] = menuPageJson.data.page.content
        menuElement = {
          ...menuCom,
          showPreview: true,
          menuPagePropertyValue: menuPageJson.data.page.propertyValue,
          shadowElement: true
        }
      }


      let currentState = [...this.state.currentState];
      currentState = currentState.filter(e => !e.shadowElement)

      if (isEnabled){

        let currentState = [...this.state.currentState];
        currentState = currentState.filter(e => !e.shadowElement)
        this.setState({ currentState: [], propertyValue: {} }, () => {
          // this.props.clearPageState();
          this.props.clearHistory();
          state.clearState(() => {
            let pageJson = {
              data: { page: { content: currentState, propertyValue: data.propertyValue } },
            }

            this.setState(
              { addAction: false, isFirstTimeCreatePage: false },
              () => {
                let res = pageJson;
                res.data.page.content = [
                  ...res.data.page.content,
                  {
                    ...menuElement,
                  }
                ]

                this.props.addActions(res, this.state.adVarList, this.props.graphJson);
                state.clearState(() => {
                  this.setState({ shouldCanvasRefreshed: true, addAction: true });
                  this.props.clearHistory();
                  //Applay External CSS to
                  this.importExternalCSSFile();
                  this._stateChange();
                });
              }
            );
            state.addEventListener('change', this._stateChange);
          });
        });
        
      }else{
        let cb = () => {
          let data2 = {
            data: { page: { content: currentState, propertyValue: data.propertyValue } },
          };
          this.setState({ addAction: true }, () => {
            this.props.addActions(data2, this.state.adVarList, this.props.graphJson);
            this.pageStateUpdate(data2);
          });
        }

        if (!menuElement) {
          cb();
        }else{
          state.removeElement(menuElement.id, menuElement.dropzoneID, menuElement.parentID, cb)
        }
        
      }

    });
    
  }

  createPage = () => {
    createPage(
      {
        //    name: this.props.match.params?.PageId, // name given by the user in user task
        name: this.props.location?.state?.behaviourData?.pageName || 'page',
        uid: this.props.match.params?.PageId, // name given by the user in user task
        projectName: currentProjectName,
        uiObject: JSON.stringify({
          data: { page: { content: [], propertyValue: {} } },
        }),
        businessFunctionName: this.props.match.params?.ProcessId,
        dataObject: '',
        pageFlowName: this.props.match.params?.TaskId, //user task name
      },
      () => {
        this.setState(
          { addAction: false, isFirstTimeCreatePage: false },
          () => {
            this.importExternalCSSFile();
          }
        );
      }
    );
  };

  updatePage = (data, snapshot) => {
    updatePage({
      data: {
        // name: 'newpage1', // name given by the user in user task
        projectName: currentProjectName,
        uiObject: JSON.stringify(data),
        dataObject: snapshot,
        language:LanguageTokens,
      },
      pageName: this.props.match.params?.PageId,
      functionName: this.props.match.params?.ProcessId,
      pageFlowName: this.props.match.params?.TaskId,
    });
  };

  getPageSnapshot = (snapshotImage) => {
    this._snapshot = snapshotImage;
  };

  pageStateUpdate = (data) => {
    debounce(() => {
      if (data?.data?.page?.content){
        data.data.page.content = data?.data?.page?.content.filter(f=>!f.shadowElement)
      }
      this.updatePage(data, this._snapshot);
    }, 1000)();
  };

  async componentWillUnmount() {
    state.removeEventListener('change', this._stateChange);

    this.props.clearHistory();
    // await this.setState({ currentState: [], propertyValue: null });
    await state.clearState(() => {
      // console.log('done');
    });

    // this.setState({ currentState: {}, selectedComponent: {}, propertyValue: {} });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.presentState) {
      return {
        exposeVarSuccess: props.apiMsg,
        currentState: props.presentState.length
          ? props.presentState[props.presentState.length - 1].ui.data.page.content
          : [],
        propertyValue:
          props.presentState.length &&
          Object.keys(
            props.presentState[props.presentState.length - 1].ui.data.page
              .propertyValue
          ).length
            ? props.presentState[props.presentState.length - 1].ui.data.page
                .propertyValue
            : state.propertyValue,
      };
    }
    return null;
  }
  handleSkipAction = (status = false) => {
    skipActionFromUndo = status;
  }

  _stateChange = (s) => {
    setTimeout(async () => {
      const newState = state.getStorableState();
      if (this.state.addAction) {
        const pageData = {
          data: {
            page: {
              content: newState,
              propertyValue: this.state.propertyValue,
            },
          },
        };
        if(skipActionFromUndo){
          this.props.addNonUndoableAction(pageData);
          this.handleSkipAction(false);
        }else {
          this.props.addActions(pageData, this.state.adVarList, this.props.graphJson);
        }
        await this.setState({ addAction: false });
        this.pageStateUpdate(pageData);
      }
    }, 5);
  };

  refreshPageState = () => {
    this.setState({ currentState: [], propertyValue: {},isFirstTimeCreatePage:true }, () => {
      this.props.clearPageState();
      this.props.clearHistory();
      state.clearState(() => {
        this.getPageDetails();
        state.addEventListener('change', this._stateChange);
      });
    });
  };

  toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  setBackgroundImage = (imageName) => {
    try {
      let imgPromise = new Promise(async (resolve, reject) => {
        if (imageName) {
          const imageObj = await getImage(
            this.props.match.params?.id,
            imageName
          );
          if (imageObj && imageObj.length) {
            let image = s3Url(imageObj[0].object);
            this.toDataURL(image)
              .then((dataUrl) => {
                resolve(dataUrl);
              })
              .catch(reject);
          } else {
            reject('imageObj not exist');
          }
        } else {
          resolve('');
        }
      });

      imgPromise
        .then((img) => {
          let setBackground = function (src) {
            this.style.backgroundImage = src ? `url('${src}')` : '';
          };
          let idocument = document.getElementById('iframe').contentDocument;
          let node = idocument.getElementById('__pillirPageContent');
          setBackground.call(node, img);
          if (
            document.getElementById('iframepreview')?.contentDocument &&
            document.getElementById('iframepreview').contentWindow.document.body
          ) {
            let pdocument =
              document.getElementById('iframepreview').contentDocument;
            let node = pdocument.getElementById('__pillirPageContent');
            setBackground.call(node, img);
          }
        })
        .catch(console.log);
    } catch (e) {
      //  console.log(e)
    }
  };

  _getPageProperties = () => {
    return {
      uuid:this.props.match.params?.PageId,
      id: null,
      dropzoneID: null,
      property: {
        componentSection: ['maxWidth'],
        gridSection: [],
        textSection: [],
        styleSection: [
          'Classes',
          'Background',
          'Opacity',
          'Custom CSS',
          'BackgroundImage',
        ],
        layoutSection: ['Layout', 'Width', 'Height'],
        interactionSection: [ 
          EVENTS_SCHEMA.PAGE_SHOW, 
          EVENTS_SCHEMA.SWIPE_LEFT,
          EVENTS_SCHEMA.SWIPE_RIGHT 
        ]
      },
      propertyValue: this.state.propertyValue,
    };
  };

  _checkValidComponnet = (data) =>{

    let { pageLaneHasMenu } = this.state;
    let {component} = this.state.propertyValue

    let menuEnabled = pageLaneHasMenu;
    if (component?.hasOwnProperty("enableMenu")) {
      menuEnabled = component.enableMenu === "enable";
    }

    const MENU_TYPE_COMPONENT = [constants.BOTTOMBAR, constants.NAVBAR, constants.PANEL]

    if (MENU_TYPE_COMPONENT.indexOf(data?.type) > -1 && !component?.menuStyle && menuEnabled ) {
      return `${MENU_TYPE_COMPONENT.join(", ")} components are not allowed with global menu!`
    }
    return ;
  }


  _onSelect = async (data, flag) => {

    if (!flag) {
      const { isActions = null, position = null } = data;
      let activeBreadCrumb = this.getActiveBreadCrumbPath(data);
      await this.setState({
        selectedComponent: data,
        isActions: isActions ? !this.state.isActions : false,
        position: position ? position : null,
        activeBreadCrumb,
      });
    } else {
      const {
        id,
        dropzoneID,
        parentID,
        uniquekey,
        label,
        cellId = null,
        parentPropertyValue = null,
        options = [],
        propertyValue = {},
        imageUrl = '',
      } = data;
      if (cellId) {
        if (parentPropertyValue) {
          let data = { ...parentPropertyValue };
          data[cellId] = propertyValue;
          await this.setState({ addAction: true });
          state.updateElement(
            id,
            dropzoneID,
            parentID,
            { propertyValue: data },
            () => {
              setTimeout(() => {
                this.highlightDComponents(cellId, 0, false, false);
              }, 100);
            }
          );
        }
      } else {
        if (id && dropzoneID) {
          await this.setState({ addAction: true });
          state.updateElement(
            id,
            dropzoneID,
            parentID,
            { label, uniquekey, options, propertyValue, imageUrl },
            () => {
              setTimeout(() => {
                this.highlightDComponents(id, 0, false, false);
              }, 100);
            }
          );
        } else {
          let data = {
            data: { page: { content: this.state.currentState, propertyValue } },
          };
          this.props.addActions(data, this.state.adVarList, this.props.graphJson);
          // if(propertyValue?.component?.imageName){
          if (
            this.state.selectedComponent?.propertyValue?.component
              ?.imageName !== propertyValue?.component?.imageName
          ) {
            this.setBackgroundImage(propertyValue?.component?.imageName);
          }
          // }
          this.pageStateUpdate(data);
        }
      }

      if (
        propertyValue?.component?.id !==
        this.state.selectedComponent?.propertyValue?.component?.id
      ) {
        this.updateJsComponent({ name: propertyValue?.component?.id }, true);
        let compUId = this.state.selectedComponent?.id;
        let obj = {
          newName: propertyValue?.component?.id,
          oldName: this.state.selectedComponent?.propertyValue?.component?.id
        }
        this.handleComponentPropertyWithInteraction(compUId, {...propertyValue?.component,...obj});
      }

      let breadCrumbPath = this.getActiveBreadCrumbPath(data);
      this.setState({
        selectedComponent: {
          ...this.state.selectedComponent,
          ...data,
        },
        activeBreadCrumb: breadCrumbPath,
      });
    }
  };

  _isDSMappingAllowed = () =>{
    let validationMsg;
    const { selectedComponent } = this.state
    if (selectedComponent.type === COMPONENT_NAMES.DATAGRID_HEADER_CELL) {
      const dataGridPV = this._getParentElementPropertyValue();
      let hasDs = false;
      if (dataGridPV?.dataSource && Array.isArray(dataGridPV.dataSource)){
        const [ds] = dataGridPV.dataSource;
        if (ds?.Data?.selfVal && ds.Data.selfVal.startsWith("{{")) {
          hasDs = true;
        }
      }
      if(!hasDs){
        validationMsg = "The Datagrid should be mapped with a table variable first!"
      }
    }
    return validationMsg;
  }

  _getParentElementPropertyValue = () => {
    let parentPropVal;
    const { selectedComponent } = this.state
    const parent = state.traverseAndReturnElement(selectedComponent.id, selectedComponent.dropzoneID, selectedComponent.parentID); // Find the parent elem
    if (parent?.propertyValue){
      parentPropVal = parent.propertyValue
    }
    
    return parentPropVal;
  }

  updateComponent = (key, value) => {
    updateComponentHelper(this, key, value,(ds)=>{
      this._triggerHooksToUpdateAdditionalElements(ds);

    });
  };

  getActiveBreadCrumbPath = (data) => {
    let activeBreadCrumb = [];
    if (data.dropzoneID && data.dropzoneID !== 'root') {
      let idocument = document.getElementById('iframe')?.contentDocument;
      let dropzoneID = data.dropzoneID;
      let isFinished = false;
      while (!isFinished) {
        let ele = idocument?.getElementsByClassName(dropzoneID);
        if (ele && ele[0] && ele[0].getAttribute('properties')) {
          let obj = JSON.parse(ele[0].getAttribute('properties'));
          activeBreadCrumb = [obj, ...activeBreadCrumb];
          if (obj.dropzoneID !== 'root') {
            dropzoneID = obj.dropzoneID;
          } else {
            isFinished = true;
          }
        } else {
          isFinished = true;
        }
      }
    }
    activeBreadCrumb.push(data);
    if (data.dropzoneID !== null) {
      // Page path in brudcrumb
      activeBreadCrumb = [this._getPageProperties(), ...activeBreadCrumb];
    }
    return activeBreadCrumb;
  };

  canUpdate = () => {
    return this.pageViewPolicy.allowUpdate();
  };

  _onDrop = async (data, cb) => {
    
    if (this.canUpdate()) {

      let validationMsg = this._checkValidComponnet(data);
      if (validationMsg){
        alertShow({
          message: validationMsg,
          type: 'error',
        });
        return ;
      }
      
      let idocument = document.getElementById('iframe').contentDocument;
      await this.setState({ addAction: true });

      // Add Margin between each element 24 px. This way, the design by default get a space and allows the user to add content inbetween. Not applied for bottombar (navbar) , footer, topbar, etc.
      if (
        [
          constants.BOTTOMBAR,
          constants.FOOTER,
          constants.TOPBAR,
          constants.NAVBAR,
          constants.PANEL,
          constants.DIALOG,
          constants.PROGRESSBAR,
        ].indexOf(data.type) === -1
      ) {
        data.propertyValue.layout.marginTop = DEFAULT_MARGIN;
        data.propertyValue.layout.marginBottom = DEFAULT_MARGIN;
      }

      // no need to ask id and label again
      if (data.payload && data.payload.dropped) {
        return cb(data);
      }

      if(data.type === constants.PROGRESSBAR){
        data.label = DEFAULT_PROGRESSBAR_TITLE
      }
      if(data.type === constants.GAUGE_CHART){
        data.label = "%";
      }

      let label = data.label;
      const id = Math.floor(Date.now() / 1000) + '';

      let uniquekey = getMaxIDByType(idocument, data.type);
      // if (idocument.getElementsByClassName(data.type)) {
      //   uniquekey = idocument.getElementsByClassName(data.type).length + 1;
      // }
      var text_uniquekey =
        idocument.getElementsByClassName(constants.TEXT).length + 1;
      var grid_uniquekey =
        idocument.getElementsByClassName(constants.GRID).length + 1;
      let initialElements = [];
      switch (data.type) {
        case constants.LISTVIEW:
          // Default style adjustment
          data.propertyValue.style = {
            ...data.propertyValue.style,
            backgroundColor: 'rgb(255,255,255)',
          };
          initialElements = [];
          cb({
            ...data,
            label,
            id,
            uniquekey,
            initialElements,
            payload: { dropped: true },
            propertyValue: {
              ...data.propertyValue,
              component: {
                ...data.propertyValue.component,
                id: `${data.propertyValue.component.id}_${uniquekey}`,
              },
            },
            fields: [...initialElements],
            options: data?.options ? data.options : [],
            disableDraggable: data.disableDraggable || false,
          });
          break;
        case constants.COLLAPSIBLE:
          initialElements = [];
          cb({
            ...data,
            label,
            id,
            uniquekey,
            initialElements,
            payload: { dropped: true },
            propertyValue: {
              ...data.propertyValue,
              component: {
                ...data.propertyValue.component,
                id: `${data.propertyValue.component.id}_${uniquekey}`,
              },
            },
            fields: [...initialElements],
            options: data?.options ? data.options : [],
            disableDraggable: data.disableDraggable || false,
          });
          break;
        case constants.TOPBAR:
        case constants.PANEL:
          if (idocument.getElementsByClassName('DTopBar').length != 0) {
            alertShow({
              message: "Panel and TopBar can't be used together!",
              type: 'error',
            });
            return;
          }
          let lableid = data.type === constants.TOPBAR ? 'Header_' : 'Panel_';
          if (this.state.addElementToRoot) {
            if (data.type === constants.TOPBAR) {
              initialElements = [
                {
                  ...replaceTemplateValues(textTemplate, {
                    textColor: '#fafafa',
                    TextId: text_uniquekey,
                  }),
                  dropzoneID: lableid + id,
                  id: lableid + '1604577350',
                  key: lableid + '1604577350',
                  label: 'Header',
                  uniquekey: text_uniquekey,
                  parentID: id,
                },
              ];
            }
            this.state.addElementToRoot(
              {
                ...data,
                label,
                id,
                uniquekey,
                dropzoneID: 'root',
                parentID: 'root',
                initialElements,
                payload: { dropped: true },
                propertyValue: {
                  ...data.propertyValue,
                  component: {
                    ...data.propertyValue.component,
                    id: `${data.propertyValue.component.id}_${uniquekey}`,
                  },
                },
                fields: [...initialElements],
                options: data?.options ? data.options : [],
                disableDraggable: data.disableDraggable || false,
              },
              false
            );
          }
          break;
        case constants.FOOTER:
        case constants.BOTTOMBAR:
          if (idocument.getElementsByClassName('DFooter').length != 0) {
            return;
          }
          if (this.state.addElementToRoot) {
            if (data.type === constants.FOOTER) {
              initialElements = [
                {
                  ...replaceTemplateValues(textTemplate, {
                    textColor: '#fafafa',
                    TextId: text_uniquekey,
                  }),
                  dropzoneID: 'Footer_' + id,
                  id: '16045773503',
                  key: '16045773503',
                  label: 'Footer',
                  uniquekey: text_uniquekey,
                  parentID: id,
                },
              ];
            }
            if(!this.checkMenuPage()){
              this._adjustPageBottomPadding();
            }
            this.state.addElementToRoot(
              {
                ...data,
                label,
                id,
                uniquekey,
                dropzoneID: 'root',
                parentID: 'root',
                initialElements,
                payload: { dropped: true },
                propertyValue: {
                  ...data.propertyValue,
                  component: {
                    ...data.propertyValue.component,
                    id: `${data.propertyValue.component.id}_${uniquekey}`,
                  },
                },
                fields: [...initialElements],
                options: data?.options ? data.options : [],
                disableDraggable: data.disableDraggable || false,
              },
              false
            );
          }
          break;
        case constants.TABLE:
          initialElements = [];
          cb({
            ...data,
            label,
            id,
            uniquekey,
            initialElements,
            payload: { dropped: true },
            propertyValue: {
              ...data.propertyValue,
              component: {
                ...data.propertyValue.component,
                id: `${data.propertyValue.component.id}_${uniquekey}`,
              },
            },
            fields: [...initialElements],
            options: data?.options ? data.options : [],
            disableDraggable: data.disableDraggable || false,
          });
          break;
        case constants.NAVBAR:
          if (idocument.getElementsByClassName('DTabbar').length != 0) {
            return;
          }
          if (this.state.addElementToRoot) {
            this.state.addElementToRoot(
              {
                ...data,
                label,
                id,
                uniquekey,
                initialElements,
                dropzoneID: 'root',
                parentID: 'root',
                propertyValue: {
                  ...data.propertyValue,
                  component: {
                    ...data.propertyValue.component,
                    id: `${data.propertyValue.component.id}_${uniquekey}`,
                  },
                },
                payload: { dropped: true },
                options: data?.options ? data.options : [],
                disableDraggable: data.disableDraggable || false,
              },
              false
            );
          }
          break;
        case constants.DIALOG:
          if (this.state.addElementToRoot) {
            this.state.addElementToRoot(
              {
                ...data,
                label,
                id,
                uniquekey,
                initialElements,
                dropzoneID: 'root',
                parentID: 'root',
                propertyValue: {
                  ...data.propertyValue,
                  component: {
                    ...data.propertyValue.component,
                    id: `${data.propertyValue.component.id}_${uniquekey}`,
                  },
                },
                payload: { dropped: true },
                options: data?.options ? data.options : [], 
                disableDraggable: data.disableDraggable || false,
              },
              false
            );
          }
          break;
        case constants.PROGRESSBAR:
          if (this.state.addElementToRoot) {
            this.state.addElementToRoot(
              {
                ...data,
                label,
                id,
                uniquekey,
                initialElements,
                dropzoneID: 'root',
                parentID: 'root',
                propertyValue: {
                  ...data.propertyValue,
                  component: {
                    ...data.propertyValue.component,
                    id: `${data.propertyValue.component.id}_${uniquekey}`,
                  },
                },
                payload: { dropped: true },
                options: data?.options ? data.options : [], 
                disableDraggable: data.disableDraggable || false,
              },
              false
            );
          }
          break;
        default:
          cb({
            ...data,
            label,
            id,
            uniquekey,
            dropzoneID: 'root',
            parentID: 'root',
            propertyValue: {
              ...data.propertyValue,
              component: {
                ...data.propertyValue.component,
                id: `${data.propertyValue.component.id}_${uniquekey}`,
              },
            },
            payload: { dropped: true },
            options: data?.options ? data.options : [],
            disableDraggable: data.disableDraggable || false,
          });
          break;
      }
    }
  };

  navigateTab = (path) => {
    // this.props.history.push(path);
    setSessionVal(
      'routeParam',
      JSON.stringify(this.props.history?.location?.state)
    );
    this.props.history.push(`/Project/${this.props.match.params.id}/${path}`);
  };

  refreshPageContent = () => {
    let idocument = document.getElementById('iframe').contentDocument;
    idocument.getElementById('__pillirPageContent').click();
  };

  _detachBehavious = () => {
    const { propertyValue } = this.state.selectedComponent;
    let componentId = propertyValue?.component?.id;
    if (componentId) {
      let scripts = this.props.graphJson?.PageScriptList?.filter(
        (script) => script.name === componentId
      ) || [];
      scripts.forEach(async (script) => {
        await deleteBehaviour({
          componentName: script.name,
          projectName: this.props.match.params.id,
          businessFunctionName: this.props.match.params.ProcessId,
          pageId: script.uiPageId,
        });
      });
    }
  };

  moreActionsOnElements = async (action) => {
    switch (action) {
      case 'copy':
        this.setState({
          clipboard: _deepCloneJSON(this.state.selectedComponent),
          isActions: false,
        });
        break;
      case 'paste':
        this.pasteElement();
        break;
      case 'delete':
        // if(this.state.openConfirmDelete){
        const { id, dropzoneID, parentID, propertyValue } =
          this.state.selectedComponent;
        try {
          await this.setState({ addAction: true });

          //Hook method to delete the component's script
          this._detachBehavious();
          this.handleComponentPropertyWithInteraction(id, propertyValue?.component, true);
          state.removeElement(id, dropzoneID, parentID, () => {
            this.setState({
              isActions: false,
              activeBreadCrumb: [],
            });
            this.refreshPageContent();
          });
        } catch(e) {
          console.error(e);
          this.setState({ isActions: false });
        }
        // }else{
        //   this.setState({
        //     openConfirmDelete: true
        //   })
        // }
        break;
      default:
        break;
    }
  };

  handleCloseConfirmDelete = () => {
    this.setState({
      openConfirmDelete: false,
    });
  };

  pasteElement = () => {
    if (this.state.clipboard) {
      let { id,dropzoneID, parentID, type, propertyValue } = _deepCloneJSON(
        this.state.clipboard
      );
      

      let maxIdMap={};
      let idocument = document.getElementById('iframe').contentDocument;
      let new_id = Math.floor(Date.now() / 1000) + '';
      let uniquekey = 1;
      if (idocument.getElementsByClassName(type)) {
        if(!maxIdMap[type]){
          maxIdMap[type] = getMaxIDByType(idocument, type);
        }else{
          maxIdMap[type]++;
        }
        uniquekey = maxIdMap[type];
      }

      var el = state.traverseAndReturnElement(id, dropzoneID, parentID);
      el = _deepCloneJSON(el);
      let oldCompID=`${el.type.toLowerCase()}${el.uniquekey}`;
      let sparentID=this.state.selectedComponent.parentID;
      let sdropzoneID =this.state.selectedComponent.dropzoneID;
      // console.log("data: original", _deepCloneJSON(el));

      // initialElements array property is not there while copying grid and its children, so we need to clone fields instead
      // if ((!el.initialElements || el.initialElements.length === 0) && (el.fields && el.fields.length > 0)){
      if (el.fields) {
        el.initialElements = el.fields;
      }
      el.parentID=sparentID;
      el.dropzoneID = sdropzoneID;
      var copy_initialElements = undefined;
      if (el && el.initialElements && el.initialElements.length > 0) {
        copy_initialElements = el.initialElements || [];
        const assignInitialElements = (list, parentID) => {
          list.forEach((e) => {
            let oldId = e.id;
            e.id = e.id + Math.random().toString(36).slice(-6);

            // e.dropzoneID = e.dropzoneID.substring(0, e.dropzoneID.length - 10) + parentID;
            e.dropzoneID = e.dropzoneID.replace(e.parentID, parentID);
            if(!maxIdMap[ e.type]){
              maxIdMap[ e.type]=getMaxIDByType(idocument, e.type);
            }else{
              maxIdMap[ e.type]++;
            }
            e.parentID = parentID;
            let oldCompChID = `${e.type.toLowerCase()}${e.uniquekey}`
            e.uniquekey = maxIdMap[e.type];
            e.propertyValue.component.id = `${e.type.toLowerCase()}_${
              e.uniquekey
            }`;

            // initialElements array property is not there while copying grid and its children, so we need to clone fields instead
            // if ((!e.initialElements || e.initialElements.length === 0) && (e.fields && e.fields.length > 0)) {
            if (e.fields) {
              e.initialElements = e.fields;
            }

            if (e && e.initialElements && e.initialElements.length > 0) {
              // assignInitialElements([...e.initialElements],e.dropzoneID);

              // Copying and updating property values key
              for (var key in e.propertyValue) {
                if (key.indexOf(oldId) > -1) {
                  let propertValueKey = key.replace(oldId, e.id);
                  e.propertyValue[propertValueKey] = _deepCloneJSON(
                    e.propertyValue[key]
                  );
                  let compid=e.propertyValue[propertValueKey].component.id;
                  e.propertyValue[propertValueKey].component.id = compid.replace(oldCompChID,`${e.type.toLowerCase()}${e.uniquekey}`);
                  delete e.propertyValue[key];
                }
              }

              assignInitialElements([...e.initialElements], e.id);
            }
          });
        };

        assignInitialElements(copy_initialElements, new_id);
       
      }
      
      // Copying and updating property values key
      for (var key in propertyValue) {
        if (key.indexOf(id) > -1) {
          let propertValueKey = key.replace(id, new_id);
          propertyValue[propertValueKey] = _deepCloneJSON(propertyValue[key]);
          let compid=propertyValue[propertValueKey].component.id;
          propertyValue[propertValueKey].component.id = compid.replace(oldCompID,`${type.toLowerCase()}${uniquekey}`);
          delete propertyValue[key];
        }
      }

      let data = {
        // ...this.state.clipboard,
        ...el,
        id: new_id,
        payload: { dropped: true },
        propertyValue: {
          ...propertyValue,
          component: {
            ...propertyValue.component,
            id: `${type.toLowerCase()}_${uniquekey}`,
          },
        },
        initialElements: copy_initialElements,
        uniquekey: uniquekey,
      };

      // console.log("data: copied", _deepCloneJSON(data));

      //delete data.style;
      this.setState(
        {
          isActions: false,
          addAction: true,
        },
        () => {
          let { id, dropzoneID, parentID } = this.state.selectedComponent;
          state.addElement(id, dropzoneID, parentID, data);
        }
      );
    }
  };

  handleGraphJsonUndo = () => {
    setTimeout(() => {
      let presentState = this.props.presentState;
      let graphJson = presentState && presentState.length ? presentState[presentState.length - 1]?.graphJson : null;
      if(graphJson){
        this.updateGraphJSON(graphJson);
        updateUIJson(graphJson);
      }
    }, 0);
  }

  updateUndoRedoData = () => {
    setTimeout(() => {
      let data  = { data: { page: { 
        content: this.state.currentState, 
        propertyValue: this.state.propertyValue 
      } } };
      this.pageStateUpdate(data);
      this.handleGraphJsonUndo();
    }, 1000);
  }

  undoActions = () => {
    if (this.props.canUndo) {
      this.props.onUndo();
      state.clearState(() => {
        this.setState({ shouldCanvasRefreshed: true });
      });
      this.updateUndoRedoData();
    }
  };

  redoActions = () => {
    if (this.props.canRedo) {
      this.props.onRedo();
      state.clearState(() => {
        this.setState({ shouldCanvasRefreshed: true });
      });
      this.updateUndoRedoData();
    }
  };

  updateJsComponent = (blocks, isComponentIdUpdate, openBehavior) => {
    if (this.pageViewPolicy.allowUpdate()) {
      params.pageName = this.props.match.params?.PageId;
      params.projectName = this.props.match.params?.id;
      params.businessFunctionName = this.props.match.params?.ProcessId;
      params.pageId = this.props.pageDetail;
      updateJsBuilderComponent(
        blocks,
        params,
        this.state.selectedComponent,
        isComponentIdUpdate ?? false,
        openBehavior
      );
    }
  };

  fetchJsBuilderComponent = async (pageId, componentName) => {
    params = {
      projectName: currentProjectName,
      businessFunctionName: this.props.match.params?.ProcessId,
      pageId: pageId ? pageId : this.props.pageDetail,
    };

    const getData = await fetchJsBuilderComponent(
      params,
      this.state.selectedComponent,
      componentName
    );
    const responeData = {
      xmlData: getData.xmlData,
      componentData: getData.componentData,
    };
    this.props.loadXmlData(responeData);
    if (getData.xmlData || getData.componentData?.id) {
      this.setState({
        fetchJsBuilderData: true,
      });
    } else {
      this.setState({
        fetchJsBuilderData: false,
      });
      // this.createBehaviour();
    }

    return getData;
  };

  createBehaviour = (xmlCode, jsCode) => {
    const data = {
      name: this.props.match.params?.PageId,
      projectName: currentProjectName,
      uiObject: xmlCode,
      businessFunctionName: this.props.match.params?.ProcessId,
      dataObject: jsCode,
      uiPageId: this.props.pageDetail,
      pageName: this.state.pageName,
      taskName: this.props.match.params?.TaskId,
    };

    createBehaviour(data, this.state.selectedComponent, this.props.taskJSON);
  };

  highlightDComponents = (
    id,
    index = 0,
    fromBreadCrumb = false,
    scroll = true
  ) => {
    try {
      let idocument = document.getElementById('iframe').contentDocument;
      var option = {
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      };
      let elem = idocument.querySelector(`[dataid*="${id}_parent"]`);
      if (elem && !elem.length) {
        elem.click();
        if (scroll) elem.scrollIntoView(option);
      }
      const clickElement = (Id) => {
        idocument.getElementById(Id).click();
        if (scroll) idocument.getElementById(Id).scrollIntoView(option);
      };
      if (!isNaN(id) && idocument.getElementById(`${id}_parent`)) {
        clickElement(`${id}_parent`);
      } else if (isNaN(id)) {
        if (idocument.getElementById(id)) {
          clickElement(id);
        } else if (idocument.getElementById(`${id}_parent`)) {
          clickElement(`${id}_parent`);
        }
      } else if (id === null) {
        // Trigger page click
        idocument.getElementById(`__pillirPageContent`).click();
      }
      if (fromBreadCrumb) {
        this.setState({
          activeBreadCrumb: this.state.activeBreadCrumb.slice(0, index + 1),
        });
      }
    } catch {}
  };

  updateLinkTo = (screenId, fromMoreActions = false) => {
    // let data = JSON.parse(JSON.stringify(this.props.taskVariables));
    // let isEndDefault = data.data?.end ? data.data?.end?.objectId === screenId : false;
    // if(!data.data) return;
    // data.data.screens.every((screen) => {
    //   if (screen.name === this.props.match.params?.PageId) {
    //     let isUpdated = false;
    //     screen.actions.every((x, idx) => {
    //       if (x?.componentId === this.state.selectedComponent?.id) {
    //         isUpdated = true;
    //         x.goto = screenId;
    //         return false;
    //       }
    //       return true;
    //     });
    //     if (!isUpdated) {
    //       screen.actions.push({
    //         type: isEndDefault ? 'EndDefault' : 'screens',
    //         goto: screenId,
    //         componentId: this.state.selectedComponent?.id,
    //         componentType: this.state.selectedComponent?.type,
    //         id: this.state.selectedComponent?.id,
    //         component: this.state.selectedComponent?.label,
    //         name: isEndDefault ? '' : 'onClick',
    //       });
    //     }
    //     return false;
    //   }
    //   return true;
    // });
    if (fromMoreActions) {
      let { id, dropzoneID, parentID, propertyValue } =
        this.state.selectedComponent;
      propertyValue['component']['link'] = screenId;
      this.setState({ addAction: true }, () => {
        state.updateElement(id, dropzoneID, parentID, { propertyValue });
      });
    }
    // updateTaskDetail(this.props.match.params?.id, this.props.match.params?.ProcessId, this.props.match.params?.TaskId, data.data);
    // this.props.loadTaskVariable(data);
  };

  searchBlocks = (searchText) => {
    searchBlocks(searchText);
  };

  handleVariableType = (status = '') => {
    variableType = status;
  }

  saveExposedVariable(varObj, isEdit, oldObj, callback = () => null) {
    let project = this.props.match.params.id;
    let businessFunction = this.props.match.params.ProcessId;
    // let varList = this.props.varList;
    // if (!varList) varList = [];
    // varList.variable.push(varObj);

    saveVariables(
      varObj,
      project,
      businessFunction,
      'exposedVar',
      variableType,
      callback
    );
    this.handleVariableType('');
  }

  autoSaveVariable = (oldName, NewName) => {
    let project = this.props.match.params.id;
    let businessFunction = this.props.match.params.ProcessId;
    var variables = this.props.varList;
    let index = variables.variable.findIndex((el) => el.name === oldName);
    if (index != -1) {
      var obj = variables.variable[index];
      obj.name = NewName;
      variables[index] = obj;
      saveVariables(
        variables.variable,
        project,
        businessFunction,
        'exposedVar'
      );
    }
  };

  isValidVariable = (propName, varName) => {
    // return true;

    let isValid = false;
    let varType = "";
    if(varName?.startsWith("$WORKITEM")){
      varType = "String";
    }else {
      varType = getVarType(varName, this.props.varList.variable);
    }
    // console.log("isValidVariable: propName, varName, varType________________", propName, varName, varType);

    if (varType) {
      if (propName.startsWith('datasource_')) {
        const [_, sourcePart] = propName.split('_');
        const source = JSON.parse(sourcePart);
        const { src, label, index, isArray } = source;

        isValid = varType !== 'Table';
        if (isArray && src === label) {
          isValid = varType === 'Table';
        }
      } else {
        isValid = varType !== 'Table';
      }
    }
    return isValid;
  };
  
  getCompatibleVarType = (propName) => {
    if (propName.startsWith('datasource_')) {
      const [_, sourcePart] = propName.split('_');
      const source = JSON.parse(sourcePart);
      const { src, label, index, isArray } = source;

      if (isArray && src === label) {
        return 'Table';
      }else {
        return 'Value';
      }
    }else {
      return 'Value';
    }
  }
  _triggerHooksToUpdateAdditionalElements = (ds) => {
    this.hookMethodToUpdateDatagridTableCellDatasource(ds);
  }

  hookMethodToUpdateDatagridTableCellDatasource = (ds) => {
    //Need to forward the varaible mapping on table cell's text component from header cell' Data
    let { selectedComponent } = this.state
    let { propertyValue: pv } = selectedComponent;
    if (ds && selectedComponent.type === "datagrid_headercell" && selectedComponent?.cellId?.startsWith("table-heading-col-")) {
      var el = state.traverseAndReturnElement(selectedComponent.id, selectedComponent.dropzoneID, selectedComponent.parentID);
      let firstTextComponent;
      if (el && el.fields && el.fields.length>0) {
        let cellIndex = selectedComponent?.cellId.split("-")[3]
        firstTextComponent = el.fields.find((elem) => {
          return (elem.dropzoneID.startsWith(`table-body-col-0-${cellIndex}-`) && elem.type === "TEXT");
        })
       /* if (firstTextComponent && ds) {
          firstTextComponent.label = ds[0].Data.selfVal
        }*/
        const data = {
          ...firstTextComponent,
          label:ds[0].Data.selfVal,
        };

        const { id, dropzoneID, parentID, uniquekey, label, cellId = null, parentPropertyValue = null, options = [], propertyValue = {}, imageUrl = ''} = data;
        this.setState({ addAction: true },()=>{
          state.updateElement(
            id,
            dropzoneID,
            parentID,
            { label, uniquekey, options, propertyValue, imageUrl },
            () => {}
          );
        });
      }
    }
  }
  
  checkMenuPage = () =>{
    // const {PageId} = this.props.match.params
    // const {graph} = this.props.graphJson
    // if (PageId && graph && graph?.lanes){
    //   let found = false;
    //   graph.lanes.forEach(l => {
    //     if(!found){
    //       found = l.children.find(ch => (ch.uid === PageId && ch.type === SHAPE_TYPES.MENU))
    //     }
    //   })
    //   if (found){
    //     return true;
    //   }
    // }
    // return false;

    if (this.state.propertyValue?.component?.menuStyle) { // "menuStyle" property will be a part of menu json page only
      return true;
    }
    return false;

  }

  getMenuLinkOptions = () => {
    let options = []
    const { PageId } = this.props.match.params
    const { graph } = this.props.graphJson
    if (PageId && graph && graph?.lanes) {
      let found = false;
      graph.lanes.forEach(l => {
        if (!found) {
          found = l.children.find(ch => (ch.uid === PageId && ch.type === SHAPE_TYPES.MENU))
          if(found){
            options = l.children.map((ch)=>{
              return {
                ...ch,
                value : ch.name
              } 
            });
          }
        }
      })
    }
    return options;
  }

  updateAPMMenu = (linkData) =>{
    const { PageId } = this.props.match.params
    const { graphJson } = this.props
    let newGraphJson = JSON.parse(JSON.stringify(graphJson));
    delete newGraphJson.PageList
    delete newGraphJson.PageScriptList
    newGraphJson.graph.lanes.forEach((l) => {
      l.children.forEach((ch) => {
        if (ch.uid === PageId) {
          ch.data = JSON.stringify(linkData)
        }
      })
    })
    saveGraphJson(newGraphJson, this.props.match.params?.id, this.props.match.params?.ProcessId);
  }
  getEdgeObjectFromConnectors =(connectors, uuid)=>{
    return connectors.find(e=>e.uid===uuid);
  }
  getPageObjectFromGraph = (lanes, connectors, uuid) => {
    let page = null;
    if(connectors.length>0){
      page=this.getEdgeObjectFromConnectors(connectors,uuid);
      if(page){
        return page;
      }
    }
    loop1: for (let i = 0; i < lanes.length; i++) {
      page = lanes[i].children.find((d) => d.uid === uuid);
      if (!page) {
          let groups = lanes[i].children.filter((e) => e.type === 'Task');
          for (let j = 0; j < groups.length; j++) {
            if (groups[j].childTask) {
                page = groups[j].childTask.find((p) => p.uid === uuid);
            }
            if (page) {
                break loop1;
            }
          }
      } else {
          break loop1;
      }
    }
    return page;
  };

  updatePageObjectInLanes = (lanes,connectors, pageObj) => {
      let obj={};
      obj.lanes=lanes.map((lane) => {
        lane.children.map((ch) => {
            if (ch.uid === pageObj.uid) {
              return pageObj;
            }
            if (ch.type === 'Task') {
              if (ch.childTask) {
                ch.childTask.map((task) => {
                  if (task.uid === pageObj.uid) {
                      return pageObj;
                  }
                  return task;
                });
              }
              return ch;
            }
        });
        return lane;
      });
      obj.connectors=connectors.map(
        (ch) => {
          if (ch.uid === pageObj.uid) {
            return pageObj;
          }else{
            return ch;
          }
        }
      );
      return obj;
  };

  isAlreadyExist = (transitions = [], obj = {}) => {
    let flag = false;
    transitions.forEach(e => {
      if(e.id === obj.id && obj.type === e.type && e.cellId==obj.cellId){
        flag= true;
      }
    })
    return flag;
  }
  updateInteraction = (edgeId = "", oldObj = {}, payl = {}, lanes, connectors=[],isDelete = false) => {
    let edgeObj = this.getPageObjectFromGraph(lanes, connectors, edgeId);
    if(edgeObj) {
      let transitions = edgeObj['transitions'] || [];
      if(transitions.length > 0 && this.isAlreadyExist(transitions, oldObj)){
          if(isDelete){
            transitions = transitions?.filter(e => !(e.id === oldObj.id && e.type === oldObj.type && e.cellId===oldObj.cellId));
          }else{
            transitions = transitions?.map(e => { 
              return e.id === oldObj.id && e.type  === oldObj.type && e.cellId===oldObj.cellId? payl : e;
            });
          }
      }else {
        transitions.push(payl);
      }
      edgeObj['transitions'] = transitions;
      if(!transitions.length){
        edgeObj.name = '';
        if(!edgeObj.style.includes("dashed=1;")){
          edgeObj.style = edgeObj.style + ";dashed=1;";
        }
      }else if(transitions.length > 0){
        if(edgeObj.style.includes("dashed=1;")){
          edgeObj.style = edgeObj.style.replaceAll("dashed=1;", '');
        }
      }
      return this.updatePageObjectInLanes(lanes,connectors, edgeObj);

    }
     return {lanes:lanes,connectors:connectors};
  }

  updateComponentActionWithInteraction = (
    lanses = [],
    connectors= [],
    compUId = "",
    newProp = {}, 
    isDelete = false
  ) => {
    let pageObj= this.getPageObjectFromGraph(lanses, [], this.props.match.params.PageId);
    const updateTransition = (edge = {}) => {
      if(edge.start===pageObj.id && edge.transitions?.length > 0){
        if(isDelete){
          edge.transitions = edge.transitions.filter(e => compUId !== e.id);
          if(!edge.transitions.length && !edge.style.includes("dashed=1;")){
            edge.style += ((edge.style.endsWith(";") ? '' : ';') + "dashed=1;");
          }else if(edge.transitions.length > 0 && edge.style.includes("dashed=1;")){
            edge.style = edge.style.replaceAll("dashed=1;", '');
          }
        }else {
          edge.transitions = edge.transitions.map(e => {
            if(e.id === compUId && e.name===newProp?.oldName){ e.name = newProp?.id; }
            return e;
          })
        }
      }
      return edge;
    };
    let obj={};
    obj.lanes=lanses.map((lane) => {
      lane.children.map((ch) => {
          if (ch.type === SHAPE_TYPES.CONNECTOR) {
            return updateTransition(ch);
          }
          if (ch.type === 'Task') {
            if (ch.childTask) {
              ch.childTask.map((task) => {
                if (task.type === SHAPE_TYPES.CONNECTOR) {
                    return updateTransition(task);
                }
                return task;
              });
            }
            return ch;
          }
      });
      return lane;
    });
    obj.connectors=connectors.map(edge=>{
      return updateTransition(edge);
    })
    return obj;
  }
  
  handleComponentPropertyWithInteraction = (compUId = "", newProp = {}, isDelete = false) => {
    let graphJson = this.props.graphJson;
    let lanes = graphJson.graph.lanes;
    let connectors=this.props.graphJson.graph.connectors;
    let obj={lanes:lanes,connectors:connectors};
    obj = this.updateComponentActionWithInteraction(lanes,connectors, compUId, newProp, isDelete);
    graphJson.graph.lanes = obj.lanes;
    graphJson.graph.connectors =obj.connectors;
    graphJson=_deepCloneJSON(graphJson);
    this.updateGraphJSON(graphJson);
  }

  handeleUpdateInteractions = (id = "", oldObj = {}, payload = {}, canDelete = false, replaceId = "")=>{
      let lanes = this.props.graphJson.graph.lanes;
      let connectors=this.props.graphJson.graph.connectors;
      let obj={lanes:lanes,connectors:connectors};
      if(id !== '-1' && id !== '-2' && id){
        obj = this.updateInteraction(id, oldObj, payload, lanes, connectors,canDelete);
      }
      if(replaceId && replaceId !== '-1' && replaceId !== '-2' ){
        obj = this.updateInteraction(replaceId, oldObj, payload, lanes, connectors,true);
      }
      if(obj){
        let graphJson = this.props.graphJson;
        graphJson.graph.lanes = obj.lanes;
        graphJson.graph.connectors = obj.connectors;
        graphJson=_deepCloneJSON(graphJson);
        this.updateGraphJSON(graphJson);
      }
      
  }

  updateGraphJSON = (json) => {
      const { id, ProcessId } = this.props.match.params;
      delete json.PageList;
      delete json.PageScriptList;
      saveGraphJson(json, id, ProcessId);
  };

  render() {
    return (
      <>
        <AppDesignerLayout
          navigateTab={this.navigateTab}
          onDrop={this._onDrop}
          initialElements={this.state.currentState}
          onSelect={this._onSelect}
          selectedComponent={this.state.selectedComponent}
          setState={(data) => this.setState(data)}
          moreActionsOnElements={(action) => this.moreActionsOnElements(action)}
          undoActions={this.undoActions}
          redoActions={this.redoActions}
          getComputedStyle={getComputedStyle}
          updateJsComponent={this.updateJsComponent}
          fetchJsBuilderComponent={this.fetchJsBuilderComponent}
          highlightDComponents={this.highlightDComponents}
          pageName={this.state.pageName}
          taskVariables={this.props.taskVariables}
          updateLinkTo={this.updateLinkTo}
          createBehaviour={this.createBehaviour}
          getPageSnapshot={this.getPageSnapshot}
          saveExposedVariable={this.saveExposedVariable}
          propertyValue={this.state.propertyValue}
          updateComponent={this.updateComponent}
          triggerHooks={this._triggerHooksToUpdateAdditionalElements}
          isValidVariable={this.isValidVariable}
          getCompatibleVarType={this.getCompatibleVarType}
          handleSkipAction={this.handleSkipAction}
          handleVariableType={this.handleVariableType}
          {...this.state}
          {...this.props}
          refreshPageState={this.refreshPageState}
          openBehavior={
            this.props.history.location?.state?.openBehavior ?? 'false'
          }
          behaviourData={this.props.history.location?.state?.behaviourData}
          projectName={this.props.match.params?.id}
          setBackgroundImage={this.setBackgroundImage}
          jsBuilderservices={this.props.taskVariables}
          searchBlocks={this.searchBlocks}
          clearPageState={this.props.clearPageState}
          _getPageProperties={this._getPageProperties}
          handleCloseConfirmDelete={this.handleCloseConfirmDelete}
          handeleExposedNav={this.handeleExposedNav}
          isDSMappingAllowed={this._isDSMappingAllowed}
          handeleUpdateInteractions={this.handeleUpdateInteractions}
          getParentElementPropertyValue={this._getParentElementPropertyValue}
          isMenuPage={this.checkMenuPage()}
          getMenuLinkOptions={this.getMenuLinkOptions}
          updateAPMMenu={this.updateAPMMenu}
          toggleScreenMenu={this.toggleScreenMenu}
        />
        {this.state.showChangeImageDialog && (
          <ChangeImageDialog
            setState={(data) => this.setState(data)}
            showChangeImageDialog={this.state.showChangeImageDialog}
            _onSelect={this._onSelect}
            selectedComponent={this.state.selectedComponent}
            projectName={this.props.match.params?.id}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    canUndo:
      state.appDesigner.past.length > 0 && state.appDesigner.future.length < 10,
    canRedo: state.appDesigner.future.length > 0,
    presentState: state.appDesigner.present,
    pastState: state.appDesigner.past,
    future: state.appDesigner.future,
    componentData: state.jsBuilder.componentData,
    xmlData: state.jsBuilder.xmlData,
    pageDetail:
      state.jsBuilder.pageDetail?.uuid || state.jsBuilder.pageDetail.detail?.id,
    taskVariables: state.userTaskDetails.taskVariables,
    apiMsg: state.userTaskDetails.apiMsg,
    details: state.businessFunction.details,
    taskJSON: state.userTaskDetails.taskJSON,
    leftPanel: state.jsBuilder.leftPanel,
    graphJson: state.businessFunction.graphJson,
    varList: state.businessFunction.varList,
    userPropertyList: state.settings.userPropertyList,
    globalVariable: state.globalVariable.globalVariables,
    projVariable: state.projectSettings.projectVariables,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUndo: () => dispatch(UndoActionCreators.undo()),
    onRedo: () => dispatch(UndoActionCreators.redo()),
    addActions: (data, variables, graphJson) => dispatch(addActions(data, variables, _deepCloneJSON(graphJson))),
    clearPageState: () => dispatch(clearPageState()),
    clearApiMsg: () => dispatch(loadApiMessage({})),
    loadTaskVariable: (data) => dispatch(loadTaskVariable(data)),
    loadXmlData: (data) => dispatch(fetchComponent(data)),
    clearHistory: () => dispatch(UndoActionCreators.clearHistory()),
    addNonUndoableAction: (data) => dispatch(addNonUndoableAction(data))
  };
};

App = NetworkDetectorHOC(App);
App = connect(mapStateToProps, mapDispatchToProps)(App);

export default withStyles(styles, { withTheme: true })(App);
