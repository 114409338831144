import React, { useState, useRef } from 'react';
import { Tooltip, Typography, ListItemIcon, IconButton } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import {
    Draggable,
    Dropzone,
    state
} from '../../../../libraries/react-page-maker';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import { contextMenu as property } from './property';
import ContextMenuPreview from './preview/ContextMenu';
import constants from '../../const';
import { getComputedStyle, getStyle } from '../../utils/style';
import { GROUP_COMPONENT_NAMES } from '../../utils/configuration/componentName.contants';
import getIcon from '../../utils/configuration/icons';
import { gContextMenuSchema } from './schema';



import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { COMPONENT_OPTIONS_KEY } from '../../../../constants';


const defaultOption = [
    { label: 'Favorite', icon: 'FavoriteBorder' },
    { label: 'List', icon: 'FeaturedPlayList' },
    { label: 'Note', icon: 'MusicNote' },
];

const ContextMenuItem = React.forwardRef((props, navbarRef) => {
    //console.log('props description',props);
    const propertyValue = props.propertyValue;
    const elementLable = propertyValue?.component?.placeholder;
    const [label, setLabel] = useState(
        propertyValue?.component?.placeholder ??
        defaultOption[props.i - 1]?.label ??
        'Label'
    );
    const [isEdit, setEdit] = useState(false);
    const onChange = async (value) => {
        await setLabel(value);
        await setEdit(true);
    };
    React.useEffect(() => {
        if (navbarRef && navbarRef.current && !props.propertyValue.style) {
            const { value } = getComputedStyle({
                computedStyle: window.getComputedStyle(navbarRef.current, null),
                componentName: props.j,
                componentId: props.i,
                gComponentName: GROUP_COMPONENT_NAMES.CONTEXT_MENU,
            });
            var val = value;
            if (defaultOption[props.i - 1]?.icon) {
                val.component.iconName = defaultOption[props.i - 1].icon;
                val.component.placeholder = defaultOption[props.i - 1].label;
            }
            props.updateCellPropertyValue(props.id, val, true);
        }
    }, [navbarRef]);

    const updatelabel = async () => {
        if (isEdit) {
            if (label !== elementLable) {
                await setEdit(false);
                var newPropsvalue = propertyValue;
                newPropsvalue.component.placeholder = label;
                props.updateCellPropertyValue(props.id, newPropsvalue);
                //await setState({ addAction: true });
                //await setEdit(false);
                // onSelect({ id, dropzoneID, uniquekey, propertyValue, parentID, type, label: label, property }, false);
            }
        }
    };
    return (
        <>
            {/* <Tooltip  arrow
            title = {propertyValue?.tooltip?.title || ""}
            placement = {propertyValue?.tooltip?.position || ""}
            PopperProps={{ disablePortal: true }}
          > */}
            <MenuItem
                ref={navbarRef}
                id={props.propertyValue?.component?.id}
                className={`${props.propertyValue?.component?.classes || ''}`}
                dataId={props.id}
                onClick={(e) => {
                    e.stopPropagation();
                    props.onCellClick({ ...props, gComponentName: GROUP_COMPONENT_NAMES.BOTTOM_BAR_ITEM }, navbarRef);
                }}
                style={{
                    border: props.triggerAction.highlightGrid
                        ? '2px solid #97C1FF'
                        : '2px solid transparent',
                    color: 'rgba(0, 0, 0, 0.87)',
                    ...getStyle(props.propertyValue, ['text', 'layout', 'style']),
                }}
            >
               { propertyValue.component.iconPosition && (
                <ListItemIcon>
                    {getIcon(propertyValue?.component?.iconName, null, { fontSize: "small" })}
                </ListItemIcon>)}
                <Typography variant="inherit" >
                    <span
                        onDoubleClick={async (e) => {
                            e.persist();
                            const event = e;
                            await setEdit(true);
                            event.target.focus();
                        }}
                        contentEditable={isEdit ? 'true' : 'false'}
                        onBlur={(e) => updatelabel()}
                        onInput={(e) => onChange(e.currentTarget.innerText)}
                    >
                        {elementLable}
                    </span>
                </Typography>
            </MenuItem>
            {props.triggerAction.highlightGrid && (
                <MoreActionButton
                    type={'Item'}
                    showParentSelection={true}
                    showActionButton={false}
                    selectParent={(e) => {
                        e.stopPropagation();
                        props.triggerAction.selectParent();
                    }}
                    anchorElement={navbarRef}
                />
            )}
        </>
        // </Tooltip>
    );
});

const DraggableContextMenu = (props) => {
    const { id, uniquekey, showBasicContent, showPreview, dropzoneID, parentID, onSelect, type, iconComponent, setState, propertyValue, idocument, dropzoneProps, onDropZone, initialElements, fields, options = [1, 2, 3, 4], selectedComponent, ...rest } = props;
    const [label, setLabel] = useState(props.label);
    const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
    const [isHover, setHover] = useState(false);
    const dialogRef = useRef();
    const dialog1Ref = useRef();


    const handleCloseMenu = (event, reason) => {
        //event && event.stopPropagation();
    };


    if (showBasicContent) {
        let data = { ...props };
        data.propertyValue = defaultPropertyValue;
        data.options = [
            {[COMPONENT_OPTIONS_KEY]:0,label: '',value: '',checked: false},
            {[COMPONENT_OPTIONS_KEY]:1,label: '',value: '',checked: false},
          ];
        return (
            <Draggable {...data}>
                {iconComponent}
                <ContextMenuPreview setDefaultPropertyValue={setDefaultPropertyValue} />
            </Draggable>
        );
    }

    if (showPreview) {
        return (
            <IconButton
                aria-label="more"
                id={propertyValue.component.id}
                style={{color:'inherit',...getStyle(propertyValue, ['text', 'style', 'layout']),background:'transparent' }}
                aria-controls="fade-menu" aria-haspopup="true"
            >
                <MoreVert className="icon" style={{ ...getStyle(propertyValue, ['text', 'style']),background:'transparent' }} />
            </IconButton>
        );
    }

    const onClick = (e) => {
        e && e.stopPropagation();
        onSelect({ id, dropzoneID, parentID, uniquekey, type, label, property, propertyValue, options, }, false, e);
    };
    const handleActions = (e) => {
        e.stopPropagation();
        let position = { x: e.clientX, y: e.clientY };
        onSelect({ id, dropzoneID, parentID, uniquekey, type, label, property, propertyValue, isActions: true, position, options, }, false, e);
    };
    const updateCellPropertyValue = async (cellId, newValue, initflag) => {
        if (!initflag) {
            var newPvalue = propertyValue;
            newPvalue[cellId] = newValue;
            await setState({ addAction: true });
            state.updateElement(
                id,
                dropzoneID,
                parentID,
                { propertyValue: newPvalue },
                () => {
                    onSelect(
                        {
                            id,
                            dropzoneID,
                            parentID,
                            cellId,
                            type,
                            label,
                            property,
                            parentPropertyValue: newPvalue,
                            propertyValue: newPvalue[cellId],
                        },
                        false
                    );
                }
            );
        } else {
            var newPvalue = propertyValue;
            newPvalue[cellId] = newValue;
            await setState({ addAction: true });
            state.updateElement(
                id,
                dropzoneID,
                parentID,
                { propertyValue: newPvalue },
                () => { }
            );
        }
    };
    const onCellClick = (target, ref) => {
        // console.warn('on cell click', ref);
        let cellId = target.id;
        let gComponentName = target.gComponentName;
        const property = {
            componentSection: [
                'ID',
                'Classes',
                'Label',
                'IconList',
                'IconPosition',
                'Link',
                'Enabled',
            ],
            gridSection: [],
            dataSource: [],
            textSection: ['Size', 'Alignment', 'Decoration'],
            styleSection: ['Background', 'Opacity', 'Custom CSS'],
            layoutSection: ['Layout', 'Width', 'Height'],
            tooltipSection: ['event', 'position', 'title'],
            interactionSection: gContextMenuSchema({}).events
        };
        let styles = { ...(propertyValue ? propertyValue : defaultPropertyValue) };
        onSelect(
            {
                id,
                dropzoneID,
                parentID,
                cellId,
                type,
                label,
                property,
                parentPropertyValue: styles,
                propertyValue: styles[cellId],
                gComponentName,
            },
            false
        );
    };
    const selectParent = () => {
        onClick(null);
    };
    const _getDotStyle=(opt=['text', 'style', 'layout'])=>{
        let s=getStyle(propertyValue, opt);
        s.backgroundColor ='transparent';
        s.background = 'transparent';
        return s;
    }

    return (
        <Draggable {...props} dataid={`${id}_parent`} onClick={onClick}>
            {(data) => (
                <Tooltip arrow
                    title={propertyValue?.tooltip?.title || ""}
                    placement={propertyValue?.tooltip?.position || ""}
                    PopperProps={{ disablePortal: true }}
                >
                        <IconButton
                            aria-label="more"
                            ref={dialogRef}
                            aria-controls={`contextMenu_${id}`} aria-haspopup="true"
                            onMouseOut={(e) => {
                                e.stopPropagation();
                                setHover(false);
                            }}
                            onMouseOver={(e) => {
                                e.stopPropagation();
                                setHover(true);
                            }}
                            id={propertyValue.component.id}
                            dropzoneID={dropzoneID}
                            compid={propertyValue.component.id}
                            className={`${type} ${isHover && 'draggable-hover'}  ${data.className}   ${selectedComponent?.id === id && 'highlight-component'} ${propertyValue.component.classes ?? ''}`}
                            dataid={`${id}_parent`}
                            properties={JSON.stringify({ id, dropzoneID, parentID, label, type })}
                            style={{ color:'inherit',..._getDotStyle(['text', 'style', 'layout'])}}
                        >
                            <MoreVert className="icon" style={{ ..._getDotStyle(['style'])}} />
                            {!selectedComponent?.cellId && selectedComponent?.id === id && (
                                <MoreActionButton
                                    handleActions={handleActions}
                                    type={'Menu'}
                                    showParentSelection={true}
                                    idocument={idocument}
                                    parentID={dropzoneID}
                                    selectParent={(e) => {
                                      e.stopPropagation();
                                    }}
                                    anchorElement={dialogRef}
                                />
                            )}
                            <Menu
                                id={`contextMenu_${id}`}
                                anchorEl={propertyValue?.component.hasOwnProperty("show") ? (propertyValue?.component?.show === "true") && dialogRef.current : null}
                                open={propertyValue?.component.hasOwnProperty("show") ? (propertyValue?.component?.show === "true") : false}
                                onClose={handleCloseMenu}
                                keepMounted
                                container={idocument.body}
                                ref={dialog1Ref}
                                PaperProps={{
                                    style:{ ...getStyle(propertyValue, ['text', 'style']),opacity:propertyValue?.component.hasOwnProperty("show") ? (propertyValue?.component?.show === "true") && 1 : 0 },
                                }}
                                getContentAnchorEl={null}
                                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                                transformOrigin={{vertical: 'top', horizontal: 'left'}}
                                style={{background:propertyValue?.component.hasOwnProperty("show") ? (propertyValue?.component?.show === "true") && '#00000047' : 'transparent'}}
                            >
                                {options.map((e, i) => (
                                    <Dropzone
                                        {...dropzoneProps}
                                        initialElements={[]}
                                        id={'menu-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id}
                                        onSelect={onSelect}
                                        key={`${id}-${ (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY])}`}
                                        i={ (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + 1}
                                        j={`menu${uniquekey}_item`}
                                        selectedComponent={selectedComponent}
                                        setState={setState}
                                        onCellClick={onCellClick}
                                        updateCellPropertyValue={updateCellPropertyValue}
                                        placeholder=' '
                                        component={ContextMenuItem}
                                        className={'menu-item-' +  (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id}
                                        type={`Menu Item ${(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + 1}`}
                                        dropzoneCellID={id}
                                        properties={JSON.stringify({
                                            id: 'menu-item-' +  (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id,
                                            dropzoneID: id,
                                            parentID: id,
                                            label: `Menu Item ${(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY])+ 1}`,
                                            type: 'Menu Item',
                                        })}
                                        triggerAction={{
                                            highlightGrid:
                                                'menu-item-' +  (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id === selectedComponent?.cellId,
                                            options,
                                            selectParent: selectParent,
                                        }}
                                        propertyValue={
                                            propertyValue && propertyValue['menu-item-' +  (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id]
                                                ? propertyValue['menu-item-' + (e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY]) + '-' + id]
                                                : {
                                                    component: { id: `menu${uniquekey}_item_${(e?.[COMPONENT_OPTIONS_KEY]==undefined?i:e[COMPONENT_OPTIONS_KEY])+ 1}` },
                                                }
                                        }
                                    />
                                ))}
                            </Menu>
                        </IconButton>

                </Tooltip>

            )}
        </Draggable>
    );
};

export default DraggableContextMenu;

