/**
 * @license
 *
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * @fileoverview Define generation methods for custom blocks.
 * @author samelh@google.com (Sam El-Husseini)
 */

// More on generating code:
// https://developers.google.com/blockly/guides/create-custom-blocks/generating-code
import { EVENT_ARGS } from './eventArgs';
import * as Blockly from 'blockly/core';
import 'blockly/javascript';

Blockly.JavaScript['test_react_field'] = function (block) {
  return "console.log('custom block');\n";
};

Blockly.JavaScript['test_react_date_field'] = function (block) {
  return 'console.log(' + block.getField('DATE').getText() + ');\n';
};

// flow custom block code
Blockly.JavaScript['time_set_timout'] = function (block) {
  var statements_function = Blockly.JavaScript.statementToCode(
    block,
    'function'
  );
  var value_time = Blockly.JavaScript.valueToCode(
    block,
    'time',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code;
  if (value_time != '') {
    code =
      'setTimeout(async function(){' +
      statements_function +
      '}, ' +
      value_time +
      ');\n';
  } else {
    code = 'setTimeout(async function(){' + statements_function + '});\n';
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return code;
};

Blockly.JavaScript['time_set_interval'] = function (block) {
  var statements_function = Blockly.JavaScript.statementToCode(
    block,
    'function'
  );
  var value_time = Blockly.JavaScript.valueToCode(
    block,
    'time',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = '';
  code =
    'setInterval(async function(){' +
    statements_function +
    '}, ' +
    value_time +
    ');\n';
  // TODO: Change ORDER_NONE to the correct strength.
  return code;
};

// flow custom block code
Blockly.JavaScript['ref_time_set_timout'] = function (block) {
  var statements_function = Blockly.JavaScript.statementToCode(
    block,
    'function'
  );
  var value_time = Blockly.JavaScript.valueToCode(
    block,
    'time',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code;
  if (value_time != '') {
    code =
      'setTimeout(async function(){' + statements_function + '}, ' + value_time + ')';
  } else {
    code = 'setTimeout(async function(){' + statements_function + '})';
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_MEMBER];
};

Blockly.JavaScript['ref_time_set_interval'] = function (block) {
  var statements_function = Blockly.JavaScript.statementToCode(
    block,
    'function'
  );
  var value_time = Blockly.JavaScript.valueToCode(
    block,
    'time',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = '';
  code =
    'setInterval(async function(){' +
    statements_function +
    '}, ' +
    value_time +
    ')';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_MEMBER];
};
Blockly.JavaScript['time_clear_interval'] = function (block) {
  var value_variable = Blockly.JavaScript.valueToCode(
    block,
    'variable',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = 'clearInterval(' + value_variable + ');\n';
  return code;
};

Blockly.JavaScript['time_clear_timeout'] = function (block) {
  var value_variable = Blockly.JavaScript.valueToCode(
    block,
    'variable',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = 'clearTimeout(' + value_variable + ');\n';
  return code;
};

//json custom block code
Blockly.JavaScript['json_parse'] = function (block) {
  var value_string = Blockly.JavaScript.valueToCode(
    block,
    'string',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = '__er.parseJSON(' + value_string + ')';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_MEMBER];
};

Blockly.JavaScript['json_stringify'] = function (block) {
  var value_string = Blockly.JavaScript.valueToCode(
    block,
    'string',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  value_string = removeBrace(value_string);
  var code = '__er.JSONStringify('+ value_string + ')';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_MEMBER];
};

//logic custom block code
Blockly.JavaScript['object_sort'] = function (block) {
  var dropdown_sort_type = block.getFieldValue('sort_type');
  var value_parameter = Blockly.JavaScript.valueToCode(
    block,
    'parameter',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  var value_object = Blockly.JavaScript.valueToCode(
    block,
    'object',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = '';
  //value_parameter = removeBrace(value_parameter);
  code =
    '__er.sortObject("' +
    dropdown_sort_type +
    '", ' +
    value_parameter +
    ', ' +
    value_object +
    ')';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['object_group_by'] = function (block) {
  var value_parameter = Blockly.JavaScript.valueToCode(
    block,
    'parameter',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  var value_object = Blockly.JavaScript.valueToCode(
    block,
    'object',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = '';
  code = '__er.groupBy(' + value_parameter + ', ' + value_object + ')';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};



Blockly.JavaScript['object_filter'] = function (block) {
  var variableVar = Blockly.JavaScript.variableDB_.getName(
    block.getFieldValue('VAR'),
    Blockly.Variables.NAME_TYPE
  );

  var statements_filter_conditions = Blockly.JavaScript.statementToCode(block, 'filter_conditions');
  var code = 'await __er.filterObjects("' + variableVar + '",' + '[' + statements_filter_conditions + ']' + ')';
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['object_isExist'] = function (block) {
  var variableVar = Blockly.JavaScript.variableDB_.getName(
    block.getFieldValue('VAR'),
    Blockly.Variables.NAME_TYPE
  );

  var statements_filter_conditions = Blockly.JavaScript.statementToCode(block, 'filter_conditions');
  var code = 'await __er.objectIsExist("' + variableVar + '",' + '[' + statements_filter_conditions + ']' + ')';
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};



Blockly.JavaScript['Filter_Condition'] = function (block) {
  var text_key = block.getFieldValue('key');
  var dropdown_operator = block.getFieldValue('operator');
  var value_value = Blockly.JavaScript.valueToCode(block, 'value', Blockly.JavaScript.ORDER_ATOMIC);

  if (dropdown_operator === "EQUAL") {
    dropdown_operator = "EQ";
  }

  var code = '{"name": "' + text_key + '", "condition": "' + dropdown_operator + '", "value": ' + value_value + ' , "op": "' + 'AND' + '", "value_2": ""},';
  return code;
};


Blockly.JavaScript['date_new'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'new Date()';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_MEMBER];
};

Blockly.JavaScript['date_new_date'] = function (block) {
  var value_date = Blockly.JavaScript.valueToCode(
    block,
    'date',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  var value_formate = Blockly.JavaScript.valueToCode(
    block,
    'formate',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = '__er.newDate(' + value_date + ', ' + value_formate + ')';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_MEMBER];
};

Blockly.JavaScript['date_get_value'] = function (block) {
  var dropdown_type = block.getFieldValue('type');
  var value_date = Blockly.JavaScript.valueToCode(
    block,
    'date',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = '';

  switch (dropdown_type) {
    case 'year':
      code = value_date + '.getFullYear()';
      break;
    case 'month':
      code = '('+value_date + '.getMonth())+1';
      break;
    case 'day':
      code = value_date + '.getDay()';
      break;
    case 'date':
      code = value_date + '.getDate()';
      break;
    case 'hours':
      code = value_date + '.getHours()';
      break;
    case 'time':
      code = value_date + '.getTime()';
      break;
    case 'minutes':
      code = value_date + '.getMinutes()';
      break;
    case 'seconds':
      code = value_date + '.getSeconds()';
      break;
    case 'milliseconds':
      code = value_date + '.getMilliseconds()';
      break;
  }
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['date_to_string'] = function (block) {
  var value_date = Blockly.JavaScript.valueToCode(
    block,
    'date',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  var value_formate = Blockly.JavaScript.valueToCode(
    block,
    'formate',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = '__er.dateToString(' + value_date + ', ' + value_formate + ')';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_MEMBER];
};

Blockly.JavaScript['get_session_variable'] = function (block) {
  var value_key = Blockly.JavaScript.valueToCode(
    block,
    'KEY',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = '__er.getSessionStorage(' + value_key + ')';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['remove_session_variable'] = function (block) {
  var value_key = Blockly.JavaScript.valueToCode(
    block,
    'KEY',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = '__er.removeSessionStorage(' + value_key + ');\n';
  return code;
};

Blockly.JavaScript['clear_all_session_variable'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = '__er.clearAllSessionStorage();\n';
  return code;
};

Blockly.JavaScript['local_storage_set'] = function (block) {
  var value_key = Blockly.JavaScript.valueToCode(
    block,
    'key',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  var value_value = Blockly.JavaScript.valueToCode(
    block,
    'value',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = '__er.setLocalStorage(' + value_key + ' , ' + value_value + ');\n';
  return code;
};

Blockly.JavaScript['local_storage_get'] = function (block) {
  var value_key = Blockly.JavaScript.valueToCode(
    block,
    'key',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = '__er.getLocalStorage(' + value_key + ')';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['local_storage_remove'] = function (block) {
  var value_key = Blockly.JavaScript.valueToCode(
    block,
    'key',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = '__er.removeLocalStorage(' + value_key + ');\n';
  return code;
};

Blockly.JavaScript['session_storage_set'] = function (block) {
  var value_key = Blockly.JavaScript.valueToCode(
    block,
    'key',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  var value_value = Blockly.JavaScript.valueToCode(
    block,
    'value',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code =
    '__er.setSessionStorage(' + value_key + ', ' + value_value + ');\n';
  return code;
};

// rest api call

Blockly.JavaScript['rest_api_call'] = function (block) {
  var dropdown_method = block.getFieldValue('method');
  var value_url =
  Blockly.JavaScript.valueToCode(
    block,
    'url',
    Blockly.JavaScript.ORDER_ATOMIC
  ) || '""';

  var value_headers =
  Blockly.JavaScript.valueToCode(
    block,
    'headers',
    Blockly.JavaScript.ORDER_ATOMIC
  ) || '""';

  var value_payload =
  Blockly.JavaScript.valueToCode(
    block,
    'payload',
    Blockly.JavaScript.ORDER_ATOMIC
  ) || '""';

  var value_return =
  Blockly.JavaScript.valueToCode(
    block,
    'return',
    Blockly.JavaScript.ORDER_ATOMIC
  ) || '""';

  // TODO: Assemble JavaScript into code variable.
  var code = '';
  value_return = removeBrace(value_return);
  code =
    '__er.restApiCall("' +
    dropdown_method +
    '", ' +
    value_url +
    ', ' +
    value_headers +
    ', ' +
    value_payload +
    ', ' +
    value_return +
    ');\n';

  return code;
  
}

// event custom code

Blockly.JavaScript['event_call_set'] = function (block) {
  var dropdown_call_on = block.getFieldValue('call_on');
  var value_object =
    Blockly.JavaScript.valueToCode(
      block,
      'object',
      Blockly.JavaScript.ORDER_ATOMIC
    ) || '""';
  var value_function =
    Blockly.JavaScript.valueToCode(
      block,
      'function',
      Blockly.JavaScript.ORDER_ATOMIC
    ) || '""';

  // TODO: Assemble JavaScript into code variable.
  var code = '';
  value_function = removeBrace(value_function);
  code =
    '__er.addEventListener("' +
    dropdown_call_on +
    '", ' +
    value_object +
    ', ' +
    value_function +
    ');\n';

  return code;
};

Blockly.JavaScript['event_unbind'] = function (block) {
  var dropdown_call_on = block.getFieldValue('call_on');
  var value_object =
    Blockly.JavaScript.valueToCode(
      block,
      'object',
      Blockly.JavaScript.ORDER_ATOMIC
    ) || '""';
  var value_function =
    Blockly.JavaScript.valueToCode(
      block,
      'function',
      Blockly.JavaScript.ORDER_ATOMIC
    ) || '""';
  // TODO: Assemble JavaScript into code variable.
  var code = '';
  value_function = removeBrace(value_function);
  code =
    '__er.unbindEvent("' +
    dropdown_call_on +
    '", ' +
    value_object +
    ', ' +
    value_function +
    ');\n';

  return code;
};

Blockly.JavaScript['event_trigger'] = function (block) {
  var dropdown_call_on = block.getFieldValue('call_on');
  var value_object =
    Blockly.JavaScript.valueToCode(
      block,
      'object',
      Blockly.JavaScript.ORDER_ATOMIC
    ) || '""';
  // TODO: Assemble JavaScript into code variable.
  var code = '';

  code =
    '__er.triggerEvent("' + dropdown_call_on + '", ' + value_object + ');\n';

  return code;
};

Blockly.JavaScript['event_stop'] = function (block) {
  var value_event =
    Blockly.JavaScript.valueToCode(
      block,
      'event',
      Blockly.JavaScript.ORDER_ATOMIC
    ) || '""';
  var code = '';

  code = '__er.stopEventPropagation(' + value_event + ');\n';

  return code;
};

Blockly.JavaScript['enable_loader'] = function (block) {
  var value_event =
    Blockly.JavaScript.valueToCode(
      block,
      'event',
      Blockly.JavaScript.ORDER_ATOMIC
    ) || '""';
  var code = '';

  code = '__er.enableLoader(' + value_event + ');\n';

  return code;
};

Blockly.JavaScript['play_audio'] = function (block) {
  var value_event =
    Blockly.JavaScript.valueToCode(
      block,
      'event',
      Blockly.JavaScript.ORDER_ATOMIC
    ) || '""';
  var code = '';

  code = '__er.playAudio(' + value_event + ');\n';

  return code;
};

Blockly.JavaScript['variable_function'] = function (block) {
  var value_input =
    Blockly.JavaScript.valueToCode(
      block,
      'input',
      Blockly.JavaScript.ORDER_ATOMIC
    ) || '""';
  var dropdown_type = block.getFieldValue('type');
  var value_function =
    Blockly.JavaScript.valueToCode(
      block,
      'function',
      Blockly.JavaScript.ORDER_ATOMIC
    ) || '""';
  var value_arg1 =
    Blockly.JavaScript.valueToCode(
      block,
      'arg1',
      Blockly.JavaScript.ORDER_ATOMIC
    ) || '""';
  // TODO: Assemble JavaScript into code variable.
  var code = '';

  code =
    '__er.variableFunction("' +
    dropdown_type +
    '", ' +
    value_input +
    ', ' +
    value_function +
    ', ' +
    value_arg1 +
    ')';

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['regular_expression'] = function (block) {
  var dropdown_type = block.getFieldValue('type');
  var value_expression = Blockly.JavaScript.valueToCode(
    block,
    'expression',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  var value_input = Blockly.JavaScript.valueToCode(
    block,
    'input',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = '';
  //value_expression = removeBrace(value_expression);

  code =
    '__er.regularExpression("' +
    dropdown_type +
    '", ' +
    removeBrace(value_expression) +
    ', ' +
    value_input +
    ')';

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.Blocks['object_create'] = {
  init: function () {
    this.appendDummyInput().appendField('Object');
    this.setOutput(true, 'object_create');
    this.setStyle('variable_block');
    this.variableCount = 1;
    this.updateShape_();
    this.setMutator(new Blockly.Mutator(['object_sub_variable']));
    this.setTooltip('Create an object using blocks');
    this.setHelpUrl('');
  },
  /**
   * Create XML to represent the number of variable inputs.
   * @return {Element} XML storage element.
   * @this {Blockly.Block}
   */
  mutationToDom: function () {
    var container = Blockly.utils.xml.createElement('mutation');
    container.setAttribute('variable', this.variableCount);
    return container;
  },

  /**
   * Parse XML to restore the else-if and else inputs.
   * @param {!Element} xmlElement XML storage element.
   * @this {Blockly.Block}
   */
  domToMutation: function (xmlElement) {
    this.variableCount = parseInt(xmlElement.getAttribute('variable'), 10) || 0;
    this.updateShape_();
  },

  /**
   * Populate the mutator's dialog with this block's components.
   * @param {!Blockly.Workspace} workspace Mutator's workspace.
   * @return {!Blockly.Block} Root block in mutator.
   * @this {Blockly.Block}
   */
  decompose: function (workspace) {
    var containerBlock = workspace.newBlock('object_sub_object');
    containerBlock.initSvg();
    var connection = containerBlock.getInput('object_variables').connection;
    for (var i = 0; i < this.variableCount; i++) {
      var variable = workspace.newBlock('object_sub_variable');
      variable.initSvg();
      connection.connect(variable.previousConnection);
      connection = variable.nextConnection;
    }
    return containerBlock;
  },

  /**
   * Reconfigure this block based on the mutator dialog's components.
   * @param {!Blockly.Block} containerBlock Root block in mutator.
   * @this {Blockly.Block}
   */
  compose: function (containerBlock) {
    var variableBlock = containerBlock.getInputTargetBlock('object_variables');
    var valueConnections = [];
    while (variableBlock) {
      valueConnections.push(variableBlock.valueConnection_);
      variableBlock =
        variableBlock.nextConnection &&
        variableBlock.nextConnection.targetBlock();
    }
    this.variableCount = valueConnections.length;
    this.updateShape_();
  },

  /**
   * Modify this block to have the correct number of inputs.
   * @this {Blockly.Block}
   * @private
   */
  updateShape_: function () {
    // Add new inputs.
    for (var i = 0; i < this.variableCount; i++) {
      if (!this.getInput('variable' + i)) {
        this.appendValueInput('variable' + i)
          .appendField(
            new Blockly.FieldTextInput('variable_' + i),
            'variable_name' + i
          )
          .setAlign(Blockly.ALIGN_RIGHT)
          .setCheck(null);
      }
    }
    // Remove deleted inputs.
    while (this.getInput('variable' + i)) {
      this.removeInput('variable' + i);
      i++;
    }
  },
};

Blockly.JavaScript['object_create'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var variableCount = block.variableCount;
  var code = '{';
  //var i = 1;
  var argumentValue;
  for (var i = 0; i < variableCount; i++) {
    if (i > 0) {
      code += ', ';
    }
    code +=
      block.getFieldValue('variable_name' + i) +
      ' : ' +
      Blockly.JavaScript.valueToCode(
        block,
        'variable' + i,
        Blockly.JavaScript.ORDER_ATOMIC
      );
  }
  code += '}';
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['object_get_value'] = function (block) {
  var value_object = Blockly.JavaScript.valueToCode(
    block,
    'object',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  var value_variable = Blockly.JavaScript.valueToCode(
    block,
    'variable',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = '';

  value_object = removeBrace(value_object);
  // value_variable = removeBrace(value_variable);

  var code = value_object + '[' + value_variable + ']';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_MEMBER];
};

Blockly.JavaScript['object_set_value'] = function (block) {
  var value_object = Blockly.JavaScript.valueToCode(
    block,
    'object',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  var value_variable = Blockly.JavaScript.valueToCode(
    block,
    'variable',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  var value_value = Blockly.JavaScript.valueToCode(
    block,
    'value',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = '';
  value_object = removeBrace(value_object);
  // value_variable = removeBrace(value_variable);
  // value_value = removeBrace(value_value);

  var code = value_object + '[' + value_variable + ']' + ' = ' + value_value + ';\n';

  // TODO: Change ORDER_NONE to the correct strength.
  return code;
};

Blockly.JavaScript['console_log'] = function (block) {
  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = 'console.log(' + value_name + ');\n';
  return code;
};

/**
 * Mixin for mutator functions in the 'variable_function_ext'
 * extension.
 * @mixin
 * @augments Blockly.Block
 * @package
 */

var maxin = {
  /**
   * Create XML to represent whether the 'arg1' should be present.
   * @return {Element} XML storage element.
   * @this {Blockly.Block}
   */
  mutationToDom: function () {
    var container = Blockly.utils.xml.createElement('mutation');
    var type = this.getFieldValue('type');
    if (
      type == 'replace' ||
      type == 'slice' ||
      type == 'substr' ||
      type == 'substring'
    ) {
      container.setAttribute('arg1Check', 'true');
      container.setAttribute('inputCheck', 'false');
      container.setAttribute('argCheck', 'false');
    } else if (type == 'fromCharCode') {
      container.setAttribute('inputCheck', 'true');
      container.setAttribute('argCheck', 'false');
      container.setAttribute('arg1Check', 'false');
    } else if (
      type == 'toLocaleLowerCase' ||
      type == 'toLocaleUpperCase' ||
      type == 'toLowerCase' ||
      type == 'toString' ||
      type == 'toUpperCase' ||
      type == 'trim' ||
      type == 'valueOf' ||
      type == 'toInt' ||
      type == 'toFloat'
    ) {
      container.setAttribute('argCheck', 'true');
      container.setAttribute('inputCheck', 'false');
      container.setAttribute('arg1Check', 'false');
    }
    return container;
  },
  /**
   * Parse XML to restore the 'arg1'.
   * @param {!Element} xmlElement XML storage element.
   * @this {Blockly.Block}
   */
  domToMutation: function (xmlElement) {
    var hasArgument;
    if (xmlElement.getAttribute('arg1Check') == 'true') {
      hasArgument = 'one';
    } else if (xmlElement.getAttribute('inputCheck') == 'true') {
      hasArgument = 'two';
    } else if (xmlElement.getAttribute('argCheck') == 'true') {
      hasArgument = 'three';
    }
    //let foo = xmlElement.getAttribute('arg1');
    this.updateShape_(hasArgument);
  },
  /**
   * Modify this block to have (or not have) an input for 'replace'.
   * @param {boolean} divisorInput True if this block has an argument.
   * @private
   * @this {Blockly.Block}
   */
  updateShape_: function (param) {
    // Add or remove a Value Input.
    var inputArg1 = this.getInput('arg1');
    var inputValue = this.getInput('input');
    var inputArg = this.getInput('function');
    if (param == 'one') {
      //console.log("one");
      if (inputValue) {
        this.removeInput('input');
      }
      if (inputArg) {
        this.removeInput('function');
      }
      if (inputArg1) {
        this.removeInput('arg1');
      }
      this.appendValueInput('input').setCheck(null).appendField('Input');
      this.appendValueInput('function').setCheck(null).appendField('Argument');
      this.appendValueInput('arg1').setCheck(null).appendField('Argument-2');
    } else if (param == 'two') {
      //console.log("two");
      if (inputValue) {
        this.removeInput('input');
      }
      if (inputArg) {
        this.removeInput('function');
      }
      if (inputArg1) {
        this.removeInput('arg1');
      }
      this.appendValueInput('function').setCheck(null).appendField('Argument');
    } else if (param == 'three') {
      //console.log("three");
      if (inputValue) {
        this.removeInput('input');
      }
      if (inputArg) {
        this.removeInput('function');
      }
      if (inputArg1) {
        this.removeInput('arg1');
      }
      this.appendValueInput('input').setCheck(null).appendField('Input');
    } else {
      if (inputValue) {
        this.removeInput('input');
      }
      if (inputArg) {
        this.removeInput('function');
      }
      if (inputArg1) {
        this.removeInput('arg1');
      }
      this.appendValueInput('input').setCheck(null).appendField('Input');
      this.appendValueInput('function').setCheck(null).appendField('Argument');
    }
  },
};

/**
 * 'variable_function_ext' maxinFunction  block that
 * can update the block shape (add/remove divisor input) based on whether
 * property.
 * @this {Blockly.Block}
 * @package
 */
var maxinFunction = function () {
  this.getField('type').setValidator(function (option) {
    var divisorInput = 'oth';
    if (
      option == 'replace' ||
      option == 'slice' ||
      option == 'substr' ||
      option == 'substring'
    ) {
      divisorInput = 'one';
    } else if (option == 'fromCharCode') {
      divisorInput = 'two';
    } else if (
      option == 'toLocaleLowerCase' ||
      option == 'toLocaleUpperCase' ||
      option == 'toLowerCase' ||
      option == 'toString' ||
      option == 'toUpperCase' ||
      option == 'trim' ||
      option == 'valueOf' ||
      option == 'toFloat' ||
      option == 'toInt'
    ) {
      divisorInput = 'three';
    }
    this.sourceBlock_.updateShape_(divisorInput);
  });
};

Blockly.Extensions.registerMutator(
  'variable_function_ext',
  maxin,
  maxinFunction
);

/**
 * Mixin for mutator functions in the 'element_set_properties_ext'
 * extension.
 * @mixin
 * @augments Blockly.Block
 * @package
 */

var maxinElemSetProp = {
  /**
   * Create XML to represent whether the 'arg1' should be present.
   * @return {Element} XML storage element.
   * @this {Blockly.Block}
   */
  mutationToDom: function () {
    var container = Blockly.utils.xml.createElement('mutation');
    var property = this.getFieldValue('property');
    if (property == 'attribute') {
      container.setAttribute('attrCheck', 'true');
    }
    return container;
  },
  /**
   * Parse XML to restore the 'arg1'.
   * @param {!Element} xmlElement XML storage element.
   * @this {Blockly.Block}
   */
  domToMutation: function (xmlElement) {
    var hasArgument;
    if (xmlElement.getAttribute('attrCheck') == 'true') {
      hasArgument = 'one';
    }
    this.updateShape_(hasArgument);
  },
  /**
   * Modify this block to have (or not have) an input for 'replace'.
   * @param {boolean} divisorInput True if this block has an argument.
   * @private
   * @this {Blockly.Block}
   */
  updateShape_: function (param) {
    // Add or remove a Value Input.
    var inputAttr = this.getInput('attr');
    if (inputAttr) {
      this.removeInput('attr');
    }

    if (param == 'one') {
      this.appendValueInput('attr').setCheck('String').appendField('Name');
      this.moveInputBefore('attr', 'value');
    }
  },
};

/**
 * 'element_set_properties_ext' maxinFunction  block that
 * can update the block shape (add/remove divisor input) based on whether
 * property.
 * @this {Blockly.Block}
 * @package
 */
var maxinElemSetPropHelperFun = function () {
  this.getField('property').setValidator(function (option) {
    var divisorInput = 'oth';
    if (option == 'attribute') {
      divisorInput = 'one';
    }
    this.sourceBlock_.updateShape_(divisorInput);
  });
};

Blockly.Extensions.registerMutator(
  'element_set_properties_ext',
  maxinElemSetProp,
  maxinElemSetPropHelperFun
);

// function custom block code

Blockly.Blocks['function_call_with_return'] = {
  /**
   * Block for creating a function with any number of arguments of any type.
   * @this {Blockly.Block}
   */
  init: function () {
    this.appendDummyInput()
      .appendField('Function Call')
      .appendField(new Blockly.FieldTextInput('Name'), 'function_name');
    this.setInputsInline(false);
    this.setOutput(true, 'function_call');
    this.setStyle('scope_no_connect_block');
    this.argumentCount = 1;
    this.updateShape_();
    this.setTooltip(
      'Function Call With non or Many Argument and has a return '
    );
    this.setMutator(new Blockly.Mutator(['function_argument_2']));
    this.setHelpUrl('');
  },

  /**
   * Create XML to represent the number of argument inputs.
   * @return {Element} XML storage element.
   * @this {Blockly.Block}
   */
  mutationToDom: function () {
    /** 
     *  Commenting "if" & "null" to capture removal of default argument by user. 
     *  (T3054133 - 454)
     **/ 
    // if (!this.argumentCount) {
    //   return null;
    // }

    var container = Blockly.utils.xml.createElement('mutation');
    // if (this.argumentCount) {
      container.setAttribute('argument', this.argumentCount);
    // }

    return container;
  },

  /**
   * Parse XML to restore the else-if and else inputs.
   * @param {!Element} xmlElement XML storage element.
   * @this {Blockly.Block}
   */
  domToMutation: function (xmlElement) {
    this.argumentCount = parseInt(xmlElement.getAttribute('argument'), 10) || 0;
    this.updateShape_();
  },

  /**
   * Populate the mutator's dialog with this block's components.
   * @param {!Blockly.Workspace} workspace Mutator's workspace.
   * @return {!Blockly.Block} Root block in mutator.
   * @this {Blockly.Block}
   */
  decompose: function (workspace) {
    var containerBlock = workspace.newBlock('function_common_dummy');
    containerBlock.initSvg();
    var connection = containerBlock.getInput('input_arguments').connection;
    for (var i = 1; i <= this.argumentCount; i++) {
      var argument = workspace.newBlock('function_argument_2');
      argument.initSvg();
      connection.connect(argument.previousConnection);
      connection = argument.nextConnection;
    }
    return containerBlock;
  },

  /**
   * Reconfigure this block based on the mutator dialog's components.
   * @param {!Blockly.Block} containerBlock Root block in mutator.
   * @this {Blockly.Block}
   */
  compose: function (containerBlock) {
    var argumentBlock = containerBlock.getInputTargetBlock('input_arguments');
    // Count number of inputs.
    var valueConnections = [];
    while (argumentBlock) {
      //if (argumentBlock.type == 'function_argument_2'){
      //this.argumentCount++;
      valueConnections.push(argumentBlock.valueConnection_);
      /*}
      else{
        throw TypeError('Unknown block type: ' + argumentBlock.type);
      }*/
      argumentBlock =
        argumentBlock.nextConnection &&
        argumentBlock.nextConnection.targetBlock();
    }

    // Disconnect any children that don't belong.
    for (var i = 1; i <= this.argumentCount; i++) {
      var connection = this.getInput('argument' + i).connection
        .targetConnection;
      if (connection && valueConnections.indexOf(connection) == -1) {
        connection.disconnect();
      }
    }
    this.argumentCount = valueConnections.length;

    this.updateShape_();
    // Reconnect any child blocks.
    this.reconnectChildBlocks_(valueConnections);
  },

  /**
   * Store pointers to any connected child blocks.
   * @param {!Blockly.Block} containerBlock Root block in mutator.
   * @this {Blockly.Block}
   */
  saveConnections: function (containerBlock) {
    var argumentBlock = containerBlock.getInputTargetBlock('input_arguments');
    var i = 1;
    while (argumentBlock) {
      //if (argumentBlock.type == 'function_argument_2'){
      var inputArgument = this.getInput('argument' + i);
      argumentBlock.valueConnection_ =
        inputArgument && inputArgument.connection.targetConnection;
      i++;
      /*}
      else{
        throw TypeError('Unknown block type: ' + argumentBlock.type);
      }*/
      argumentBlock =
        argumentBlock.nextConnection &&
        argumentBlock.nextConnection.targetBlock();
    }
  },

  /**
   * Reconstructs the block with all child blocks attached.
   * @this {Blockly.Block}
   */
  /*
  rebuildShape_: function() {
   var valueConnections = [null];
   
   var i = 1;
   while (this.getInput('argument' + i)) {
     var inputArgument = this.getInput('argument' + i);
     valueConnections.push(inputArgument.connection.targetConnection);
     i++;
   }
   
   this.updateShape_();
   this.reconnectChildBlocks_(valueConnections);
  },
  */

  /**
   * Modify this block to have the correct number of inputs.
   * @this {Blockly.Block}
   * @private
   */
  updateShape_: function () {
    // Delete everything.
    var i = 1;
    while (this.getInput('argument' + i)) {
      this.removeInput('argument' + i);
      i++;
    }

    // Rebuild block.
    for (i = 1; i <= this.argumentCount; i++) {
      this.appendValueInput('argument' + i)
        .setAlign(Blockly.ALIGN_RIGHT)
        .setCheck(null)
        .appendField('argument-' + i);
    }
  },

  /**
   * Reconnects child blocks.
   * @param {!Array.<?Blockly.RenderedConnection>} valueConnections List of
   * value connections for 'if' input.
   * @param {!Array.<?Blockly.RenderedConnection>} statementConnections List of
   * statement connections for 'do' input.
   * @param {?Blockly.RenderedConnection} elseStatementConnection Statement
   * connection for else input.
   * @this {Blockly.Block}
   */
  reconnectChildBlocks_: function (valueConnections) {
    for (var i = 1; i <= this.argumentCount; i++) {
      Blockly.Mutator.reconnect(valueConnections[i], this, 'argument' + i);
    }
  },
};

Blockly.Blocks['function_call_without_return'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('Function Call')
      .appendField(new Blockly.FieldTextInput('Name'), 'function_name');
    this.setInputsInline(false);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setStyle('scope_no_connect_block');
    this.argumentCount = 1;
    this.updateShape_();
    this.setTooltip(
      'Function Call With non or Many Argument and has no return '
    );
    this.setMutator(new Blockly.Mutator(['function_argument_2']));
    this.setHelpUrl('');
  },

  /**
   * Create XML to represent the number of argument inputs.
   * @return {Element} XML storage element.
   * @this {Blockly.Block}
   */
  mutationToDom: function () {
    /** 
     *  Commenting "if" & "null" to capture removal of default argument by user. 
     *  (T3054133 - 454)
     **/ 
    // if (!this.argumentCount) {
    //   return null;
    // }

    var container = Blockly.utils.xml.createElement('mutation');
    // if (this.argumentCount) {
      container.setAttribute('argument', this.argumentCount);
    // }

    return container;
  },

  /**
   * Parse XML to restore the else-if and else inputs.
   * @param {!Element} xmlElement XML storage element.
   * @this {Blockly.Block}
   */
  domToMutation: function (xmlElement) {
    this.argumentCount = parseInt(xmlElement.getAttribute('argument'), 10) || 0;
    this.updateShape_();
  },

  /**
   * Populate the mutator's dialog with this block's components.
   * @param {!Blockly.Workspace} workspace Mutator's workspace.
   * @return {!Blockly.Block} Root block in mutator.
   * @this {Blockly.Block}
   */
  decompose: function (workspace) {
    var containerBlock = workspace.newBlock('function_common_dummy');
    containerBlock.initSvg();
    var connection = containerBlock.getInput('input_arguments').connection;
    for (var i = 1; i <= this.argumentCount; i++) {
      var argument = workspace.newBlock('function_argument_2');
      argument.initSvg();
      connection.connect(argument.previousConnection);
      connection = argument.nextConnection;
    }
    return containerBlock;
  },

  /**
   * Reconfigure this block based on the mutator dialog's components.
   * @param {!Blockly.Block} containerBlock Root block in mutator.
   * @this {Blockly.Block}
   */
  compose: function (containerBlock) {
    var argumentBlock = containerBlock.getInputTargetBlock('input_arguments');
    // Count number of inputs.
    var valueConnections = [];
    while (argumentBlock) {
      //if (argumentBlock.type == 'function_argument_2'){
      //this.argumentCount++;
      valueConnections.push(argumentBlock.valueConnection_);
      /*}
      else{
        throw TypeError('Unknown block type: ' + argumentBlock.type);
      }*/
      argumentBlock =
        argumentBlock.nextConnection &&
        argumentBlock.nextConnection.targetBlock();
    }

    // Disconnect any children that don't belong.
    for (var i = 1; i <= this.argumentCount; i++) {
      var connection = this.getInput('argument' + i).connection
        .targetConnection;
      if (connection && valueConnections.indexOf(connection) == -1) {
        connection.disconnect();
      }
    }
    this.argumentCount = valueConnections.length;

    this.updateShape_();
    // Reconnect any child blocks.
    this.reconnectChildBlocks_(valueConnections);
  },

  /**
   * Store pointers to any connected child blocks.
   * @param {!Blockly.Block} containerBlock Root block in mutator.
   * @this {Blockly.Block}
   */
  saveConnections: function (containerBlock) {
    var argumentBlock = containerBlock.getInputTargetBlock('input_arguments');
    var i = 1;
    while (argumentBlock) {
      //if (argumentBlock.type == 'function_argument_2'){
      var inputArgument = this.getInput('argument' + i);
      argumentBlock.valueConnection_ =
        inputArgument && inputArgument.connection.targetConnection;
      i++;
      /*}
      else{
        throw TypeError('Unknown block type: ' + argumentBlock.type);
      }*/
      argumentBlock =
        argumentBlock.nextConnection &&
        argumentBlock.nextConnection.targetBlock();
    }
  },

  /**
   * Reconstructs the block with all child blocks attached.
   * @this {Blockly.Block}
   */
  /*
  rebuildShape_: function() {
   var valueConnections = [null];
   
   var i = 1;
   while (this.getInput('argument' + i)) {
     var inputArgument = this.getInput('argument' + i);
     valueConnections.push(inputArgument.connection.targetConnection);
     i++;
   }
   
   this.updateShape_();
   this.reconnectChildBlocks_(valueConnections);
  },
  */

  /**
   * Modify this block to have the correct number of inputs.
   * @this {Blockly.Block}
   * @private
   */
  updateShape_: function () {
    // Delete everything.
    var i = 1;
    while (this.getInput('argument' + i)) {
      this.removeInput('argument' + i);
      i++;
    }

    // Rebuild block.
    for (i = 1; i <= this.argumentCount; i++) {
      this.appendValueInput('argument' + i)
        .setAlign(Blockly.ALIGN_RIGHT)
        .setCheck(null)
        .appendField('argument-' + i);
    }
  },

  /**
   * Reconnects child blocks.
   * @param {!Array.<?Blockly.RenderedConnection>} valueConnections List of
   * value connections for 'if' input.
   * @param {!Array.<?Blockly.RenderedConnection>} statementConnections List of
   * statement connections for 'do' input.
   * @param {?Blockly.RenderedConnection} elseStatementConnection Statement
   * connection for else input.
   * @this {Blockly.Block}
   */
  reconnectChildBlocks_: function (valueConnections) {
    for (var i = 1; i <= this.argumentCount; i++) {
      Blockly.Mutator.reconnect(valueConnections[i], this, 'argument' + i);
    }
  },
};

Blockly.Blocks['function_def_no_return'] = {
  /**
   * Block for defining a procedure with no return value.
   * @this {Blockly.Block}
   */
  init: function () {
    var nameField = new Blockly.FieldTextInput('', Blockly.Procedures.rename);
    nameField.setSpellcheck(false);
    this.appendDummyInput()
      .appendField('Function')
      .appendField(nameField, 'NAME')
      .appendField('', 'PARAMS');
    this.setMutator(new Blockly.Mutator(['procedures_mutatorarg']));
    if (
      (this.workspace.options.comments ||
        (this.workspace.options.parentWorkspace &&
          this.workspace.options.parentWorkspace.options.comments)) &&
      Blockly.Msg['PROCEDURES_DEFNORETURN_COMMENT']
    ) {
      this.setCommentText(Blockly.Msg['PROCEDURES_DEFNORETURN_COMMENT']);
    }
    this.setStyle('scope_no_connect_block');
    this.setTooltip(Blockly.Msg['PROCEDURES_DEFNORETURN_TOOLTIP']);
    this.setHelpUrl(Blockly.Msg['PROCEDURES_DEFNORETURN_HELPURL']);
    this.arguments_ = [];
    this.argumentVarModels_ = [];
    this.setStatements_(true);
    this.statementConnection_ = null;
    //this.setGlobelFunction = false;
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
  },
  /**
   * Add or remove the statement block from this function definition.
   * @param {boolean} hasStatements True if a statement block is needed.
   * @this {Blockly.Block}
   */
  setStatements_: function (hasStatements) {
    if (this.hasStatements_ === hasStatements) {
      return;
    }
    if (hasStatements) {
      this.appendStatementInput('STACK').appendField(
        Blockly.Msg['PROCEDURES_DEFNORETURN_DO']
      );
      if (this.getInput('RETURN')) {
        this.moveInputBefore('STACK', 'RETURN');
      }
    } else {
      this.removeInput('STACK', true);
    }
    this.hasStatements_ = hasStatements;
  },
  /**
   * Update the display of parameters for this procedure definition block.
   * @private
   * @this {Blockly.Block}
   */
  updateParams_: function () {
    // Merge the arguments into a human-readable list.
    var paramString = '';
    if (this.arguments_.length) {
      paramString =
        Blockly.Msg['PROCEDURES_BEFORE_PARAMS'] +
        ' ' +
        this.arguments_.join(', ');
    }
    // The params field is deterministic based on the mutation,
    // no need to fire a change event.
    Blockly.Events.disable();
    try {
      this.setFieldValue(paramString, 'PARAMS');
    } finally {
      Blockly.Events.enable();
    }
  },
  /**
   * Create XML to represent the argument inputs.
   * @param {boolean=} opt_paramIds If true include the IDs of the parameter
   *     quarks.  Used by Blockly.Procedures.mutateCallers for reconnection.
   * @return {!Element} XML storage element.
   * @this {Blockly.Block}
   */
  mutationToDom: function (opt_paramIds) {
    var container = Blockly.utils.xml.createElement('mutation');
    if (opt_paramIds) {
      container.setAttribute('name', this.getFieldValue('NAME'));
    }
    for (var i = 0; i < this.argumentVarModels_.length; i++) {
      var parameter = Blockly.utils.xml.createElement('arg');
      const argModel = this.argumentVarModels_[i];
      if(argModel?.name){
        parameter.setAttribute('name', argModel?.name);
        parameter.setAttribute('varid', argModel?.getId());
        if (opt_paramIds && this.paramIds_) {
          parameter.setAttribute('paramId', this.paramIds_[i]);
        }
        container.appendChild(parameter);
      }
    }

    // Save whether the statement input is visible.
    if (!this.hasStatements_) {
      container.setAttribute('statements', 'false');
    }
    if (!this.setGlobelFunction) {
      container.setAttribute('globel', 'false');
    } else {
      container.setAttribute('globel', 'true');
    }
    //console.log('test3');
    return container;
  },
  /**
   * Parse XML to restore the argument inputs.
   * @param {!Element} xmlElement XML storage element.
   * @this {Blockly.Block}
   */
  domToMutation: function (xmlElement) {
    this.arguments_ = [];
    this.argumentVarModels_ = [];
    for (var i = 0, childNode; (childNode = xmlElement.childNodes[i]); i++) {
      if (childNode.nodeName.toLowerCase() == 'arg') {
        var varName = childNode.getAttribute('name');
        var varId =
          childNode.getAttribute('varid') || childNode.getAttribute('varId');
        this.arguments_.push(varName);
        var variable = Blockly.Variables.getOrCreateVariablePackage(
          this.workspace,
          varId,
          varName,
          '_args'
        );
        if (variable != null) {
          this.argumentVarModels_.push(variable);
        } else {
          // console.log('Failed to create a variable with name ' + varName + ', ignoring.');
        }
      }
    }
    this.updateParams_();
    Blockly.Procedures.mutateCallers(this);

    // Show or hide the statement input.
    this.setStatements_(xmlElement.getAttribute('statements') !== 'false');
    if (xmlElement.getAttribute('globel') !== 'false') {
      this.setGlobelFunction = true;
    }
    //console.log('test2');
  },
  /**
   * Populate the mutator's dialog with this block's components.
   * @param {!Blockly.Workspace} workspace Mutator's workspace.
   * @return {!Blockly.Block} Root block in mutator.
   * @this {Blockly.Block}
   */
  decompose: function (workspace) {
    /*
     * Creates the following XML:
     * <block type="function_mutatorcontainer">
     *   <statement name="STACK">
     *     <block type="procedures_mutatorarg">
     *       <field name="NAME">arg1_name</field>
     *       <next>etc...</next>
     *     </block>
     *   </statement>
     * </block>
     */

    var containerBlockNode = Blockly.utils.xml.createElement('block');
    containerBlockNode.setAttribute('type', 'function_mutatorcontainer');
    var statementNode = Blockly.utils.xml.createElement('statement');
    statementNode.setAttribute('name', 'STACK');
    containerBlockNode.appendChild(statementNode);

    var node = statementNode;
    for (var i = 0; i < this.arguments_.length; i++) {
      var argBlockNode = Blockly.utils.xml.createElement('block');
      argBlockNode.setAttribute('type', 'procedures_mutatorarg');
      var fieldNode = Blockly.utils.xml.createElement('field');
      fieldNode.setAttribute('name', 'NAME');
      var argumentName = Blockly.utils.xml.createTextNode(this.arguments_[i]);
      fieldNode.appendChild(argumentName);
      argBlockNode.appendChild(fieldNode);
      var nextNode = Blockly.utils.xml.createElement('next');
      argBlockNode.appendChild(nextNode);

      node.appendChild(argBlockNode);
      node = nextNode;
    }

    var containerBlock = Blockly.Xml.domToBlock(containerBlockNode, workspace);

    if (this.type == 'function_def_return') {
      containerBlock.setFieldValue(this.hasStatements_, 'STATEMENTS');
    } else {
      containerBlock.removeInput('STATEMENT_INPUT');
    }
    //containerBlock.setFieldValue(this.hasStatements_, 'STATEMENTS');
    //console.log('test1');

    containerBlock.setFieldValue(this.setGlobelFunction, 'GlobelFunction');

    // Initialize procedure's callers with blank IDs.
    Blockly.Procedures.mutateCallers(this);
    return containerBlock;
  },
  /**
   * Reconfigure this block based on the mutator dialog's components.
   * @param {!Blockly.Block} containerBlock Root block in mutator.
   * @this {Blockly.Block}
   */
  compose: function (containerBlock) {
    // Parameter list.
    this.arguments_ = [];
    this.paramIds_ = [];
    this.argumentVarModels_ = [];
    var paramBlock = containerBlock.getInputTargetBlock('STACK');
    while (paramBlock) {
      var varName = paramBlock.getFieldValue('NAME');
      this.arguments_.push(varName);
      var variable = this.workspace.getVariable(varName, '_args');
      this.argumentVarModels_.push(variable);

      this.paramIds_.push(paramBlock.id);
      paramBlock =
        paramBlock.nextConnection && paramBlock.nextConnection.targetBlock();
    }
    this.updateParams_();
    Blockly.Procedures.mutateCallers(this);

    // Show/hide the statement input.
    var hasStatements = containerBlock.getFieldValue('STATEMENTS');
    if (hasStatements !== null) {
      hasStatements = hasStatements == 'TRUE';
      if (this.hasStatements_ != hasStatements) {
        if (hasStatements) {
          this.setStatements_(true);
          // Restore the stack, if one was saved.
          Blockly.Mutator.reconnect(this.statementConnection_, this, 'STACK');
          this.statementConnection_ = null;
        } else {
          // Save the stack, then disconnect it.
          var stackConnection = this.getInput('STACK').connection;
          this.statementConnection_ = stackConnection.targetConnection;
          if (this.statementConnection_) {
            var stackBlock = stackConnection.targetBlock();
            stackBlock.unplug();
            stackBlock.bumpNeighbours();
          }
          this.setStatements_(false);
        }
      }
    }
    var hasGlobelFunction = containerBlock.getFieldValue('GlobelFunction');
    if (hasGlobelFunction !== null) {
      hasGlobelFunction = hasGlobelFunction == 'TRUE';
      if (this.setGlobelFunction != hasGlobelFunction) {
        if (hasGlobelFunction) {
          this.setGlobelFunction = true;
        } else {
          this.setGlobelFunction = false;
        }
      }
    }
  },
  /**
   * Return the signature of this procedure definition.
   * @return {!Array} Tuple containing three elements:
   *     - the name of the defined procedure,
   *     - a list of all its arguments,
   *     - that it DOES NOT have a return value.
   * @this {Blockly.Block}
   */
  getProcedureDef: function () {
    return [this.getFieldValue('NAME'), this.arguments_, false];
  },
  /**
   * Return all variables referenced by this block.
   * @return {!Array.<string>} List of variable names.
   * @this {Blockly.Block}
   */
  getVars: function () {
    return this.arguments_;
  },
  /**
   * Return all variables referenced by this block.
   * @return {!Array.<!Blockly.VariableModel>} List of variable models.
   * @this {Blockly.Block}
   */
  getVarModels: function () {
    return this.argumentVarModels_;
  },
  /**
   * Notification that a variable is renaming.
   * If the ID matches one of this block's variables, rename it.
   * @param {string} oldId ID of variable to rename.
   * @param {string} newId ID of new variable.  May be the same as oldId, but
   *     with an updated name.  Guaranteed to be the same type as the old
   *     variable.
   * @override
   * @this {Blockly.Block}
   */
  renameVarById: function (oldId, newId) {
    var oldVariable = this.workspace.getVariableById(oldId);
    if (oldVariable.type != '') {
      // Procedure arguments always have the empty type.
      return;
    }
    var oldName = oldVariable.name;
    var newVar = this.workspace.getVariableById(newId);

    var change = false;
    for (var i = 0; i < this.argumentVarModels_.length; i++) {
      if (this.argumentVarModels_[i].getId() == oldId) {
        this.arguments_[i] = newVar.name;
        this.argumentVarModels_[i] = newVar;
        change = true;
      }
    }
    if (change) {
      this.displayRenamedVar_(oldName, newVar.name);
      Blockly.Procedures.mutateCallers(this);
    }
  },
  /**
   * Notification that a variable is renaming but keeping the same ID.  If the
   * variable is in use on this block, rerender to show the new name.
   * @param {!Blockly.VariableModel} variable The variable being renamed.
   * @package
   * @override
   * @this {Blockly.Block}
   */
  updateVarName: function (variable) {
    var newName = variable.name;
    var change = false;
    for (var i = 0; i < this.argumentVarModels_.length; i++) {
      if (this.argumentVarModels_[i].getId() == variable.getId()) {
        var oldName = this.arguments_[i];
        this.arguments_[i] = newName;
        change = true;
      }
    }
    if (change) {
      this.displayRenamedVar_(oldName, newName);
      Blockly.Procedures.mutateCallers(this);
    }
  },
  /**
   * Update the display to reflect a newly renamed argument.
   * @param {string} oldName The old display name of the argument.
   * @param {string} newName The new display name of the argument.
   * @private
   * @this {Blockly.Block}
   */
  displayRenamedVar_: function (oldName, newName) {
    this.updateParams_();
    // Update the mutator's variables if the mutator is open.
    if (this.mutator && this.mutator.isVisible()) {
      var blocks = this.mutator.workspace_.getAllBlocks(false);
      for (var i = 0, block; (block = blocks[i]); i++) {
        if (
          block.type == 'procedures_mutatorarg' &&
          Blockly.Names.equals(oldName, block.getFieldValue('NAME'))
        ) {
          block.setFieldValue(newName, 'NAME');
        }
      }
    }
  },
  /**
   * Add custom menu options to this block's context menu.
   * @param {!Array} options List of menu options to add to.
   * @this {Blockly.Block}
   */
  customContextMenu: function (options) {
    if (this.isInFlyout) {
      return;
    }
    // Add option to create caller.
    var option = { enabled: true };
    var name = this.getFieldValue('NAME');
    option.text = Blockly.Msg['PROCEDURES_CREATE_DO'].replace('%1', name);
    var xmlMutation = Blockly.utils.xml.createElement('mutation');
    xmlMutation.setAttribute('name', name);
    for (var i = 0; i < this.arguments_.length; i++) {
      var xmlArg = Blockly.utils.xml.createElement('arg');
      xmlArg.setAttribute('name', this.arguments_[i]);
      xmlMutation.appendChild(xmlArg);
    }
    var xmlBlock = Blockly.utils.xml.createElement('block');
    xmlBlock.setAttribute('type', this.callType_);
    xmlBlock.appendChild(xmlMutation);
    option.callback = Blockly.ContextMenu.callbackFactory(this, xmlBlock);
    options.push(option);

    // Add options to create getters for each parameter.
    if (!this.isCollapsed()) {
      for (var i = 0; i < this.argumentVarModels_.length; i++) {
        var argOption = { enabled: true };
        var argVar = this.argumentVarModels_[i];
        argOption.text = Blockly.Msg['VARIABLES_SET_CREATE_GET'].replace(
          '%1',
          argVar.name
        );

        var argXmlField = Blockly.Variables.generateVariableFieldDom(argVar);
        var argXmlBlock = Blockly.utils.xml.createElement('block');
        argXmlBlock.setAttribute('type', 'variables_get');
        argXmlBlock.appendChild(argXmlField);
        argOption.callback = Blockly.ContextMenu.callbackFactory(
          this,
          argXmlBlock
        );
        options.push(argOption);
      }
    }
  },
  callType_: 'procedures_callnoreturn',
};

Blockly.Blocks['function_def_return'] = {
  /**
   * Block for defining a procedure with a return value.
   * @this {Blockly.Block}
   */
  init: function () {
    var nameField = new Blockly.FieldTextInput('', Blockly.Procedures.rename);
    nameField.setSpellcheck(false);
    this.appendDummyInput()
      .appendField('Function')
      .appendField(nameField, 'NAME')
      .appendField('', 'PARAMS');
    this.appendValueInput('RETURN')
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(Blockly.Msg['PROCEDURES_DEFRETURN_RETURN']);
    this.setMutator(new Blockly.Mutator(['procedures_mutatorarg']));
    if (
      (this.workspace.options.comments ||
        (this.workspace.options.parentWorkspace &&
          this.workspace.options.parentWorkspace.options.comments)) &&
      Blockly.Msg['PROCEDURES_DEFRETURN_COMMENT']
    ) {
      this.setCommentText(Blockly.Msg['PROCEDURES_DEFRETURN_COMMENT']);
    }
    this.setStyle('scope_no_connect_block');
    this.setTooltip(Blockly.Msg['PROCEDURES_DEFRETURN_TOOLTIP']);
    this.setHelpUrl(Blockly.Msg['PROCEDURES_DEFRETURN_HELPURL']);
    this.arguments_ = [];
    this.argumentVarModels_ = [];
    this.setStatements_(true);
    this.statementConnection_ = null;
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
  },
  setStatements_: Blockly.Blocks['function_def_no_return'].setStatements_,
  updateParams_: Blockly.Blocks['function_def_no_return'].updateParams_,
  mutationToDom: Blockly.Blocks['function_def_no_return'].mutationToDom,
  domToMutation: Blockly.Blocks['function_def_no_return'].domToMutation,
  decompose: Blockly.Blocks['function_def_no_return'].decompose,
  compose: Blockly.Blocks['function_def_no_return'].compose,
  /**
   * Return the signature of this procedure definition.
   * @return {!Array} Tuple containing three elements:
   *     - the name of the defined procedure,
   *     - a list of all its arguments,
   *     - that it DOES have a return value.
   * @this {Blockly.Block}
   */
  getProcedureDef: function () {
    return [this.getFieldValue('NAME'), this.arguments_, true];
  },
  getVars: Blockly.Blocks['function_def_no_return'].getVars,
  getVarModels: Blockly.Blocks['function_def_no_return'].getVarModels,
  renameVarById: Blockly.Blocks['function_def_no_return'].renameVarById,
  updateVarName: Blockly.Blocks['function_def_no_return'].updateVarName,
  displayRenamedVar_:
    Blockly.Blocks['function_def_no_return'].displayRenamedVar_,
  customContextMenu: Blockly.Blocks['function_def_no_return'].customContextMenu,
  callType_: 'procedures_callreturn',
};

Blockly.Blocks['function_mutatorcontainer'] = {
  /**
   * Mutator block for procedure container.
   * @this {Blockly.Block}
   */
  init: function () {
    this.appendDummyInput().appendField(
      Blockly.Msg['PROCEDURES_MUTATORCONTAINER_TITLE']
    );
    this.appendStatementInput('STACK');
    this.appendDummyInput('STATEMENT_INPUT')
      .appendField(Blockly.Msg['PROCEDURES_ALLOW_STATEMENTS'])
      .appendField(new Blockly.FieldCheckbox('TRUE'), 'STATEMENTS');
    this.appendDummyInput('GLOBEL_INPUT')
      .appendField('Set Global Function')
      .appendField(new Blockly.FieldCheckbox('FALSE'), 'GlobelFunction');
    this.setStyle('scope_no_connect_block');
    this.setTooltip(Blockly.Msg['PROCEDURES_MUTATORCONTAINER_TOOLTIP']);
    this.contextMenu = false;
  },
};

Blockly.Blocks['function_create_one'] = {
  /**
   * Block for defining a procedure with no return value.
   * @this {Blockly.Block}
   */
  init: function () {
    /* var nameField = new Blockly.FieldTextInput('',
         Blockly.Procedures.rename);
     nameField.setSpellcheck(false);*/
    this.appendDummyInput()
      .appendField('Function Definition')
      /*.appendField(nameField, 'NAME')*/
      .appendField('', 'PARAMS');
    this.appendValueInput('RETURN')
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(Blockly.Msg['PROCEDURES_DEFRETURN_RETURN']);
    this.setMutator(new Blockly.Mutator(['procedures_mutatorarg']));
    if (
      (this.workspace.options.comments ||
        (this.workspace.options.parentWorkspace &&
          this.workspace.options.parentWorkspace.options.comments)) &&
      Blockly.Msg['PROCEDURES_DEFNORETURN_COMMENT']
    ) {
      this.setCommentText(Blockly.Msg['PROCEDURES_DEFNORETURN_COMMENT']);
    }
    this.setOutput(true, 'function_create_one');
    this.setStyle('scope_no_connect_block');
    this.setTooltip('Creates the function definition to store');
    this.setHelpUrl();
    this.arguments_ = [];
    this.argumentVarModels_ = [];
    this.setStatements_(true);
    this.statementConnection_ = null;
  },
  /**
   * Add or remove the statement block from this function definition.
   * @param {boolean} hasStatements True if a statement block is needed.
   * @this {Blockly.Block}
   */
  setStatements_: function (hasStatements) {
    if (this.hasStatements_ === hasStatements) {
      return;
    }
    if (hasStatements) {
      this.appendStatementInput('STACK').appendField(
        Blockly.Msg['PROCEDURES_DEFNORETURN_DO']
      );
      if (this.getInput('RETURN')) {
        this.moveInputBefore('STACK', 'RETURN');
      }
    } else {
      this.removeInput('STACK', true);
    }
    this.hasStatements_ = hasStatements;
  },
  /**
   * Update the display of parameters for this procedure definition block.
   * @private
   * @this {Blockly.Block}
   */
  updateParams_: function () {
    // Merge the arguments into a human-readable list.
    var paramString = '';
    if (this.arguments_.length) {
      paramString =
        Blockly.Msg['PROCEDURES_BEFORE_PARAMS'] +
        ' ' +
        this.arguments_.join(', ');
    }
    // The params field is deterministic based on the mutation,
    // no need to fire a change event.
    Blockly.Events.disable();
    try {
      this.setFieldValue(paramString, 'PARAMS');
    } finally {
      Blockly.Events.enable();
    }
  },
  /**
   * Create XML to represent the argument inputs.
   * @param {boolean=} opt_paramIds If true include the IDs of the parameter
   *     quarks.  Used by Blockly.Procedures.mutateCallers for reconnection.
   * @return {!Element} XML storage element.
   * @this {Blockly.Block}
   */
  mutationToDom: function (opt_paramIds) {
    var container = Blockly.utils.xml.createElement('mutation');
    /*if (opt_paramIds) {
      container.setAttribute('name', this.getFieldValue('NAME'));
    }*/
    for (var i = 0; i < this.argumentVarModels_.length; i++) {
      var parameter = Blockly.utils.xml.createElement('arg');
      const argModel = this.argumentVarModels_[i];
      if( argModel?.name){
        parameter.setAttribute('name', argModel?.name);
        parameter.setAttribute('varid', argModel?.getId());
        if (opt_paramIds && this.paramIds_) {
          parameter.setAttribute('paramId', this.paramIds_[i]);
        }
        container.appendChild(parameter);
      }
    }

    // Save whether the statement input is visible.
    if (!this.hasStatements_) {
      container.setAttribute('statements', 'false');
    }
    return container;
  },
  /**
   * Parse XML to restore the argument inputs.
   * @param {!Element} xmlElement XML storage element.
   * @this {Blockly.Block}
   */
  domToMutation: function (xmlElement) {
    this.arguments_ = [];
    this.argumentVarModels_ = [];
    for (var i = 0, childNode; (childNode = xmlElement.childNodes[i]); i++) {
      if (childNode.nodeName.toLowerCase() == 'arg') {
        var varName = childNode.getAttribute('name');
        var varId =
          childNode.getAttribute('varid') || childNode.getAttribute('varId');
        this.arguments_.push(varName);
        var variable = Blockly.Variables.getOrCreateVariablePackage(
          this.workspace,
          varId,
          varName,
          '_args'
        );
        if (variable != null) {
          this.argumentVarModels_.push(variable);
        } else {
          // console.log('Failed to create a variable with name ' + varName + ', ignoring.');
        }
      }
    }
    this.updateParams_();
    Blockly.Procedures.mutateCallers(this);

    // Show or hide the statement input.
    this.setStatements_(xmlElement.getAttribute('statements') !== 'false');
  },
  /**
   * Populate the mutator's dialog with this block's components.
   * @param {!Blockly.Workspace} workspace Mutator's workspace.
   * @return {!Blockly.Block} Root block in mutator.
   * @this {Blockly.Block}
   */
  decompose: function (workspace) {
    /*
     * Creates the following XML:
     * <block type="procedures_mutatorcontainer">
     *   <statement name="STACK">
     *     <block type="procedures_mutatorarg">
     *       <field name="NAME">arg1_name</field>
     *       <next>etc...</next>
     *     </block>
     *   </statement>
     * </block>
     */

    var containerBlockNode = Blockly.utils.xml.createElement('block');
    containerBlockNode.setAttribute('type', 'procedures_mutatorcontainer');
    var statementNode = Blockly.utils.xml.createElement('statement');
    statementNode.setAttribute('name', 'STACK');
    containerBlockNode.appendChild(statementNode);

    var node = statementNode;
    for (var i = 0; i < this.arguments_.length; i++) {
      var argBlockNode = Blockly.utils.xml.createElement('block');
      argBlockNode.setAttribute('type', 'procedures_mutatorarg');
      var fieldNode = Blockly.utils.xml.createElement('field');
      fieldNode.setAttribute('name', 'NAME');
      var argumentName = Blockly.utils.xml.createTextNode(this.arguments_[i]);
      fieldNode.appendChild(argumentName);
      argBlockNode.appendChild(fieldNode);
      var nextNode = Blockly.utils.xml.createElement('next');
      argBlockNode.appendChild(nextNode);

      node.appendChild(argBlockNode);
      node = nextNode;
    }

    var containerBlock = Blockly.Xml.domToBlock(containerBlockNode, workspace);

    if (this.type == 'procedures_defreturn') {
      containerBlock.setFieldValue(this.hasStatements_, 'STATEMENTS');
    } else {
      containerBlock.removeInput('STATEMENT_INPUT');
    }

    // Initialize procedure's callers with blank IDs.
    Blockly.Procedures.mutateCallers(this);
    return containerBlock;
  },
  /**
   * Reconfigure this block based on the mutator dialog's components.
   * @param {!Blockly.Block} containerBlock Root block in mutator.
   * @this {Blockly.Block}
   */
  compose: function (containerBlock) {
    // Parameter list.
    this.arguments_ = [];
    this.paramIds_ = [];
    this.argumentVarModels_ = [];
    var paramBlock = containerBlock.getInputTargetBlock('STACK');
    while (paramBlock) {
      var varName = paramBlock.getFieldValue('NAME');
      this.arguments_.push(varName);
      var variable = this.workspace.getVariable(varName, '_args');
      this.argumentVarModels_.push(variable);

      this.paramIds_.push(paramBlock.id);
      paramBlock =
        paramBlock.nextConnection && paramBlock.nextConnection.targetBlock();
    }
    this.updateParams_();
    Blockly.Procedures.mutateCallers(this);

    // Show/hide the statement input.
    var hasStatements = containerBlock.getFieldValue('STATEMENTS');
    if (hasStatements !== null) {
      hasStatements = hasStatements == 'TRUE';
      if (this.hasStatements_ != hasStatements) {
        if (hasStatements) {
          this.setStatements_(true);
          // Restore the stack, if one was saved.
          Blockly.Mutator.reconnect(this.statementConnection_, this, 'STACK');
          this.statementConnection_ = null;
        } else {
          // Save the stack, then disconnect it.
          var stackConnection = this.getInput('STACK').connection;
          this.statementConnection_ = stackConnection.targetConnection;
          if (this.statementConnection_) {
            var stackBlock = stackConnection.targetBlock();
            stackBlock.unplug();
            stackBlock.bumpNeighbours();
          }
          this.setStatements_(false);
        }
      }
    }
  },
  /**
   * Return the signature of this procedure definition.
   * @return {!Array} Tuple containing three elements:
   *     - the name of the defined procedure,
   *     - a list of all its arguments,
   *     - that it DOES NOT have a return value.
   * @this {Blockly.Block}
   */
  getProcedureDef: function () {
    return [/*this.getFieldValue('NAME'),*/ '', this.arguments_, false];
  },
  /**
   * Return all variables referenced by this block.
   * @return {!Array.<string>} List of variable names.
   * @this {Blockly.Block}
   */
  getVars: function () {
    return this.arguments_;
  },
  /**
   * Return all variables referenced by this block.
   * @return {!Array.<!Blockly.VariableModel>} List of variable models.
   * @this {Blockly.Block}
   */
  getVarModels: function () {
    return this.argumentVarModels_;
  },
  /**
   * Notification that a variable is renaming.
   * If the ID matches one of this block's variables, rename it.
   * @param {string} oldId ID of variable to rename.
   * @param {string} newId ID of new variable.  May be the same as oldId, but
   *     with an updated name.  Guaranteed to be the same type as the old
   *     variable.
   * @override
   * @this {Blockly.Block}
   */
  renameVarById: function (oldId, newId) {
    var oldVariable = this.workspace.getVariableById(oldId);
    if (oldVariable.type != '') {
      // Procedure arguments always have the empty type.
      return;
    }
    var oldName = oldVariable.name;
    var newVar = this.workspace.getVariableById(newId);

    var change = false;
    for (var i = 0; i < this.argumentVarModels_.length; i++) {
      if (this.argumentVarModels_[i].getId() == oldId) {
        this.arguments_[i] = newVar.name;
        this.argumentVarModels_[i] = newVar;
        change = true;
      }
    }
    if (change) {
      this.displayRenamedVar_(oldName, newVar.name);
      Blockly.Procedures.mutateCallers(this);
    }
  },
  /**
   * Notification that a variable is renaming but keeping the same ID.  If the
   * variable is in use on this block, rerender to show the new name.
   * @param {!Blockly.VariableModel} variable The variable being renamed.
   * @package
   * @override
   * @this {Blockly.Block}
   */
  updateVarName: function (variable) {
    var newName = variable.name;
    var change = false;
    for (var i = 0; i < this.argumentVarModels_.length; i++) {
      if (this.argumentVarModels_[i].getId() == variable.getId()) {
        var oldName = this.arguments_[i];
        this.arguments_[i] = newName;
        change = true;
      }
    }
    if (change) {
      this.displayRenamedVar_(oldName, newName);
      Blockly.Procedures.mutateCallers(this);
    }
  },
  /**
   * Update the display to reflect a newly renamed argument.
   * @param {string} oldName The old display name of the argument.
   * @param {string} newName The new display name of the argument.
   * @private
   * @this {Blockly.Block}
   */
  displayRenamedVar_: function (oldName, newName) {
    this.updateParams_();
    // Update the mutator's variables if the mutator is open.
    if (this.mutator && this.mutator.isVisible()) {
      var blocks = this.mutator.workspace_.getAllBlocks(false);
      for (var i = 0, block; (block = blocks[i]); i++) {
        if (
          block.type == 'procedures_mutatorarg' &&
          Blockly.Names.equals(oldName, block.getFieldValue('NAME'))
        ) {
          block.setFieldValue(newName, 'NAME');
        }
      }
    }
  },
  /**
   * Add custom menu options to this block's context menu.
   * @param {!Array} options List of menu options to add to.
   * @this {Blockly.Block}
   */
  customContextMenu: function (options) {
    if (this.isInFlyout) {
      return;
    }
    // Add option to create caller.
    var option = { enabled: true };
    /*var name = this.getFieldValue('NAME');*/
    /*option.text = Blockly.Msg['PROCEDURES_CREATE_DO'].replace('%1', name);*/
    var xmlMutation = Blockly.utils.xml.createElement('mutation');
    /*xmlMutation.setAttribute('name', name);*/
    for (var i = 0; i < this.arguments_.length; i++) {
      var xmlArg = Blockly.utils.xml.createElement('arg');
      xmlArg.setAttribute('name', this.arguments_[i]);
      xmlMutation.appendChild(xmlArg);
    }
    var xmlBlock = Blockly.utils.xml.createElement('block');
    xmlBlock.setAttribute('type', this.callType_);
    xmlBlock.appendChild(xmlMutation);
    option.callback = Blockly.ContextMenu.callbackFactory(this, xmlBlock);
    options.push(option);

    // Add options to create getters for each parameter.
    if (!this.isCollapsed()) {
      for (var i = 0; i < this.argumentVarModels_.length; i++) {
        var argOption = { enabled: true };
        var argVar = this.argumentVarModels_[i];
        argOption.text = Blockly.Msg['VARIABLES_SET_CREATE_GET'].replace(
          '%1',
          argVar.name
        );

        var argXmlField = Blockly.Variables.generateVariableFieldDom(argVar);
        var argXmlBlock = Blockly.utils.xml.createElement('block');
        argXmlBlock.setAttribute('type', 'variables_get');
        argXmlBlock.appendChild(argXmlField);
        argOption.callback = Blockly.ContextMenu.callbackFactory(
          this,
          argXmlBlock
        );
        options.push(argOption);
      }
    }
  },
  callType_: 'function_create_one',
};

Blockly.JavaScript['function_call_with_return'] = function (block) {
  var value_name = block.getFieldValue('function_name');
  // TODO: Assemble JavaScript into code variable.
  var argumentCount = block.argumentCount;
  var code = value_name + '(';
  //var i = 1;
  var argumentValue;
  for (var i = 1; i <= argumentCount; i++) {
    if (i != 1) {
      code += ', ';
    }
    code += Blockly.JavaScript.valueToCode(
      block,
      'argument' + i,
      Blockly.JavaScript.ORDER_ATOMIC
    );
  }
  code += ')';
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['function_call_without_return'] = function (block) {
  var text_function_name = block.getFieldValue('function_name');
  // TODO: Assemble JavaScript into code variable.
  var argumentCount = block.argumentCount;
  var code = text_function_name + '(';
  //var i = 1;
  var argumentValue;
  for (var i = 1; i <= argumentCount; i++) {
    if (i != 1) {
      code += ', ';
    }
    code += Blockly.JavaScript.valueToCode(
      block,
      'argument' + i,
      Blockly.JavaScript.ORDER_ATOMIC
    );
  }
  code += ');';
  return code;
};

/*
Blockly.JavaScript['function_call_by_variable_return'] = function(block) {
  
  var value_variable = Blockly.JavaScript.valueToCode(block, 'variable', Blockly.JavaScript.ORDER_ATOMIC);
  // TODO: Assemble JavaScript into code variable.
  var argumentCount = block.argumentCount;
  var code = value_variable.slice(1,(value_variable.length - 1)) + '('; 
  //var i = 1;
  var argumentValue;
  for (var i = 1; i <= argumentCount; i++)
  {
    if (i != 1){
      code += ', ';
    }
    code += Blockly.JavaScript.valueToCode(block, 'argument' + i, Blockly.JavaScript.ORDER_ATOMIC);
  }
  code += ')';
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL ];
};
 
Blockly.JavaScript['function_call_by_variable_no_return'] = function(block) {
  
  var value_variable = Blockly.JavaScript.valueToCode(block, 'variable', Blockly.JavaScript.ORDER_ATOMIC);
  // TODO: Assemble JavaScript into code variable.
  var argumentCount = block.argumentCount;
  var code = value_variable.slice(1,(value_variable.length - 1)) +'(';
  //var i = 1;
  var argumentValue;
  for (var i = 1; i <= argumentCount; i++)
  {
    if (i != 1){
      code += ', ';
    }
    code += Blockly.JavaScript.valueToCode(block, 'argument' + i, Blockly.JavaScript.ORDER_ATOMIC);
  }
  code += ');';
  return code;
};
*/

Blockly.JavaScript['function_create'] = function (block) {
  let code = '';
  Blockly.JavaScript.STATEMENT_PREFIX &&
    (code += Blockly.JavaScript.injectId(
      Blockly.JavaScript.STATEMENT_PREFIX,
      block
    ));
  Blockly.JavaScript.STATEMENT_SUFFIX &&
    (code += Blockly.JavaScript.injectId(
      Blockly.JavaScript.STATEMENT_SUFFIX,
      block
    ));
  code &&
    (code = Blockly.JavaScript.prefixLines(code, Blockly.JavaScript.INDENT));
  var d = '';
  Blockly.JavaScript.INFINITE_LOOP_TRAP &&
    (d = Blockly.JavaScript.prefixLines(
      Blockly.JavaScript.injectId(Blockly.JavaScript.INFINITE_LOOP_TRAP, block),
      Blockly.JavaScript.INDENT
    ));
  var e = Blockly.JavaScript.statementToCode(block, 'STACK'),
    f =
      Blockly.JavaScript.valueToCode(
        block,
        'RETURN',
        Blockly.JavaScript.ORDER_NONE
      ) || '',
    g = '';
  e && f && (g = code);
  f && (f = Blockly.JavaScript.INDENT + 'return ' + f + ';\n');
  for (var k = [], h = 0; h < block.arguments_.length; h++)
    k[h] = Blockly.JavaScript.variableDB_.getName(
      block.arguments_[h],
      Blockly.Variables.NAME_TYPE
    );
  code =
    'async function ' + '(' + k.join(', ') + ') {\n' + code + d + e + g + f + '}';
  //code=Blockly.JavaScript.scrub_(block,code);
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['function_def_no_return'] = function (block) {
  var b = Blockly.JavaScript.variableDB_.getName(
      block.getFieldValue('NAME'),
      Blockly.PROCEDURE_CATEGORY_NAME
    ),
    code = '';
  Blockly.JavaScript.STATEMENT_PREFIX &&
    (code += Blockly.JavaScript.injectId(
      Blockly.JavaScript.STATEMENT_PREFIX,
      block
    ));
  Blockly.JavaScript.STATEMENT_SUFFIX &&
    (code += Blockly.JavaScript.injectId(
      Blockly.JavaScript.STATEMENT_SUFFIX,
      block
    ));
  code &&
    (code = Blockly.JavaScript.prefixLines(code, Blockly.JavaScript.INDENT));
  var d = '';
  Blockly.JavaScript.INFINITE_LOOP_TRAP &&
    (d = Blockly.JavaScript.prefixLines(
      Blockly.JavaScript.injectId(Blockly.JavaScript.INFINITE_LOOP_TRAP, block),
      Blockly.JavaScript.INDENT
    ));
  var e = Blockly.JavaScript.statementToCode(block, 'STACK'),
    f =
      Blockly.JavaScript.valueToCode(
        block,
        'RETURN',
        Blockly.JavaScript.ORDER_NONE
      ) || '',
    g = '';
  e && f && (g = code);
  f && (f = Blockly.JavaScript.INDENT + 'return ' + f + ';\n');
  for (var k = [], h = 0; h < block.arguments_.length; h++)
    k[h] = Blockly.JavaScript.variableDB_.getName(
      block.arguments_[h],
      Blockly.Variables.NAME_TYPE
    );
  if (block.setGlobelFunction == true) {
    b = 'window.' + b;
  } else {
    b = 'var ' + b;
  }
  code =
    b +
    ' = async function ' +
    '(' +
    k.join(', ') +
    ') {\n' +
    code +
    d +
    e +
    g +
    f +
    '};\n';
  //code=Blockly.JavaScript.scrub_(block,code);
  return code;
};

Blockly.JavaScript['function_def_return'] = function (block) {
  var b = Blockly.JavaScript.variableDB_.getName(
      block.getFieldValue('NAME'),
      Blockly.PROCEDURE_CATEGORY_NAME
    ),
    code = '';
  Blockly.JavaScript.STATEMENT_PREFIX &&
    (code += Blockly.JavaScript.injectId(
      Blockly.JavaScript.STATEMENT_PREFIX,
      block
    ));
  Blockly.JavaScript.STATEMENT_SUFFIX &&
    (code += Blockly.JavaScript.injectId(
      Blockly.JavaScript.STATEMENT_SUFFIX,
      block
    ));
  code &&
    (code = Blockly.JavaScript.prefixLines(code, Blockly.JavaScript.INDENT));
  var d = '';
  Blockly.JavaScript.INFINITE_LOOP_TRAP &&
    (d = Blockly.JavaScript.prefixLines(
      Blockly.JavaScript.injectId(Blockly.JavaScript.INFINITE_LOOP_TRAP, block),
      Blockly.JavaScript.INDENT
    ));
  var e = Blockly.JavaScript.statementToCode(block, 'STACK'),
    f =
      Blockly.JavaScript.valueToCode(
        block,
        'RETURN',
        Blockly.JavaScript.ORDER_NONE
      ) || '',
    g = '';
  e && f && (g = code);
  f && (f = Blockly.JavaScript.INDENT + 'return ' + f + ';\n');
  for (var k = [], h = 0; h < block.arguments_.length; h++)
    k[h] = Blockly.JavaScript.variableDB_.getName(
      block.arguments_[h],
      Blockly.Variables.NAME_TYPE
    );
  if (block.setGlobelFunction == true) {
    b = 'window.' + b;
  } else {
    b = 'var ' + b;
  }
  code =
    b +
    ' = async function ' +
    '(' +
    k.join(', ') +
    ') {\n' +
    code +
    d +
    e +
    g +
    f +
    '};\n';
  //code=Blockly.JavaScript.scrub_(block,code);
  return code;
};

Blockly.JavaScript['function_create_no_return'] = function (block) {
  var value_return = Blockly.JavaScript.valueToCode(
    block,
    'return',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = '';

  code = 'async function(){return ' + value_return + '}';

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['element_creation'] = function (block) {
  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = '__er.createElement(' + value_name + ')';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['element_remove'] = function (block) {
  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = '';

  value_name = removeBrace(value_name);

  code = '__er.removeElement(' + value_name + ');\n';
  return code;
};

Blockly.JavaScript['element_append'] = function (block) {
  var value_source = Blockly.JavaScript.valueToCode(
    block,
    'source',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  var value_add = Blockly.JavaScript.valueToCode(
    block,
    'add',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = '';

  value_source = removeBrace(value_source);
  value_add = removeBrace(value_add);

  code = '__er.appendElement(' + value_source + ', ' + value_add + ');\n';
  return code;
};
Blockly.JavaScript['remove_element_attr'] = function (block) {
  var value_source = Blockly.JavaScript.valueToCode(
    block,
    'source',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  var value_add = Blockly.JavaScript.valueToCode(
    block,
    'add',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = '';

  value_source = removeBrace(value_source);
  value_add = removeBrace(value_add);

  code = '__er.removeElementAttribute(' + value_source + ', "' + value_add + '");\n';
  return code;
};

Blockly.JavaScript['element_get_properties'] = function (block) {
  var dropdown_property = block.getFieldValue('property');
  var value_element = Blockly.JavaScript.valueToCode(
    block,
    'element',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = '';

  //dropdown_property = removeBrace(dropdown_property);
  value_element = removeBrace(value_element);

  code =
    '__er.getElementProperties(' +
    value_element +
    ', "' +
    dropdown_property +
    '")';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['element_set_properties'] = function (block) {
  var dropdown_property = block.getFieldValue('property');
  var value_element = Blockly.JavaScript.valueToCode(
    block,
    'element',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  var value_value = Blockly.JavaScript.valueToCode(
    block,
    'value',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  if(!value_value){
    value_value='undefined';
  }
  var attr_value =
    Blockly.JavaScript.valueToCode(
      block,
      'attr',
      Blockly.JavaScript.ORDER_ATOMIC
    ) || '""';
  // TODO: Assemble JavaScript into code variable.
  var code = '';

  value_element = removeBrace(value_element);
  //value_value = removeBrace(value_value);
  //dropdown_property = removeBrace(dropdown_property);

  code =
    '__er.setElementProperties("' +
    dropdown_property +
    '", ' +
    value_element +
    ', ' +
    value_value+
    ', ' +
    attr_value +
    ');\n';
  console.log("Code",!value_value,code);
  return code;
};

Blockly.JavaScript['element_get'] = function (block) {
  var dropdown_attribute = block.getFieldValue('attribute');
  var value_value = Blockly.JavaScript.valueToCode(
    block,
    'value',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = '';

  code = '__er.getElementBy("' + dropdown_attribute + '", ' + value_value + ')';

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['element_style_set_property'] = function (block) {
  var dropdown_type = block.getFieldValue('type');
  var value_element = Blockly.JavaScript.valueToCode(
    block,
    'element',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  var value_value = Blockly.JavaScript.valueToCode(
    block,
    'value',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code =
    '__er.setStyle("' +
    dropdown_type +
    '", ' +
    value_element +
    ', ' +
    value_value +
    ');\n';
  return code;
};

Blockly.JavaScript['element_style_get_property'] = function (block) {
  var dropdown_type = block.getFieldValue('type');
  var value_element = Blockly.JavaScript.valueToCode(
    block,
    'element',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code =
    '__er.getElementStyleProperties("' +
    dropdown_type +
    '", ' +
    value_element +
    ')';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['function_create_one'] = function (block) {
  var code = '';
  Blockly.JavaScript.STATEMENT_PREFIX &&
    (code += Blockly.JavaScript.injectId(
      Blockly.JavaScript.STATEMENT_PREFIX,
      block
    ));
  Blockly.JavaScript.STATEMENT_SUFFIX &&
    (code += Blockly.JavaScript.injectId(
      Blockly.JavaScript.STATEMENT_SUFFIX,
      block
    ));
  code &&
    (code = Blockly.JavaScript.prefixLines(code, Blockly.JavaScript.INDENT));
  var d = '';
  Blockly.JavaScript.INFINITE_LOOP_TRAP &&
    (d = Blockly.JavaScript.prefixLines(
      Blockly.JavaScript.injectId(Blockly.JavaScript.INFINITE_LOOP_TRAP, block),
      Blockly.JavaScript.INDENT
    ));
  var e = Blockly.JavaScript.statementToCode(block, 'STACK'),
    f =
      Blockly.JavaScript.valueToCode(
        block,
        'RETURN',
        Blockly.JavaScript.ORDER_NONE
      ) || '',
    g = '';
  e && f && (g = code);
  f && (f = Blockly.JavaScript.INDENT + 'return ' + f + ';\n');
  for (var k = [], h = 0; h < block.arguments_.length; h++)
    k[h] = Blockly.JavaScript.variableDB_.getName(
      block.arguments_[h],
      Blockly.Variables.NAME_TYPE
    );
  code =
    'async function ' + '(' + k.join(', ') + ') {\n' + code + d + e + g + f + '}';
  //code=Blockly.JavaScript.scrub_(block,code);
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};
Blockly.ContextMenuRegistry.registry.unregister('undoWorkspace');
Blockly.ContextMenuRegistry.registry.unregister('redoWorkspace');
Blockly.ContextMenuRegistry.registry.unregister('workspaceDelete');

/** Option to delete all blocks. */
Blockly.ContextMenuItems.registerDeleteAll = function () {
  /** @type {!Blockly.ContextMenuRegistry.RegistryItem} */
  var deleteOption = {
    displayText: function (
      /** @type {!Blockly.ContextMenuRegistry.Scope} */ scope
    ) {
      if (!scope.workspace) {
        return;
      }
      var deletableBlocksLength = Blockly.ContextMenuItems.getDeletableBlocks_(
        scope.workspace
      ).length;
      if (deletableBlocksLength == 1) {
        return Blockly.Msg['DELETE_BLOCK'];
      } else {
        return Blockly.Msg['DELETE_X_BLOCKS'].replace(
          '%1',
          String(deletableBlocksLength)
        );
      }
    },
    preconditionFn: function (
      /** @type {!Blockly.ContextMenuRegistry.Scope} */ scope
    ) {
      if (!scope.workspace) {
        return;
      }
      var deletableBlocksLength = Blockly.ContextMenuItems.getDeletableBlocks_(
        scope.workspace
      ).length;
      return deletableBlocksLength > 0 ? 'enabled' : 'disabled';
    },
    callback: function (
      /** @type {!Blockly.ContextMenuRegistry.Scope} */ scope
    ) {
      if (!scope.workspace) {
        return;
      }
      scope.workspace.cancelCurrentGesture();
      var deletableBlocks = Blockly.ContextMenuItems.getDeletableBlocks_(
        scope.workspace
      );
      var eventGroup = Blockly.utils.genUid();
      if (deletableBlocks.length < 2) {
        Blockly.ContextMenuItems.deleteNext_(deletableBlocks, eventGroup);
      } else {
        Blockly.confirm(
          Blockly.Msg['DELETE_ALL_BLOCKS'].replace(
            '%1',
            deletableBlocks.length
          ),
          function (ok) {
            if (ok) {
              Blockly.ContextMenuItems.deleteNext_(deletableBlocks, eventGroup);
            }
          }
        );
      }
    },
    scopeType: Blockly.ContextMenuRegistry.ScopeType.WORKSPACE,
    id: 'workspaceDelete',
    weight: 0,
  };
  Blockly.ContextMenuRegistry.registry.register(deleteOption);
};

// Blockly.JavaScript['event_start'] = function (block) {
//   var dropdown_compname = block.getFieldValue('component');
//   var dropdown_event = block.getFieldValue('event');
//   var statements_name = Blockly.JavaScript.statementToCode(block, 'NAME');
//   // TODO: Assemble JavaScript into code variable.
//   // var code = dropdown_compname+')' + '....' + dropdown_event + '...;\n';
//   var code = 'document.getElementById("' + dropdown_compname + '").addEventListener("' +
//     dropdown_event + '",function(){\n' + statements_name + '\n}, false);\n';
//   return code;
// };
Blockly.JavaScript['event_start'] = function (block) {
  const dropdown_compname = block.getFieldValue('component');
  const dropdown_event = block.getFieldValue('event');
  const statements_name = Blockly.JavaScript.statementToCode(block, 'NAME');
  // const code = '__er.start_event("'+dropdown_compname+'", "'+dropdown_event+'", function(){\n' + statements_name + '\n}, false) );\n';
  const name = `er_${dropdown_compname.replace(/\s/g, '_')}_${dropdown_event}`;
  const code = `async function ${name}(${EVENT_ARGS[dropdown_event] || EVENT_ARGS.DEFAULT}){\n ${statements_name} \n}\n`;
  return code;
};

//=====try catch
Blockly.JavaScript['try_catch'] = function (block) {
  var statements_try = Blockly.JavaScript.statementToCode(block, 'try');
  var statements_catch = Blockly.JavaScript.statementToCode(block, 'catch');
  var statements_finally = Blockly.JavaScript.statementToCode(block, 'finally');
  // TODO: Assemble JavaScript into code variable.
  var code = '';
  code =
    'try{\n' +
    statements_try +
    '\n}\n catch(err){\n' +
    statements_catch +
    '\n}\n';
  if (statements_finally != '') {
    code += 'finally {\n' + statements_finally + '\n}\n';
  } else {
    code += 'finally {\n}';
  }
  return code;
};

Blockly.JavaScript['regular_expression'] = function (block) {
  var dropdown_type = block.getFieldValue('type');
  var value_expression = Blockly.JavaScript.valueToCode(
    block,
    'expression',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  var value_input = Blockly.JavaScript.valueToCode(
    block,
    'input',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = '';
  //value_expression = removeBrace(value_expression);

  code =
    '__er.regularExpression("' +
    dropdown_type +
    '", ' +
    removeBrace(value_expression) +
    ', ' +
    value_input +
    ')';

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['undefined_or_null'] = function (block) {
  var dropdown_sort_type = block.getFieldValue('undefined_or_null');
  var code = '';
  code = dropdown_sort_type;
  //return code;
  return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

const getVatiable = Blockly.JavaScript['variables_get'];
Blockly.JavaScript['variables_get'] = function (block) {
  // console.log('block',block);
  var type = block.workspace.variableMap_.getVariableById(
    block.getFieldValue('VAR')
  ).type;
  var variableName = block.workspace.variableMap_.getVariableById(
    block.getFieldValue('VAR')
  ).name;
  var code = '';
  if (type !== 'bf_var') code = getVatiable(block);
  else code = ['(await __er.getState("' + variableName + '"))', 0];
  return code;
};
const setVatiable = Blockly.JavaScript['variables_set'];
Blockly.JavaScript['variables_set'] = function (block) {
  var type = block.workspace.variableMap_.getVariableById(
    block.getFieldValue('VAR')
  ).type;
  var variableName = block.workspace.variableMap_.getVariableById(
    block.getFieldValue('VAR')
  ).name;
  var value =
    Blockly.JavaScript.valueToCode(
      block,
      'VALUE',
      Blockly.JavaScript.ORDER_ASSIGNMENT
    ) || '0';
  // console.log('block',block);
  var code = '';
  if (type !== 'bf_var') code = setVatiable(block);
  else code = '__er.setState("' + variableName + '", ' + value + ');\n';
  //debugger;
  return code;
};

Blockly.JavaScript['invoke_service'] = function (block) {
  var dropdown_service = block.getFieldValue('services');
  // TODO: Assemble JavaScript into code variable.
  //var code = '__er.invokeServices("' + dropdown_service + '");\n';
  var statements_onsuccess = Blockly.JavaScript.statementToCode(
    block,
    'onSuccess'
  );
  var statements_onfailure = Blockly.JavaScript.statementToCode(
    block,
    'onFailure'
  );
  // TODO: Assemble JavaScript into code variable.
  var code =
    'await __er.invokeServices("' +
    dropdown_service +
    '",async function(){\n' +
    statements_onsuccess +
    '\n}, \nasync function(){\n' +
    statements_onfailure +
    '\n});\n';
  return code;
};

// Blockly.JavaScript['invoke_serviceSF'] = function(block) {
//   var dropdown_service = block.getFieldValue('invoke_servicesf');
//   var statements_onsuccess = Blockly.JavaScript.statementToCode(block, 'onSuccess');
//   var statements_onfailure = Blockly.JavaScript.statementToCode(block, 'onFailure');
//   // TODO: Assemble JavaScript into code variable.
//   var code =  '__er.invokeService('+dropdown_service+',function(){\n' +
//   					statements_onsuccess + '\n}, \n function(){\n' +
//   					statements_onfailure  + '\n});\n';
//   return code;
// };

Blockly.JavaScript['navigation_screen'] = function (block) {
  var dropdown_service = block.getFieldValue('naviagtescreens');
  // TODO: Assemble JavaScript into code variable.
  var code = '__er.navigateScreen("' + dropdown_service + '");\n';
  return code;
};

function removeBrace(value) {
  if (value.startsWith("'")) {
    value = value.slice(1, value.length - 1);
  }
  if (value.startsWith('"')) {
    value = value.slice(1, value.length - 1);
  }
  if (value.startsWith('(')) {
    value = value.slice(1, value.length - 1);
  }

  return value;
}
Blockly.Names.getName = function (name, type) {
  if (type == Blockly.VARIABLE_CATEGORY_NAME) {
    var varName = this.getNameForUserVariable_(name);
    if (varName) {
      name = varName;
    }
  }
  var normalized = name + '_' + type;

  var isVarType =
    type == Blockly.VARIABLE_CATEGORY_NAME ||
    type == Blockly.Names.DEVELOPER_VARIABLE_TYPE;

  var prefix = isVarType ? this.variablePrefix_ : '';
  if (normalized in this.db_) {
    return prefix + this.db_[normalized];
  }
  var safeName = this.getDistinctName(name, type);
  this.db_[normalized] = safeName.substr(prefix.length);
  return safeName;
};
Blockly.JavaScript.init = function (workspace) {
  // Create a dictionary of definitions to be printed before the code.
  Blockly.JavaScript.definitions_ = Object.create(null);
  // Create a dictionary mapping desired function names in definitions_
  // to actual function names (to avoid collisions with user functions).
  Blockly.JavaScript.functionNames_ = Object.create(null);

  if (!Blockly.JavaScript.variableDB_) {
    Blockly.JavaScript.variableDB_ = new Blockly.Names(
      Blockly.JavaScript.RESERVED_WORDS_
    );
  } else {
    Blockly.JavaScript.variableDB_.reset();
  }

  Blockly.JavaScript.variableDB_.setVariableMap(workspace.getVariableMap());

  var defvars = [];
  // Add developer variables (not created or named by the user).
  var devVarList = Blockly.Variables.allDeveloperVariables(workspace);
  for (var i = 0; i < devVarList.length; i++) {
    defvars.push(
      Blockly.JavaScript.variableDB_.getName(
        devVarList[i],
        Blockly.Names.DEVELOPER_VARIABLE_TYPE
      )
    );
  }

  // Add user variables, but only ones that are being used.
  var variables = Blockly.Variables.allUsedVarModels(workspace);
  for (var i = 0; i < variables.length; i++) {
    var dvar = Blockly.JavaScript.variableDB_.getName(
      variables[i].getId(),
      Blockly.VARIABLE_CATEGORY_NAME
    );
    if (variables[i].type !== '_event' && variables[i].type !== 'bf_var' && variables[i].type !== '_args')
      defvars.push(dvar);
  }

  // Declare all of the variables.
  if (defvars.length) {
    Blockly.JavaScript.definitions_['variables'] =
      'var ' + defvars.join(', ') + ';';
  }
  this.isInitialized = true;
};

/**
 * Get the name for a user-defined variable, based on its ID.
 * This should only be used for variables of type
 * Blockly.VARIABLE_CATEGORY_NAME.
 * @param {string} id The ID to look up in the variable map.
 * @return {?string} The name of the referenced variable, or null if there was
 *     no variable map or the variable was not found in the map.
 * @private
 */
Blockly.Names.prototype.getType = function (id) {
  if (!this.variableMap_) {
    // console.log('Deprecated call to Blockly.Names.prototype.getName without ' +
    //     'defining a variable map. To fix, add the following code in your ' +
    //     'generator\'s init() function:\n' +
    //     'Blockly.YourGeneratorName.variableDB_.setVariableMap(' +
    //     'workspace.getVariableMap());');
    return null;
  }
  var variable = this.variableMap_.getVariableById(id);
  if (variable) {
    return variable.type;
  } else {
    return null;
  }
};

Blockly.JavaScript['example_multilinetextinput'] = function (block) {
  // Text value.
  //var code = Blockly.JavaScript.multiline(block.getFieldValue('FIELDNAME'));
  //var value_name = Blockly.JavaScript.valueToCode(block, 'FIELDNAME', Blockly.JavaScript.ORDER_ATOMIC);
  var code = Blockly.JavaScript.multiline_quote_(
    block.getFieldValue('FIELDNAME')
  );

  /* var order = code.indexOf('+') != -1 ? Blockly.JavaScript.ORDER_ADDITION :
      Blockly.JavaScript.ORDER_ATOMIC;
  return [code, order];*/
  return '\n/**\n' + code + '\n**/\n';
};
const strRegExp = /^\s*'([^']|\\')*'\s*$/;
let JavaScript=Blockly.JavaScript;
/**
 * Enclose the provided value in 'String(...)' function.
 * Leave string literals alone.
 * @param {string} value Code evaluating to a value.
 * @return {Array<string|number>} Array containing code evaluating to a string
 *     and the order of the returned code.[string, number]
 */
const forceString = function(value) {
  if (strRegExp.test(value)) {
    return [value, JavaScript.ORDER_ATOMIC];
  }
  return ['String(' + value + ')', JavaScript.ORDER_FUNCTION_CALL];
};
Blockly.JavaScript['text_append'] = function(block) {
  // Append to a variable in place.
  const varName = JavaScript.variableDB_.getName(
    block.getFieldValue('VAR'),'VARIABLE');
    const value = JavaScript.valueToCode(block, 'TEXT',
    JavaScript.ORDER_NONE) || "''";
  if (!Blockly.Variables.getVariable(
    this.workspace,
    null,
    varName,
    'bf_var'
  )
  ) {
    const code = varName + ' += ' +
      forceString(value)[0] + ';\n';
    return code;
  }else{
    return `__er.setState('${varName}',(await __er.getState('${varName}'))+${forceString(value)[0]});\n`
  }
  
};

const lists_getIndex = Blockly.JavaScript['lists_getIndex'];
Blockly.JavaScript['lists_getIndex'] = function(block) {
  // Get element at index.
  // Note: Until January 2013 this block did not have MODE or WHERE inputs.
  const mode = block.getFieldValue('MODE') || 'GET';
  const where = block.getFieldValue('WHERE') || 'FROM_START';
  const listOrder = (where === 'RANDOM') ? Blockly.JavaScript.ORDER_NONE : Blockly.JavaScript.ORDER_MEMBER;
  let list = Blockly.JavaScript.valueToCode(block, 'VALUE', listOrder) || '[]';

  function cacheList() {
    if (list.match(/^\w+$/)) {
      return '';
    }
    const listVar = Blockly.JavaScript.variableDB_.getDistinctName('tmpList', Blockly.VARIABLE_CATEGORY_NAME);
    const code = 'var ' + listVar + ' = [...' + list + '];\n';
    list = listVar;
    return code;
  }

  switch (where) {
    case ('FIRST'):
      if (mode === 'GET') {
        const code = list + '[0]';
        return [code, Blockly.JavaScript.ORDER_MEMBER];
      } else if (mode === 'GET_REMOVE') {
        const code = list + '.shift()';
        return [code, Blockly.JavaScript.ORDER_MEMBER];
      } else if (mode === 'REMOVE') {
        let l = list;
        if(list.includes('__')){
          l = list.replace('(await __er.getState("', '').replace('"))', '');
          return '__er.setState("' + l + '" , ' +  list + '.slice(1));\n';
        }
        return l + '.shift();\n';
      }
      break;
    case ('LAST'):
      if (mode === 'GET') {
        const code = list + '.slice(-1)[0]';
        return [code, Blockly.JavaScript.ORDER_MEMBER];
      } else if (mode === 'GET_REMOVE') {
        const code = list + '.pop()';
        return [code, Blockly.JavaScript.ORDER_MEMBER];
      } else if (mode === 'REMOVE') {
        let l = list;
        if(list.includes('__')){
          l = list.replace('(await __er.getState("', '').replace('"))', '');
          console.log(l, list);
          return '__er.setState("' + l + '" , ' +  list + '.slice(0, -1));\n';
        }
        return list + '.pop();\n';
      }
      break;
    case ('FROM_START'): {
      const at = Blockly.JavaScript.getAdjusted(block, 'AT');
      if (mode === 'GET') {
        const code = list + '[' + at + ']';
        return [code, Blockly.JavaScript.ORDER_MEMBER];
      } else if (mode === 'GET_REMOVE') {
        const code = list + '.splice(' + at + ', 1)[0]';
        return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
      } else if (mode === 'REMOVE') {
        if(list.includes('__')){
          let l = list.replace('(await __er.getState("', '').replace('"))', '');
          // let code = cacheList();
          // code += list + '.splice(' + at + ', 1);\n';
          // code += '__er.setState("' + l + '" , ' + list + ');\n';
          let code = '__er.removeFromIndex("' + l + '" , ' + at + ');\n';
          return code;
        }
        return list + '.splice(' + at + ', 1);\n';
      }
      break;
    }
    case ('FROM_END'): {
      const at = Blockly.JavaScript.getAdjusted(block, 'AT', 1, true);
      if (mode === 'GET') {
        const code = list + '.slice(' + at + ')[0]';
        return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
      } else if (mode === 'GET_REMOVE') {
        const code = list + '.splice(' + at + ', 1)[0]';
        return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
      } else if (mode === 'REMOVE') {
        if(list.includes('__')){
          let l = list.replace('(await __er.getState("', '').replace('"))', '');
          let code = cacheList();
          code += list + '.splice(' + at + ', 1);\n';
          code += '__er.setState("' + l + '" , ' + list + ');\n';
          return code;
        }
        return list + '.splice(' + at + ', 1);';
      }
      break;
    }
    case ('RANDOM'): {
      return lists_getIndex(block);
      break;
    }
  }
  // throw Error('Unhandled combination (lists_getIndex).');
};

Blockly.JavaScript['lists_setIndex'] = function(block) {
  // Set element at index.
  // Note: Until February 2013 this block did not have MODE or WHERE inputs.

  let list = Blockly.JavaScript.valueToCode(block, 'LIST', Blockly.JavaScript.ORDER_MEMBER) || '[]';
  const mode = block.getFieldValue('MODE') || 'GET';
  const where = block.getFieldValue('WHERE') || 'FROM_START';
  const value = Blockly.JavaScript.valueToCode(block, 'TO', Blockly.JavaScript.ORDER_ASSIGNMENT) || 'null';

  // Cache non-trivial values to variables to prevent repeated look-ups.
  // Closure, which accesses and modifies 'list'.
  function cacheList() {
    if (list.match(/^\w+$/)) {
      return '';
    }
    const listVar = Blockly.JavaScript.variableDB_.getDistinctName('tmpList', Blockly.VARIABLE_CATEGORY_NAME);
    const code = 'var ' + listVar + ' = ' + list + ';\n';
    list = listVar;
    return code;
  }
  switch (where) {
    case ('FIRST'):
      if (mode === 'SET') {
        return list + '[0] = ' + value + ';\n';
      } else if (mode === 'INSERT') {
        let l = list;
        if(list.includes('__')){
          l = list.replace('(await __er.getState("', '').replace('"))', '');
          return '__er.setState("' + l + '" , ' + " [ "  + value + " , ..." + list + ']);\n';
        }
        return list + '.unshift(' + value + ');\n';
      }
      break;
    case ('LAST'):
      if (mode === 'SET') {
        let code = cacheList();
        code += list + '[' + list + '.length - 1] = ' + value + ';\n';
        return code;
      } else if (mode === 'INSERT') {
        let l = list;
        if(list.includes('__')){
          l = list.replace('(await __er.getState("', '').replace('"))', '');
          return '__er.setState("' + l + '" , ' + " [ ..." + list + ' , ' + value + ']);\n';
        }
        return list + '.push(' + value + ');\n';
      }
      break;
    case ('FROM_START'): {
      const at = Blockly.JavaScript.getAdjusted(block, 'AT');
      if (mode === 'SET') {
        return list + '[' + at + '] = ' + value + ';\n';
      } else if (mode === 'INSERT') {
        return list + '.splice(' + at + ', 0, ' + value + ');\n';
      }
      break;
    }
    case ('FROM_END'): {
      const at = Blockly.JavaScript.getAdjusted(
          block, 'AT', 1, false, Blockly.JavaScript.ORDER_SUBTRACTION);
      let code = cacheList();
      if (mode === 'SET') {
        code += list + '[' + list + '.length - ' + at + '] = ' + value + ';\n';
        return code;
      } else if (mode === 'INSERT') {
        code += list + '.splice(' + list + '.length - ' + at + ', 0, ' + value +
            ');\n';
        return code;
      }
      break;
    }
    case ('RANDOM'): {
      let code = cacheList();
      const xVar =
      Blockly.JavaScript.variableDB_.getDistinctName('tmpX', Blockly.VARIABLE_CATEGORY_NAME);
      code += 'var ' + xVar + ' = Math.floor(Math.random() * ' + list +
          '.length);\n';
      if (mode === 'SET') {
        code += list + '[' + xVar + '] = ' + value + ';\n';
        return code;
      } else if (mode === 'INSERT') {
        code += list + '.splice(' + xVar + ', 0, ' + value + ');\n';
        return code;
      }
      break;
    }
  }
  // throw Error('Unhandled combination (lists_setIndex).');
};

Blockly.JavaScript['getDevicesList'] = function (block) {
 // var dropdown_service = block.getFieldValue('services');
  // TODO: Assemble JavaScript into code variable.
  //var code = '__er.invokeServices("' + dropdown_service + '");\n';
  var statements_onsuccess = Blockly.JavaScript.statementToCode(
    block,
    'onDeviceDiscovered'
  );
  var statements_onfailure = Blockly.JavaScript.statementToCode(
    block,
    'onDeviceDisappered'
  );
  // TODO: Assemble JavaScript into code variable.
  var code =
    'await __er.getDeviceList(async function(list){\n' +
    statements_onsuccess +
    '\n}, \nasync function(list){\n' +
    statements_onfailure +
    '\n});\n';
  return code;
};
Blockly.JavaScript['pairDevice'] = function (block) {
  // var dropdown_service = block.getFieldValue('services');
   // TODO: Assemble JavaScript into code variable.
   //var code = '__er.invokeServices("' + dropdown_service + '");\n';
   var deviceID = Blockly.JavaScript.valueToCode(
    block,
    'input_fields',
    Blockly.JavaScript.ORDER_ATOMIC
  );
   var statements_onsuccess = Blockly.JavaScript.statementToCode(
     block,
     'onSuccess'
   );
   var statements_onfailure = Blockly.JavaScript.statementToCode(
     block,
     'onFailure'
   );
   // TODO: Assemble JavaScript into code variable.
   var code =
     'await __er.pairDevice('+(deviceID?deviceID:'""')+',async function(){\n' +
     statements_onsuccess +
     '\n}, \nasync function(err){\n' +
     statements_onfailure +
     '\n});\n';
   return code;
 };
 Blockly.JavaScript['unPairDevice'] = function (block) {
  // var dropdown_service = block.getFieldValue('services');
   // TODO: Assemble JavaScript into code variable.
   //var code = '__er.invokeServices("' + dropdown_service + '");\n';
   var deviceID = Blockly.JavaScript.valueToCode(
    block,
    'input_fields',
    Blockly.JavaScript.ORDER_ATOMIC
  );
   var statements_onsuccess = Blockly.JavaScript.statementToCode(
     block,
     'onSuccess'
   );
   var statements_onfailure = Blockly.JavaScript.statementToCode(
     block,
     'onFailure'
   );
   // TODO: Assemble JavaScript into code variable.
   var code =
     'await __er.unPairDevice('+(deviceID?deviceID:'""')+',async function(){\n' +
     statements_onsuccess +
     '\n}, \nasync function(err){\n' +
     statements_onfailure +
     '\n});\n';
   return code;
 };
 Blockly.JavaScript['connect'] = function (block) {
  // var dropdown_service = block.getFieldValue('services');
   // TODO: Assemble JavaScript into code variable.
   //var code = '__er.invokeServices("' + dropdown_service + '");\n';
   var deviceID = Blockly.JavaScript.valueToCode(
    block,
    'input_fields',
    Blockly.JavaScript.ORDER_ATOMIC
  );
   var statements_onsuccess = Blockly.JavaScript.statementToCode(
     block,
     'onSuccess'
   );
   var statements_onfailure = Blockly.JavaScript.statementToCode(
     block,
     'onFailure'
   );
   // TODO: Assemble JavaScript into code variable.
   var code =
     'await __er.connect('+(deviceID?deviceID:'""')+',async function(){\n' +
     statements_onsuccess +
     '\n}, \nasync function(err){\n' +
     statements_onfailure +
     '\n});\n';
   return code;
 };
 Blockly.JavaScript['disconnect'] = function (block) {
  // var dropdown_service = block.getFieldValue('services');
   // TODO: Assemble JavaScript into code variable.
   //var code = '__er.invokeServices("' + dropdown_service + '");\n';
   var deviceID = Blockly.JavaScript.valueToCode(
    block,
    'input_fields',
    Blockly.JavaScript.ORDER_ATOMIC
  );
   var statements_onsuccess = Blockly.JavaScript.statementToCode(
     block,
     'onSuccess'
   );
   var statements_onfailure = Blockly.JavaScript.statementToCode(
     block,
     'onFailure'
   );
   // TODO: Assemble JavaScript into code variable.
   var code =
     'await __er.disconnect('+(deviceID?deviceID:'""')+',async function(){\n' +
     statements_onsuccess +
     '\n}, \nasync function(err){\n' +
     statements_onfailure +
     '\n});\n';
   return code;
 };

 Blockly.JavaScript['disconnect'] = function (block) {
  // var dropdown_service = block.getFieldValue('services');
   // TODO: Assemble JavaScript into code variable.
   //var code = '__er.invokeServices("' + dropdown_service + '");\n';
   var deviceID = Blockly.JavaScript.valueToCode(
    block,
    'input_fields',
    Blockly.JavaScript.ORDER_ATOMIC
  );
   var statements_onsuccess = Blockly.JavaScript.statementToCode(
     block,
     'onSuccess'
   );
   var statements_onfailure = Blockly.JavaScript.statementToCode(
     block,
     'onFailure'
   );
   // TODO: Assemble JavaScript into code variable.
   var code =
     'await __er.disconnect('+(deviceID?deviceID:'""')+',async function(){\n' +
     statements_onsuccess +
     '\n}, \nasync function(err){\n' +
     statements_onfailure +
     '\n});\n';
   return code;
 };

 Blockly.JavaScript['listen'] = function (block) {
  // var dropdown_service = block.getFieldValue('services');
   // TODO: Assemble JavaScript into code variable.
   //var code = '__er.invokeServices("' + dropdown_service + '");\n';
   var deviceID = Blockly.JavaScript.valueToCode(
    block,
    'input_fields',
    Blockly.JavaScript.ORDER_ATOMIC
  );
   var statements_onsuccess = Blockly.JavaScript.statementToCode(
     block,
     'onSuccess'
   );
  
   // TODO: Assemble JavaScript into code variable.
   var code =
     'await __er.listen('+(deviceID?deviceID:'""')+',async function(obj){\n' +
     statements_onsuccess +
     '\n});\n';
   return code;
 };

 Blockly.JavaScript['command'] = function (block) {
  // var dropdown_service = block.getFieldValue('services');
   // TODO: Assemble JavaScript into code variable.
   //var code = '__er.invokeServices("' + dropdown_service + '");\n';
   var deviceID = Blockly.JavaScript.valueToCode(
    block,
    'input_fields1',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  var command = Blockly.JavaScript.valueToCode(
    block,
    'input_fields2',
    Blockly.JavaScript.ORDER_ATOMIC
  );
   var statements_onsuccess = Blockly.JavaScript.statementToCode(
     block,
     'onSuccess'
   );
   var statements_onfailure = Blockly.JavaScript.statementToCode(
     block,
     'onFailure'
   );
   // TODO: Assemble JavaScript into code variable.
   var code =
     'await __er.command('+(deviceID?deviceID:'""')+','+(command?command:'""')+',async function(){\n' +
     statements_onsuccess +
     '\n}, \nasync function(err){\n' +
     statements_onfailure +
     '\n});\n';
   return code;
 };