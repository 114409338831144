import { DrawingCanvas } from './drawingcanvas';
import { ContextMenu, DropDownMenu } from './menubar';
import { CommandStack, CreateNodeCommand,DeleteNodeCommand,MoveCommand } from './commands';
import { ExprParser } from './ExprParser';
import { undoEvent, redoEvent, cutEvent, copyEvent, pasteEvent, deleteNode } from './builderEvents';
import { Properties } from './properties';
import clickAudio from "../media/click.mp3";
import { listLanguage, util } from './services';
import { EmptyNode, IfElseNode,LaneNode } from './model';
import {Messages,BaseURL, LoopTypes, NonDropables} from './message';

import {showNotificationMessage} from '../../../../../helpers/integrationbuilder';
import {postData as servicePostData} from '../../../../../helpers/integrationbuilder';
import {getData as serviceGetData} from '../../../../../helpers/integrationbuilder';
import defaultOflVariable from '../../../default-variable';
import {FDBTableCheckEvent} from './builderEvents';
import { Functions } from './functions';

//window.application class.
function Application() {
	this.selectedNode = null;
	this.selectedObj = null;
	this.selectedShape = null;
	this.previousHighlightedNode = null;
	this.targetNode=null;
	this.tempChildType=null;
	this.innnerHeightTemp=0;
	this.copyNode = null;
	this.projectName = null;
	this.userTaskName = null;
	this.businessFunctionName = null;
	this.integrationBuilderId = null;
	this.reload = null;
	this.moveNodeIndex=null;
	this.dropableIndex=null;
	this.readOnly = false;
	this.customBlockDetails = null;
	this.dragType=null;
	this.empty=new EmptyNode("empty", 0, 0, "empty");
	this.moveEvent = false;
	this.findedPosition = false;
	this.canView = false;
	this.canCreate = false;
	this.canUpdate = false;
	this.canDelete = false;
	this.baasEnable = false;
	this.blockImages = null;
	this.customImage = null;
	this.isDownloadBOS = false;
    this.load = function() {
		this.canvas=new DrawingCanvas();
		this.canvas.addHandlers();
		this.nodeList=new Array();
		this.ui= new UIObject();
		this.LanguageLists = listLanguage();
		this.contextMenu=new ContextMenu();
		this.contextMenu.load();
		this.addHandler();
		this.commands  = new CommandStack();
		this.audio = new AudioPlayer();
		this.getCustomBlockDetails();
		//this.read();
	};
	this.createDropdownMenu = function(event){
		return new DropDownMenu(event);
	};
	this.deactivate = function() {
		this.canvas.deactivate();
	};
	this.parse = function(value){
		let expr= new ExprParser();
		if(expr.parse(value))
			return expr.print();
		else{
			// show error
		} 
		
	};
	this.addHandler =function(){
		document.onkeyup = function(e) {
		if (e.key === 'Escape' || e.which === 27 || e.keyCode === 27) {
			window.application.contextMenu.hide();
		}else if(e.which === 37 || e.which === 38 || e.which === 39 || e.which === 40)
			window.application.contextMenu.hide();
	  }
	  
	};
	this.refresh = function () {
		this.canvas.clear();
		this.render();
		this.draw();
		if (this.selectedNode){
			this.selectedNode.shape.objs[0].set({ stroke: '#FFF021', strokeWidth: 2 });
		}
		if (this.readOnly || (!(this.canDelete && this.canCreate && this.canUpdate) && this.canView)){
			this.deactivate();
		}
	};
	this.removePlaceHolder=function(){
		if(this.empty.parent){
			// console.log(" The empty block : ", this.empty);
			if(this.empty.parent.type==='ie') 
				this.empty.parent.deleteChild(this.empty);
			else{
				const index = this.empty.parent.childs.indexOf(this.empty);
				if (index > -1) {
					this.empty.parent.childs.splice(index, 1);
				}
				this.updateBlocks(this.empty.parent,index,false);
			}
			this.canvas.remove(this.empty.shape.group);
			this.empty.parent = null;
			this.canvas.renderAll();
		}
	};
	this.addPlaceHolder=function(){
		// console.log("========== The target node : ", this.targetNode, "The dropableIndex : ", this.dropableIndex);
		this.targetNode.addChild(this.empty,this.dropableIndex);
		this.empty.parent=this.targetNode;
		if (this.targetNode.shape.tbHeight)
			this.targetNode.shape.calculateHeight(this.targetNode);
		if(this.dropableIndex==0){
			if (this.targetNode.type == 'ie'){
				this.empty.shape.x=this.targetNode.shape?.x + 10;
				if(this.tempChildType=='if'){
					this.empty.shape.y=this.targetNode.shape?.y + this.targetNode.shape?.tbHeight;
				} else{
					this.empty.shape.y=this.targetNode.shape?.y + this.targetNode.shape?.ifHeight + 20;
				}
			} else {
				this.empty.shape.x=this.targetNode.shape.x + 10;
				this.empty.shape.y=this.targetNode.shape.y + this.targetNode.shape.tbHeight;
			}
		} else if (this.targetNode.type == 'ie'){
			let shape = null;
			if(this.tempChildType=='if'){
				shape = this.targetNode.ifChilds[this.dropableIndex-1]?.shape;
			} else{
				shape = this.targetNode.elseChilds[this.dropableIndex-1]?.shape;
			}
			this.empty.shape.x=shape?.x;
			this.empty.shape.y=shape?.y + shape?.height;
		} else{
			const shape=this.targetNode.getChilds()[this.dropableIndex-1]?.shape;
			this.empty.shape.x=shape?.x;
			this.empty.shape.y=shape?.y + shape?.height;
		}
		// console.log("========== The empty node : ", this.empty);
		this.empty.shape.create(this.canvas,this.empty.shape.innerHeight ,this.empty);
		this.updateBlocks(this.targetNode,this.dropableIndex,true);
		this.canvas.renderAll();	
	};
	this.createNode=function(name,x,y,type) {
		if(this.targetNode.minimize===1)
			return true;
		let b=false;
		let index=0;
		const node=window.utils.createNode(name,x,y,type);
		if (type.startsWith('fdb') || type.startsWith('dba')){
			let check = new FDBTableCheckEvent();
			check.execute(node);
		}
		if(this.targetNode){
			node.parent=this.targetNode;
			if(node instanceof LaneNode)
				node.lane=node.type;
			else
				node.lane = this.targetNode.lane;
			node.ifChildType = this.tempChildType;
			b=this.targetNode.addChild(node,this.dropableIndex);
			index = this.targetNode.childs.indexOf(node);
		}
		else
			b=this.ui.add(node);
		this.targetNode=null;
		this.dropableIndex=null;
		if(b){
			this.addCommand(new CreateNodeCommand(node,index));
			this.refresh();
			this.canvas.setActive(node.shape.group);
		}
	};
	this.moveNode = function(){
		let b=false;
	    let index=0;
		const parent=this.selectedNode.parent;
		if (parent){
			if(this.targetNode){
				this.selectedNode.parent=this.targetNode;
				if(this.selectedNode instanceof LaneNode)
					this.selectedNode.lane=this.selectedNode.type;
				else
					this.selectedNode.lane = this.targetNode.lane;
				b=this.targetNode.addChild(this.selectedNode,this.dropableIndex);
				if (!b){
					if(parent.type=='ie'){
						if(this.childType=='if')
							parent.ifChilds.splice(this.moveNodeIndex,0,this.selectedNode);
						else
							parent.elseChilds.splice(this.moveNodeIndex,0,this.selectedNode);
					}else
						parent.childs.splice(this.moveNodeIndex,0,this.selectedNode);
					this.selectedNode.parent = parent;
				} else {
					index = this.targetNode.childs.indexOf(this.selectedNode);
					this.addCommand(new MoveCommand(this.selectedNode,parent,this.moveNodeIndex,this.targetNode,this.dropableIndex,this.childType));
				}
			}else{
				this.addCommand(new MoveCommand(this.selectedNode,parent,this.moveNodeIndex,this.targetNode,this.dropableIndex,this.childType));
				if(this.selectedNode.parent.type=='ie'){
					if(this.childType=='if')
						this.selectedNode.parent.ifChilds.splice(this.moveNodeIndex,0,this.selectedNode);
					else
						this.selectedNode.parent.elseChilds.splice(this.moveNodeIndex,0,this.selectedNode);
				}else
					this.selectedNode.parent.childs.splice(this.moveNodeIndex,0,this.selectedNode);
			}
			
		}
		this.refresh();
	}
	this.addRoot = function(name,x,y,type){
		let node=window.utils.createNode(name,x,y,type);
		let b=this.ui.add(node);
		if(b){
			this.addCommand(new CreateNodeCommand(node,0));
			this.refresh();
		}
	};
	this.handleError = function(type,code){
		//handle message.
		let data=new Object();
		data.type = type;
		data.message= Messages[code];
		showNotificationMessage(data);
	};
	this.handleErrorMessage = function(type,msg){
		//handle message.
		let data=new Object();
		data.type = type;
		data.message= msg;
		showNotificationMessage(data);
	};
	this.addNode= function(x,y){
		let b=false;
		if(this.targetNode){
			this.selectedNode.parent=this.targetNode;
			b=this.targetNode.addChild(this.selectedNode);
		}else
			b=this.ui.add(this.selectedNode);
		if(b){
			if(this.selectedNode.parent){
				this.deleteNode(this.selectedNode.parent.childs,this.selectedNode);
			}else{
				this.deleteNode(this.ui.nodeList,this.selectedNode);
			}
			this.addCommand(new CreateNodeCommand(this.selectedNode));
			this.refresh();
		}
		this.selectedNode=null;
		this.targetNode=null;
	};
	this.deleteNode=function(nodeList,node){
		const index = nodeList.indexOf(node);
		if (index > -1) {
			nodeList.splice(index, 1);
		}
		this.addCommand(new DeleteNodeCommand(node,index));
		this.refresh();
		this.contextMenu.hide();
	};
	this.updateBlocks=function(targetNode,dropableIndex,toAdd=true){
		if(targetNode.minimize===1)
			return true;

		let h=0;
		if(toAdd){
			dropableIndex++;
			if (targetNode.childs.length != 0)
				h=10;
		}
		if(targetNode.parent)
			this.updateBlocks(targetNode.parent,targetNode.parent?.childs.indexOf(targetNode),toAdd);

		if (targetNode.type != 'ie'){ 
			this.updateNodeBlocks(targetNode.childs,dropableIndex,toAdd);
		} else {
			if (targetNode.childType == 'if'){
				this.updateNodeBlocks(targetNode.ifChilds,dropableIndex,toAdd);
				this.updateNodeBlocks(targetNode.elseChilds,0,toAdd);
			} else {
				this.updateNodeBlocks(targetNode.ifChilds,targetNode.ifChilds + 1,toAdd);
				this.updateNodeBlocks(targetNode.elseChilds,dropableIndex,toAdd);
			}
		}
		

		// if (targetNode.childs && targetNode.childs.length > 0){
		// 	let innerHeight = 0;
		// 	if(toAdd){
		// 		innerHeight = targetNode.shape.innerHeight + 10;
		// 	} else
		// 	innerHeight = targetNode.shape.innerHeight - 10;
		// 	targetNode.shape.setInnerHeight(this.canvas, innerHeight, targetNode, targetNode.childType);
		// }
	};
	this.updateNodeBlocks=function(targetChildNodes,dropableIndex,toAdd=true){
		for(let i = 0; i<targetChildNodes.length; i++){
			const shape= targetChildNodes[i].shape;
			if (dropableIndex <= i){
				if(toAdd){
					shape.group.set({top:shape.y + 10});
				} else
					shape.group.set({top:shape.y - 10});
				if (targetChildNodes[i].minimize!=1 && targetChildNodes[i].childs && targetChildNodes[i].childs.length > 0)
					this.updateChildBlocks(targetChildNodes[i],toAdd);
			} else {
				// console.log("======== The shape.group.top : ", shape.group.top, ", shape.y : ", shape.y);
				if((shape.group.top != shape.y) && targetChildNodes[i].type != "empty"){
					shape.group.set({top:shape.y - 10});
					if (targetChildNodes[i].minimize!=1 && targetChildNodes[i].childs && targetChildNodes[i].childs.length > 0) 
						this.updateChildBlocks(targetChildNodes[i],false);
				}
				// console.log("======== The after change shape.group.top : ", shape.group.top, ", shape.y : ", shape.y);
			}
		}
	}
	this.updateChildBlocks=function(targetNode,toAdd=true){
		for(let i = 0; i<targetNode.getChilds().length; i++){
			const shape= targetNode.childs[i].shape;
			if(toAdd){
				shape.group.set({top:shape.y + 10});
			} else
				shape.group.set({top:shape.y - 10});
			if (targetNode.childs[i].childs && targetNode.childs[i].childs.length){
				if(targetNode.childs[i].minimize && targetNode.childs[i].minimize!=1){
					this.updateChildBlocks(targetNode.childs[i],toAdd);
				}
			}
				
		}
	};
	this.render = function(){
		let previousHeight = 0;
		this.innnerHeightTemp=0;
		if(this.ui.nodeList.length>0){
			let initX=this.ui.nodeList[0].shape.x;
			let initY=this.ui.nodeList[0].shape.y;
			let mini=0;
			let y=initY;
		    this.ui.nodeList.map((component, i) => {
				if(i>0){
					if(this.ui.nodeList[i-1].minimize==1)
						y+=30;
					else
						y+=this.ui.nodeList[i-1].shape.height;
				}
				component.shape.calculateHeight(component);
				if (component.minimize==0 && component.childs && component.childs.length > 0) {
					this.renderChild(component.childs, initX+10, previousHeight+y+component.shape.tbHeight);
				}
				// let innerH=this.getCalculateHeight(component);
				let innerH = component.shape.innerHeight;
				component.shape.x=initX;
				component.shape.y=previousHeight+y;
				// component.shape.innerHeight=innerH;
   				if(component.minimize==1)
				  mini+=component.shape.height-30;
			  	previousHeight+= innerH;
		    });
		}
	};
	this.renderChild = function(nodes,initX,initY){
		let previousHeight=0;
		let y=initY;
		let mini=0;
	    nodes.map((component, i) => {
			if (component.minimize==0) {
				// component.shape.calculateHeight(component);
				if(component.childs && component.childs.length > 0)
					this.renderChild(component.childs, initX+10, previousHeight+y+component.shape.tbHeight);
				if(component.type=='ie'){
					this.renderChild(component.ifChilds, initX+10, previousHeight+y+component.shape.tbHeight);
					const ifh =component.shape.ifHeight + 20 ;
					this.renderChild(component.elseChilds, initX+10, previousHeight+y+ifh);
				}
			}
			let innerH = component.shape.height;
			component.shape.x=initX;
			component.shape.y=previousHeight+y;
			previousHeight += innerH;
			this.innnerHeightTemp = 0;
	    });
	};
	this.draw=function() {
		 this.ui.nodeList.map((node, i) => {
			 this.create(node);
			 if(node.minimize==0)
			 	this.drawChild(node.childs);
		 });
	};
	this.drawChild=function(childs) {
		 childs.map((node, i) => {
			 this.create(node);
			 if(node.minimize==0){
				this.drawChild(node.childs);
				if(node.type == 'ie'){
					this.drawChild(node.ifChilds);
					this.drawChild(node.elseChilds);
				}
			 }
		 });
	};
	this.create = function(node){
		if(node.minimize==1)
				node.shape.createMini(this.canvas,node.shape.innerHeight,node);
		else
			node.shape.create(this.canvas,node.shape.innerHeight - 20,node);
		if(node.isCommented==1){
			node.shape.group.set({opacity:0.2});
			this.canvas.renderAll();
		}
		node.addClickHandler();
	}
	this.getInnerHeight=function(component) {
	  let h=0;
	  if (component && component.minimize==0) {
		if(component.childs.length > 0){
			for (const child of component.childs) {
				if(child.minimize==0){
					h+=child.shape.height;
					if (child && child.childs.length > 0) {
						h+=this.getInnerHeightChild(child);
						h -= 20;
					}
				}else
					h+=30;
			}
		}
		if(component.type == 'ie'){
			h+=this.getInnerHeightIfElse(component);
		}	
	  }
	  return h;
	};
	this.getCalculateHeight=function(component) {
		let h = 20;
		if(component.childs.length > 0){
			h = 0;
			for (let child of component.childs) {
				h += child.shape.height;
			}
		}
		return h;
	  };
	this.getInnerHeightChild=function(component) {
		let h=0;
		if (component && component.minimize==0 && component.childs.length > 0) {
		  for (let child of component.childs) {
			if(child.minimize==0){
				h+=child.shape.height;
				if (child && child.childs.length > 0) {
					h+=this.getInnerHeightChild(child);
					h -= 20;
				}
			}else
				h+=30;
		  }
		}if(component.type == 'ie'){
			h+=this.getInnerHeightIfElse(component);
		}	
		return h;
	  };
	this.getInnerHeightIfElse = function(component){
		let h1=20;
		let h2=20;
		if(component.ifChilds.length>0){
			for(let child of component.ifChilds){
				h1+=child.shape.height;
				h1+=this.getInnerHeight(child);
			}
			component.shape.ifInnerHeight = h1 - 20;
		} else {
			component.shape.ifInnerHeight = h1;
		}
		if(component.elseChilds.length>0){
			for(let child of component.elseChilds){
				h2+=child.shape.height;
				h2+=this.getInnerHeight(child);
			}
			component.shape.elseInnerHeight = h2 - 20;
		} else {
			component.shape.elseInnerHeight = h2;
		}
		return component.shape.ifInnerHeight+component.shape.elseInnerHeight;
	}
	this.getInnerHeightOfIf = function(component){
		let h1=20;
		if(component.ifChilds.length>0){
			for(let child of component.ifChilds){
				h1+=child.shape.height;
				h1+=this.getInnerHeight(child);
			}
			component.shape.ifInnerHeight = h1 - 20;
		}
		return component.shape.ifInnerHeight;
	}
	this.getInnerHeightOfElse = function(component){
		let h1=20;
		if(component.elseChilds.length>0){
			for(let child of component.elseChilds){
				h1+=child.shape.height;
				h1+=this.getInnerHeight(child);
			}
			component.shape.elseInnerHeight = h1 - 20;
		}
		return component.shape.elseInnerHeight;
	}
	this.loadChildObject = function(node,objs){
		if(node.childs?.length>0){
			for(const child of node.childs){
				objs.push(child.shape.group);
			}
		}
	};
	this.highlightNode=function(x,y){
		this.removePlaceHolder();
		if(this.targetNode){
			this.targetNode=null;
		}
		const n = window.application.movingOnNode?window.application.movingOnNode:window.application.selectedNode;
		let node=this.ui.findNode(n,x,y);
		if(node){
			this.findPosition(node,y);
			window.application.movingOnNode=node;
		}else{
			window.application.movingOnNode=null;
		}
		
		
	};
	this.showContextMenu=function(x,y){
		this.contextMenu.show(x,y);		
	}
	this.save = function(){
		let objSave = {};
		objSave["uuid"] = this.integrationBuilderId;
		objSave["name"] = this.integrationBuilderName;
		objSave["ui"] = this.ui.toUIObject();
		objSave["object"] = this.ui.toRunTimeJSON();
		objSave["baasEnable"] = this.baasEnable;
		objSave["type"] = this.isDownloadBOS ? 5002 : 5001;
		let _urlKey = BaseURL.configurator+"file"
		let _qryString="project_name="+ (window.application.projectName || null);
		servicePostData(_urlKey,_qryString,objSave,(resp,event)=>{},this);
	}
	this.read = function(){
		let _urlKey = BaseURL.configurator+"file/document"
		let filter = encodeURI(" uuid EQ '"+this.integrationBuilderId+"'", "UTF-8");//AND name EQ '"+window.application.integrationBuilderName+"'
		let _qryString="project_name="+window.application.projectName+"&$filter="+filter;
		serviceGetData(_urlKey,_qryString,(resp,event)=>{
			if(resp.data.status.type == 'S')
			{
				//Handled undefined detail exception for the new BOS.
				if(resp.data.detail){
					this.integrationBuilderName = resp.data.detail.name;
					this.baasEnable = typeof(resp.data.detail.baasEnable) == "boolean" ? resp.data.detail.baasEnable : false;
					this.projectName = resp.data.detail?.projectName || null;
					this.ui.fromUIObject(resp.data.detail.ui);
				}
				// this.ui.readVariables();
				if(this.isDownloadBOS){
					let varType = defaultOflVariable.variableType;
					let varName = defaultOflVariable.name;
					if(!this.ui.parameter[varType]?.[varName]){
						this.ui.addJson( varName, varType, defaultOflVariable.fields );
						// this.save();
					}
				}
				window.application.refresh();
				window.application.contextMenu.load();
				// }
				if(resp.data.status.message.indexOf('E4072') !== -1){
					this.save();
				}
			}
		},this);
	}
	this.addCommand = function(command){
		this.commands.add(command);
		this.save();
	}

	this.findPositionInParent = function(n,y){
		if(n.parent){
			this.targetNode = n.parent;
			if (n.parent.type == 'ie') {
				if (y < n.parent.shape.y + (n.parent.shape.tbHeight+n.parent.shape.ifInnerHeight)-15) {
					// console.log("--------- n.parent 2nd---  ieif part");
					n.childType = 'if';
					this.tempChildType = 'if';
					const index=n.parent.ifChilds.indexOf(n);
					this.dropableIndex = index+1;
					//this.findNodePosition(n, y, n.shape.tbHeight, n.shape.ifInnerHeight, n.shape.ifHeight, 'ifChilds');
				} else {
					// console.log("--------- n.parent 2nd---  ieelse part");
					n.childType = 'else';
					this.tempChildType = 'else';
					const index=n.parent.elseChilds.indexOf(n);
					this.dropableIndex = index+1;
					// this.dropableIndex = index+1;
					//this.findNodePosition(n, y, n.shape.ifHeight + 10 , n.shape.elseInnerHeight, n.shape.elseHeight, 'elseChilds');
					
				}
			} else {
				// console.log("--------- n.parent 2nd---  else part");
				// if(y<n.parent.shape.y+n.parent.shape.tbHeight+(n.parent.shape.innerHeight/2)){
				// 	// this.dropableIndex=0;
				// 	const index=n.parent.childs.indexOf(n);
				// 	this.dropableIndex = index;
				// }else{
					const index=n.parent.childs.indexOf(n);
					this.dropableIndex = index+1;
					// this.dropableIndex=n.parent.childs.length;
				// }
				// const index=n.parent.childs.indexOf(n);
				// this.dropableIndex = index+1;
				//this.findNodePosition(n, y, n.shape.tbHeight, n.shape.innerHeight, n.shape.height, 'childs');
			}
		}
	}

	this.findPosition= function(n,y){
		this.tempChildType = null;
		if (LoopTypes.indexOf(n.type)!=-1){
			// console.log("--------- n.parent LoopTypes",n,y,n.shape.y,n.shape.tbHeight,n.shape.ifInnerHeight,n.shape.y+(n.shape.tbHeight+n.shape.ifInnerHeight))
			this.targetNode = n;
			if(n.type == 'ie'){
				if(y<n.shape.y+(n.shape.tbHeight+n.shape.ifInnerHeight-15)){
					n.childType='if';
					this.tempChildType = 'if';
					if(y<n.shape.y+n.shape.tbHeight+(n.shape.ifInnerHeight/2)-7){
						this.dropableIndex = 0
					}else{
						this.dropableIndex = n.ifChilds.length;
					}
					//const index=n.ifChilds.indexOf(n);
					//this.findNodePosition(n, y, n.shape.tbHeight, n.shape.ifInnerHeight, n.shape.ifHeight, 'ifChilds');
				}else{
					n.childType='else';
					this.tempChildType = 'else';
					if(y<n.shape.y+(n.shape.ifHeight)+(n.shape.elseHeight/2)-20){
						this.dropableIndex = 0
					}else if(y<n.shape.y+(n.shape.ifHeight)+(n.shape.elseHeight)-40){
						this.dropableIndex = n.elseChilds.length;
					}else{
						this.findPositionInParent(n,y)
					}
					//const index=n.elseChilds.indexOf(n);
					//this.findNodePosition(n, y, n.shape.ifHeight + 10 , n.shape.elseInnerHeight, n.shape.elseHeight, 'elseChilds');
				}                                                                                                                  
			}else{
				if(y<n.shape.y+(n.shape.height/2)-20){
					this.dropableIndex=0;
				}else if(y<n.shape.y+(n.shape.height)-40){
					this.dropableIndex=n.childs.length;
				}else{
					this.findPositionInParent(n,y)
				}
				//this.findNodePosition(n, y, n.shape.tbHeight, n.shape.innerHeight, n.shape.height, 'childs');
			}
		}else if (n.parent){
			this.findPositionInParent(n,y)
		}
		else{
			// console.log("--------- n.parent total else part ",n);
			this.targetNode = n;
			if (n.childs.length > 0){
				if(y<n.shape.y+(n.shape.height/2))
					this.dropableIndex = 0;	
				else
					this.dropableIndex = n.childs.length;
			}else{
				this.dropableIndex = 0;	
			}
		}
		this.addPlaceHolder();
	}

	this.findNodePosition = function(n, y, h1, h2, h3, key){
		let parentKey = 'childs';
		if (n.parent && n.parent.type == 'ie') {
			if(n.ifChildType == 'if')
				parentKey = 'ifChilds';
			else
				parentKey = 'elseChilds';
		} else {
			parentKey = 'childs';
		}
		if (n.parent){
			// console.log("----------- test 0 : ");
			if (y<n.shape.y+(h1 - 5)){
				// console.log("----------- test 1 : ", n.shape.y+(h1 - 10));
				this.targetNode = n.parent;
				this.dropableIndex = n.parent[parentKey].indexOf(n);
			} else if (y < n.shape.y + h1 + h2){
				// console.log("----------- test 2 : ", n.shape.y + h1 + h2);
				if(y < n.shape.y + (h1 + ( h2 / 2 )))
					this.dropableIndex=0;	
				else
					this.dropableIndex=n[key].length;
			} else{
				// console.log("----------- test 3 : ", n.shape.y + h3);
				this.targetNode = n.parent;
				this.dropableIndex = n.parent[parentKey].indexOf(n) + 1;
				
			}
			// console.log("----------- test 6 : ");
		} else {
			// console.log("----------- test 4");
			if(y<n.shape.y+(h3)/2)
				this.dropableIndex=0;	
			else
				this.dropableIndex=n[key].length;
		}
		// console.log("---------  this.targetNode : ", this.targetNode, ', this.dropableIndex : ', this.dropableIndex);
	}

	this.findMovePosition = function(n,y){
		let list = this.ui.nodeList;
		this.findedPosition = false;
		this.calculateNode = false;
		for (let index in list){
			let child = list[index];
			if (child.childs){
				this.findChildMovePosition(child.childs, y);
			}
			if  (!this.findedPosition ){
				this.targetNode = child;
				this.dropableIndex = Number.parseInt(child.childs.length) + 1;
				this.findedPosition = true;
			}
		}
		this.addPlaceHolder();
	}

	this.findChildMovePosition = function(n,y){
		for (let index in n){
			if (!this.findedPosition){
				let child = n[index];
				if (Number.parseInt(index) == (n.length - 1)){
					this.targetNode = child.parent;
					if (child.type != 'ie')
						child.shape.height = child.shape.tbHeight + child.shape.innerHeight + 20;
					else
						child.shape.calculateHeight(child);
					this.findLoopPosition(child, y, index, n);
					return;
				} else if ((child.shape.y <= y) && (n[Number.parseInt(index) + 1].shape.y >= y)){
					if (child.type != 'ie')
						child.shape.height = child.shape.tbHeight + child.shape.innerHeight + 20;
					else
						child.shape.calculateHeight(child);
					this.findLoopPosition(child, y, index, n);
					return;
				}
			}
		}
	}

	this.fundIfElsePosition = function(child, y, index, n){
		if (child.shape.y + 20 >= y){
			this.targetNode = child.parent;
			this.dropableIndex = Number.parseInt(index);
			this.findedPosition = true;
		} else if ((child.shape.y + child.shape.height - 20) <= y) {
			this.targetNode = child.parent;
			this.dropableIndex = Number.parseInt(index) + 1;
			this.findedPosition = true;
		} else {
			if (child.shape.y + child.shape.ifHeight > y) {

				if (child.ifChilds && child.ifChilds.length > 0){
					this.tempChildType = 'if'; 
					child.childType='if';
					this.findChildMovePosition(child.ifChilds, y);
				} else {
					this.targetNode = child;
					this.dropableIndex = 0;
					child.childType='if';
					this.tempChildType = 'if';
					this.findedPosition = true;
				}
			} else {
				if (child.elseChilds && child.elseChilds.length > 0) {
					this.tempChildType = 'else';
					child.childType='else';
					this.findChildMovePosition(child.elseChilds, y);
				} else {
					this.targetNode = child;
					this.dropableIndex = 0;
					child.childType='else';
					this.tempChildType = 'else';
					this.findedPosition = true;
				}
			}
			this.tempChildType = null;
		}
	}

	this.findLoopPosition = function(child, y, index, n){
		if (LoopTypes.indexOf(child.type)!=-1){
			if (child.shape.y + 15 >= y){
				this.targetNode = child.parent;
				this.dropableIndex = Number.parseInt(index);
				this.findedPosition = true;
			} else if ((child.shape.y + child.shape.height - 15) <= y){
				this.targetNode = child.parent;
				this.dropableIndex = Number.parseInt(index) + 1;
				this.findedPosition = true;
			} else {
				if (child.type == 'ie') { 
					this.fundIfElsePosition(child, y, index, n);
				}else if (child.childs && child.childs.length > 0){
					this.findChildMovePosition(child.childs, y);
				} else {
					this.targetNode = child;
					this.dropableIndex = 0;
					this.findedPosition = true;
				}
			}
		} else if  (child.shape.y + child.shape.height >= y){
			this.targetNode = child.parent;
			if ((child.shape.y + (child.shape.height / 2)) > y){
				this.dropableIndex = Number.parseInt(index);
			} else {
				this.dropableIndex = Number.parseInt(index) + 1;
			}
			this.findedPosition = true;
		}
	}

	this.getCustomBlockDetails = function(){
		let _urlKey = BaseURL.configurator+"customblock"
		let _qryString="project_name="+this.projectName;
		serviceGetData(_urlKey,_qryString,(resp,event)=>{
			if(resp.data.status.type == 'S')
			{
				this.customBlockDetails = []
				for(let x of resp.data.listData)
				{
					let obj={};
					obj["name"]=x.map.name;
					obj["jarName"]=x.map.jarName;
					obj["actions"]=[];
					for(let y of x.map.actions.myArrayList)
					{
						let _obj = {};
						_obj["img"] = y.map.img;
						_obj["meta"] = JSON.parse(y.map.meta);
						if(!Array.isArray(_obj.meta.Input.field))
						{
							let arr = [];
							arr.push(_obj.meta.Input.field);
							_obj.meta.Input.field = arr;
						}
						if(!Array.isArray(_obj.meta.Output.field))
						{
							let arr = [];
							arr.push(_obj.meta.Output.field);
							_obj.meta.Output.field = arr;	
						}
						_obj["name"] = y.map.name;
						_obj["clazz"] = y.map.clazz;
						obj.actions.push(_obj);
					}
					this.customBlockDetails.push(obj);
				}
				if(this.customBlockDetails.length > 0){
					this.appendCategory();
					this.appendBlockImage();
				}
			}
			window.application.read();
		},this);
	}
	this.appendCategory = function(){
		for(let obj of this.customBlockDetails)
		{
			let temp = {};
			temp["name"] = obj.name;
			temp["type"] = obj.name;
			temp["iconImage"] = '/json/integrationbuilder/images/accordionicons/icon14.svg';
			temp["blocks"] = [];
			for(let y of obj.actions)
			{
				let _obj = {
					"name": y.name,
					"type": "custom",
					"image": "/json/integrationbuilder/images/customblock.svg",
					"caption": y.name
				}
				temp.blocks.push(_obj);
			}
			this.addLeftPanelNewCategory(temp);			
		}
	}

	this.appendBlockImage = function(){
		for(let obj of this.customBlockDetails)
		{
			let temp = {};
			for(let y of obj.actions)
			{
				let _obj = {
					"large": this.customImage,
					"small": "data:image/svg+xml;base64," + y.img,
					"dynamic": true
				}
				temp[y.name.toLowerCase().replaceAll(' ', '')] = _obj;
			}
			this.blockImages[obj.name.toLowerCase().replaceAll(' ', '')] = temp;			
		}
	}

	this.getPluginOrSystem = function(type,node)
	{
		let i =0;
		let _node = node;
		let retval = "";
		while(i<500)
		{
			if(_node instanceof LaneNode && _node.nodeName == "spr")
			{
				if(type=='plugin')
					retval = _node.plugin;
				else if(type=='system')
					retval = _node.system;
				else
					retval = '';
				break;
			}else{
				_node = _node.parent;
			}
			if(_node == null)
				break;
			i++	
		}
		return retval;
	}
}

function UIObject(){
		this.nodeList=new Array();
		this.system = new Array();
		this.plugin = new Array();
		this.tables = new Array();
		this.services = new Array();
		this.parameter = new Parameter();
		this.sp  = [];
		this.addSystem = function(sysname){
			if(sysname && !this.system.includes(sysname))
				this.system.push(sysname);
		}
		this.removeSystem = function(sysname){
			if(sysname && this.system.includes(sysname)){
				// this.system.push(sysname);
				const index = this.system.indexOf(sysname);
				if (index > -1) {
					this.system.splice(index, 1);
				}
			}
		}
		this.addServices = function(sysname){
			if(sysname && !this.services.includes(sysname))
				this.services.push(sysname);
		}
		this.removeServices = function(sysname){
			if(sysname && this.services.includes(sysname)){
				const index = this.services.indexOf(sysname);
				if (index > -1) {
					this.services.splice(index, 1);
				}
			}
		}
		this.addPlugin = function(sysname){
			if(sysname && !this.plugin.includes(sysname))
				this.plugin.push(sysname);
		}
		this.removePlugin = function(sysname){
			if(sysname && this.plugin.includes(sysname)){
				// this.system.push(sysname);
				const index = this.plugin.indexOf(sysname);
				if (index > -1) {
					this.plugin.splice(index, 1);
				}
			}
		}
		this.addTable = function(tablename){
			if(tablename && !this.tables.includes(tablename))
				this.tables.push(tablename);
		}
		this.removeTable = function(tablename){
			if(tablename && this.tables.includes(tablename)){
				const index = this.tables.indexOf(tablename);
				if (index > -1) {
					this.tables.splice(index, 1);
				}
			}
		}
		this.addParam = function(name, variable, datatype,type,oldName, mapping){
			if (window.application.baasEnable){
				this.parameter.createOrUpdateVariable(name, variable,datatype, type,oldName, mapping);
			}
			else if(!this.parameter[variable].hasOwnProperty(name))
				return this.parameter.createOrUpdateVariable(name, variable,datatype, type,oldName, mapping);
			else if (variable == 'Local'){
				this.parameter.createOrUpdateVariable(name, variable,datatype, type,oldName, mapping);
			}
		};
		this.addJson = function(name,variable,fields,oldName, mapping){
			if (window.application.baasEnable){
				this.parameter.createNewTable(name,variable,fields,oldName, mapping);
			}
			else if(!this.parameter[variable].hasOwnProperty(name))
				this.parameter.createNewTable(name,variable,fields,oldName, mapping);
			else if (variable == 'Local'){
				this.parameter.createNewTable(name,variable,fields,oldName, mapping);
			}
		}
		this.updateJson = function(name,variable,fields,oldName){
			this.parameter.createNewTable(name,variable,fields,oldName);
		}
		this.remove =function(name,variable){
			this.parameter.deleteParam(name,variable);
		};
		this.add=function(node){
			if(node instanceof LaneNode && this.nodeList.length==0){
				this.nodeList.push(node);
				node.lane=node.type;
				this.type =(node.type=='rc'?5002:5003);
				return true;
			}else{
				window.application.handleError("Error","E001");
			}
			return false;
		};
		this.findNode = function(node,x,y){
		    let nodeFound;
			//const node = this.nodeList[0];
			// console.log("findNode", this.nodeList, node, this.nodeList[0])
		  	const s = node.shape;
			if (s.x <= x && x <= s.x +s.width && s.y <= y && y <= s.y + s.height) {
				if (node?.childs?.length > 0) {
					nodeFound = this.findChildNode(x,y,node.childs);
				}
				if (!nodeFound && node instanceof IfElseNode){
					nodeFound= this.findChildNode(x,y,node.ifChilds);
					if(!nodeFound)
						nodeFound=this.findChildNode(x,y,node.elseChilds);
				}
				if(!nodeFound)
					nodeFound=node;
			}else if(node.parent){
				nodeFound = this.findNode(node.parent,x,y)
			}
		    return nodeFound;
		};
		this.findChildNode = function(x,y,childs){
		  let nodeFound;
		  if(!Array.isArray(childs)){
			childs = [childs];
		  }
		  for (const node of childs) {
			const s = node.shape;
			if (s.x <= x && x <= s.x +s.width && s.y <= y && y <= s.y + s.height) {
				if (node && node.childs && node.childs.length > 0) {
					nodeFound = this.findChildNode(x,y,node.childs);
				}
				if(!nodeFound && node instanceof IfElseNode){
					nodeFound= this.findChildNode(x,y,node.ifChilds);
					if(!nodeFound)
						nodeFound=this.findChildNode(x,y,node.elseChilds);
				}
				// console.log(nodeFound);
				if(!nodeFound)
					nodeFound=node;
			}
			if(nodeFound)
					break;
		  }
		  return nodeFound;
		};
		this.toUIObject = function(){
			let _json = {};
			_json["parameters"]=this.parameter;
			_json["parameters"]["system"] = this.system;
			_json["parameters"]["services"] = this.services;
			_json["parameters"]["plugin"] = this.plugin;
			_json["parameters"]["tables"] = this.tables;
			_json["ui"]=[];
			for(let val of window.application.ui.nodeList)
			{
				let obj = {};
				obj[val.type]={}
				obj[val.type]["caption"]=val.name;
				obj[val.type]["_type"]=val.type;
				if(val.hasOwnProperty("uiRenderTags"))
					obj[val.type]["details"]=val.toJSON();
				obj[val.type]["childs"]=[];
				_json["ui"].push(obj);
				if(val.childs.length > 0){
					this.addChildUI(obj[val.type]["childs"],val.childs)
				}
			}
			return _json;
		};
		this.addChildUI = function(lhs,rhs)
		{
			for(let i=0;i<rhs.length;i++)
			{
				let obj = {}
				obj[rhs[i].type]={}
				obj[rhs[i].type]["caption"]=rhs[i].name;
				obj[rhs[i].type]["_type"]=rhs[i].type;
				obj[rhs[i].type]["parameter"]=rhs[i].parameter;
				if(rhs[i].hasOwnProperty("uiRenderTags"))
					obj[rhs[i].type]["details"]=rhs[i].toJSON();
				obj[rhs[i].type]["childs"]=[];
				if(rhs[i] instanceof IfElseNode)
					obj[rhs[i].type]["elsechilds"]=[];
				lhs.push(obj)
				if(rhs[i] instanceof IfElseNode)
				{
					if(rhs[i].ifChilds.length > 0){
						this.addChildUI(lhs[i][rhs[i].type]["childs"],rhs[i].ifChilds)
					}
					if(rhs[i].elseChilds.length > 0){
						this.addChildUI(lhs[i][rhs[i].type]["elsechilds"],rhs[i].elseChilds)
					}
				}else{
					if(rhs[i].childs.length > 0){
						this.addChildUI(lhs[i][rhs[i].type]["childs"],rhs[i].childs)
					}
				}
			}
		};
		this.toRunTimeJSON = function(){
			let _json = [];
			this.sp = [];
			for(let val of window.application.ui.nodeList)
			{
				let obj = {};
				if(val.constructParams)
					val.constructParams();
				obj[val.nodeName]={}
				obj[val.nodeName]['name']=val.nodeName;
				obj[val.nodeName]['type']=val.type;
				this.type =(val.type=='rc'?5002:5003);
				obj[val.nodeName]['metadata']='';
				obj[val.nodeName]['isCommented']=val.isCommented;
				obj[val.nodeName]["input"] = window.utils.toJSON(val.parameter.Input,false);
				obj[val.nodeName]["output"] = window.utils.toJSON(val.parameter.Output,false);
				obj[val.nodeName]["local"] = window.utils.toJSON(val.parameter.Local,false);
				obj[val.nodeName]["child"]=[];
				_json.push(obj);
				if(val.childs.length > 0){
					this.addChildParams(obj[val.nodeName]["child"],val.childs)
				}
			}
			let runJSON = _json[0];
			const params = this.parameter.toObject(true);
			let temp = {};
			temp["input"] = params.Input;
			temp["output"] = params.Output;
			temp["local"] = params.Local;
			temp["env"] = params.Environment;
			temp["system"] = this.system;
			temp["tables"] = this.tables;
			temp["sp"] = this.sp;
			temp["type"] = this.type;
			temp["system"] = this.system;
			temp["tables"] = this.tables;
			temp = { ...temp, ...runJSON };
			return temp;
			//return _json;
		}
		this.addChildParams = function(lhs,rhs)
		{
			for(let i=0;i<rhs.length;i++)
			{
				let obj = {}
				if(rhs[i].constructParams)
					rhs[i].constructParams();
				obj[rhs[i].nodeName]={}
				obj[rhs[i].nodeName]['name']=rhs[i].nodeName;
				obj[rhs[i].nodeName]['type']=rhs[i].type;
				obj[rhs[i].nodeName]['metadata']='';
				obj[rhs[i].nodeName]['isCommented']=rhs[i].isCommented;
				if(rhs[i].nodeName == "spr")
				{
					obj[rhs[i].nodeName]['plugin']=rhs[i].plugin;
					obj[rhs[i].nodeName]['connection']=rhs[i].connection;
				}	
				obj[rhs[i].nodeName]["input"] = window.utils.toJSON(rhs[i].parameter.Input,false);
				obj[rhs[i].nodeName]["output"] = window.utils.toJSON(rhs[i].parameter.Output,false);
				obj[rhs[i].nodeName]["local"] = window.utils.toJSON(rhs[i].parameter.Local,false);
				obj[rhs[i].nodeName]["child"]=[];
				if(rhs[i].nodeName == "catalog")
					obj[rhs[i].nodeName]["clazz"]=rhs[i].clazz;
				if(rhs[i] instanceof IfElseNode)
					obj[rhs[i].nodeName]["elsechild"]=[];
				lhs.push(obj)
				if(rhs[i] instanceof IfElseNode){
					if(rhs[i].ifChilds.length > 0){
						this.addChildParams(lhs[i][rhs[i].nodeName]["child"],rhs[i].ifChilds)
					}
					if(rhs[i].elseChilds.length > 0){
						this.addChildParams(lhs[i][rhs[i].nodeName]["elsechild"],rhs[i].elseChilds)
					}
				}else{
					if(rhs[i].childs.length > 0){
						this.addChildParams(lhs[i][rhs[i].nodeName]["child"],rhs[i].childs)
					}
				}
				
				if(rhs[i].nodeName == "spr")
				{
					let sp_name = "SP_"+this.sp.length;
					lhs[i][rhs[i].nodeName]['name'] = sp_name;
					lhs[i][rhs[i].nodeName]['runoncloud'] = this.getRunOnCloudObject();
					lhs[i][rhs[i].nodeName]['runoncloud']['child'] = lhs[i][rhs[i].nodeName]['child'];
					lhs[i][rhs[i].nodeName]['child'] = [];
					this.sp.push(lhs[i][rhs[i].nodeName])
					lhs[i][rhs[i].nodeName]={};
					lhs[i][rhs[i].nodeName]["name"] = sp_name; 
					lhs[i][rhs[i].nodeName]["isCommented"] = rhs[i].isCommented;
				}
			}
		}
		this.getRunOnCloudObject = ()=>{
			return {'name': 'runoncloud','type': 'rc','metadata': '','isCommented': false,'input': {'field': [],'table': []},'output': {'field': [],'table': []},'child': []};
		}
		this.readVariables = function(){
			//Reading Business Function or Bird Side view Variables
			let _urlKey =""
			if(window.application.from && window.application.from=='BirdsEyeView')
				_urlKey = BaseURL.configurator+"variables/pf/"+window.application.userTaskName
			else
				_urlKey = BaseURL.configurator+"variables/bf/"+window.application.businessFunctionName
				
			let _qryString="project_name="+window.application.projectName;
			serviceGetData(_urlKey,_qryString,(resp,event)=>{
				if(resp.data.status.type == 'S')
				{
					let _data = JSON.parse(resp.data.object);
					_data = _data.variable;
					//let _data = [{"name": "Counter1","type": "Text","value": "jjjkk"}];
					for(let _x of _data)
					{
						if(_x.type==="Table")
						{
							this.addJson(_x.name,"Input",(_x.json == null || _x.json == undefined )?[]:_x.json,"");
							this.addJson(_x.name,"Output",(_x.json == null || _x.json == undefined )?[]:_x.json,"");
						}else{
							this.addParam(_x.name, "Input",_x.type,_x.type,"")
							this.addParam(_x.name, "Output",_x.type,_x.type,"")
						}
					}
					window.application.reload();
				}
			},this);
		}
		this.fromUIObject = function(data){
			let _obj = JSON.parse(data);
			const _p = _obj.parameters;
			_obj = _obj.ui;
			this.parameter = Object.assign(new Parameter(),JSON.parse(JSON.stringify(_p)));
			this.system = JSON.parse(JSON.stringify(_p))['system'];
			this.services = JSON.parse(JSON.stringify(_p))['services'];
			this.plugin = JSON.parse(JSON.stringify(_p))['plugin'];
			this.plugin = this.plugin == undefined || this.plugin == null ? [] : this.plugin;
			this.services = this.services == undefined || this.services == null ? [] : this.services;
			this.tables = JSON.parse(JSON.stringify(_p))['tables'];
			window.application.reload();
			for(let x in _obj){
				let _temp = _obj[x][Object.keys(_obj[x])[0]];
				let n = window.utils.createNode(_temp.caption,100,30,_temp._type);
				this.add(n);
				if(Object.keys(_temp.details).length > 0)
					n.fromJSON(_temp.details);
				for(let y in _temp.childs)
					this.childRender(n,_temp.childs[y][Object.keys(_temp.childs[y])[0]],"childs");
			}
			window.application.refresh();
		}
	
		this.childRender = function(node,child,type){
			let n = window.utils.createNode(child.caption,100,30,child._type);
			if(Object.keys(child.details).length > 0)
				n.fromJSON(child.details);
			n.parent = node;
			node[type].push(n);
			if(n instanceof IfElseNode){
				for(let y in child.childs)
					this.childRender(n,child.childs[y][Object.keys(child.childs[y])[0]],"ifChilds");
				for(let y in child.elsechilds)
					this.childRender(n,child.elsechilds[y][Object.keys(child.elsechilds[y])[0]],"elseChilds");
			}
			else{
				for(let y in child.childs)
					this.childRender(n,child.childs[y][Object.keys(child.childs[y])[0]],"childs");
			}
		}
}

function Parameter() {
	this.Input = new Object();
	this.Output = new Object();
	this.Local = new Object();
	this.Environment = new Object();
	var HOSTUSER = new Object();
	HOSTUSER["Xpath"] =  "BOS$Environment$HOSTUSER";
	HOSTUSER["Expression"] = "";
	HOSTUSER["dataType"] = "String";
	HOSTUSER["Description"] = "HOSTUSER";
	HOSTUSER["name"] = "HOSTUSER";
	HOSTUSER["type"] = "String";

	var APPNAME = new Object();
	APPNAME["Xpath"] =  "BOS$Environment$APPNAME";
	APPNAME["Expression"] = "";
	APPNAME["dataType"] = "String";
	APPNAME["Description"] = "APPNAME";
	APPNAME["name"] = "APPNAME";
	APPNAME["type"] = "String";

	var LOGINID = new Object();
	LOGINID["Xpath"] =  "BOS$Environment$LOGINID";
	LOGINID["Expression"] = "";
	LOGINID["dataType"] = "String";
	LOGINID["Description"] = "LOGINID";
	LOGINID["name"] = "LOGINID";
	LOGINID["type"] = "String";

	var SSOTOKEN = new Object();
	SSOTOKEN["Xpath"] =  "BOS$Environment$SSOTOKEN";
	SSOTOKEN["Expression"] = "";
	SSOTOKEN["dataType"] = "String";
	SSOTOKEN["Description"] = "SSOTOKEN";
	SSOTOKEN["name"] = "SSOTOKEN";
	SSOTOKEN["type"] = "String";

	var SYNCDATETIME = new Object();
	SYNCDATETIME["Xpath"] =  "BOS$Environment$SYNCDATETIME";
	SYNCDATETIME["Expression"] = "";
	SYNCDATETIME["dataType"] = "String";
	SYNCDATETIME["Description"] = "SYNCDATETIME";
	SYNCDATETIME["name"] = "SYNCDATETIME";
	SYNCDATETIME["type"] = "String";
	
	var RESPONSE = new Object();
	RESPONSE["Xpath"] =  "BOS$Environment$RESPONSE";
	RESPONSE["Expression"] = "";
	RESPONSE["dataType"] = "Table";
	RESPONSE["Description"] = "RESPONSE";
	RESPONSE["name"] = "RESPONSE";
	RESPONSE["type"] = "Table";

	let childElements = [{key: 'Success',value: 'Success'},{key:'MsgType',value:'MsgType'},{key:'Message',value:'Message'}];
	let flds = new Array();
	for(const child of childElements){
		let obj = {
			'name': child.key,
			default: child.value,
			Xpath: `BOS$Environment.$RESPONSE$${child.key}`,
			'key': child.key,
			'dataType': 'String'
		}
		flds.push(obj);
	}
	RESPONSE['field']=flds;

	this.Environment["HOSTUSER"] = HOSTUSER;
	this.Environment["APPNAME"] = APPNAME;
	this.Environment["LOGINID"] = LOGINID;
	this.Environment["SSOTOKEN"] = SSOTOKEN;
	this.Environment["SYNCDATETIME"] = SYNCDATETIME;
	this.Environment["RESPONSE"] = RESPONSE;

	this.metadata = "";
}

Parameter.prototype = {
	setDefaultMetaData : function() {
		this.metadata = "";
	},
	createOrUpdateVariable: function(name, variable, dataType,type,oldName, mapping) {
		this.deleteParam(oldName,variable);
		this[variable][`${name}`] = {
			Xpath: `BOS$${variable}$${name}`,
			Expression: '',
			dataType: dataType,
			Description: name,
			type : type,
			name,
			mapping
		}
		return this[variable][`${name}`];
	},
	createNewTable: function(name,variable,fields,oldName, mapping){
		let oldFields = this[variable][name] ? this[variable][name].field : [];
		this.deleteParam(oldName,variable);
		this[variable][name] = {
			Xpath : `BOS$${variable}.$${name}`,
			type : 'Table',
			name,
			mapping
		}
		let flds = new Array();
		for(let i=0;i<fields.length;i++){
			const key = `${fields[i].key}`;
			let obj = null;
			let oldObj = null;
			for(let j=0;j<oldFields.length;j++){
				if (oldFields[j].name == key){
					oldObj = oldFields[j];
					break;
				}
			}
			if (obj == null) {
				obj = {
					'name' : key,
					default : fields[i].dataType ? fields[i].dataType : "String",
					Xpath : `BOS$${variable}.$${name}$${fields[i].key}`,
					'key':key,
					'isPrimary':fields[i].isPrimary,
					'isIndex':fields[i].isIndex,
					'dataType': fields[i].dataType ? fields[i].dataType : "String",
					'value' : oldObj?.value
				}
			}
			flds.push(obj);
		}
		this[variable][name]['field']=flds;
	},
	addParam : function(object,variable,name) {
		this[variable][`${name}`] =object;
	},
	addFilter : function(name, filter) {
		this['Input'][`${name}`]['filter'] = filter;
	},
	updateParam : function(paramNameToBeUpdated, selectedNodexpath, datatype,
			description) {
	},
	deleteParam : function(name,variable) {
		delete this[variable][`${name}`];
	},
	set : function(obj, isGlobal) {
		this.Input = window.utils.fromJSON(obj.Input, isGlobal);
		this.Output = window.utils.fromJSON(obj.Output, isGlobal);
		this.Local = window.utils.fromJSON(obj.Local, isGlobal);
		//this.Environment = window.utils.fromJSON(obj.Environment, isGlobal);
		//this.metadata = obj.metadata;
	},
	toObject : function(isGlobal) {
		var obj = new Object();
		obj.Input = window.utils.toJSON(this.Input, isGlobal);
		obj.Output = window.utils.toJSON(this.Output, isGlobal);
		obj.Local = window.utils.toJSON(this.Local, isGlobal);
		obj.Environment = window.utils.toJSON(this.Environment, isGlobal);
		//obj.metadata = this.metadata;
		return obj;
	},
	update : function(name, oldvalue, newvalue) {
		if (this.Input && this.Input[name]
				&& this.Input[name]["Expression"] == "\"" + oldvalue + "\"") {
			var obj = this.Input[name];
			obj.Expression = "\"" + newvalue + "\"";
			obj.value = "LIT(" + newvalue + ")";
		}
	},
	getValue : function(name) {
		if (this.Input && this.Input[name]) {
			var obj = this.Input[name];
			return obj.Expression;
		}
		return null;
	},
	copy : function() {
		var cpyparameter = new Parameter();
		var newInputStr = JSON.stringify(this.Input);
		var newInputMap = JSON.parse(newInputStr);
		var newOutputStr = JSON.stringify(this.Output);
		var newOutputMap = JSON.parse(newOutputStr);
		var newLocalStr = JSON.stringify(this.Local);
		var newLocalMap = JSON.parse(newLocalStr);
		var newEnvironmentStr = JSON.stringify(this.Environment);
		var newEnvironmentMap = JSON.parse(newEnvironmentStr);
		cpyparameter.Input = newInputMap;
		cpyparameter.Output = newOutputMap;
		cpyparameter.Local = newLocalMap;
		cpyparameter.Environment = newEnvironmentMap;
		cpyparameter.metadata = this.metadata;

		return cpyparameter;
	},
	getTables : function() {
		let arr=new Array();
		this.listTables(arr,this.Output,"Output");
		this.listTables(arr,this.Local,"Local");
		return arr;
	},
	listTables : function(arr,object,type){
		for(const key in object){
			const obj = object[key];
			if(obj.type == 'Table'){
				const o={
					name : key,
					value : obj.Xpath,
					type : type,
					isTable : true
				};
				arr.push(o);
			}
		}
	},
	getColumns : function(obj){
		let arr = new Array();
		if(this[obj.type][obj.name]){
			const fields = this[obj.type][obj.name].field;
			if (fields && Array.isArray(fields) && fields.length > 0){
			for(const fld of fields){
				const o={
					name : fld.name,
					xpath : fld.Xpath
				};
				arr.push(o);
			}
		}
		}
		return arr;
	},
	checkTable: function(object,type){
		type = type.indexOf('.') == -1 ? type : type.substring(0, type.indexOf('.'));
		return this[type][object] && this[type][object]['type'] == 'Table'? true: false;
	},
	setTableFieldValue : function(xpath, value){
		let setVal = xpath.split('$');
		let setType = setVal[1].endsWith('\.')?setVal[1].substring(0,setVal[1].length-1):setVal[1];
		if (this[setType][setVal[2]]){
			let check = false;
			if (this[setType][setVal[2]].field && Array.isArray(this[setType][setVal[2]].field)){
				for (let i in this[setType][setVal[2]].field){
					if (xpath == this[setType][setVal[2]].field[i].Xpath){
						this[setType][setVal[2]].field[i].value = value;
						check = true;
					}
				}
				if (!check){
					this[setType][setVal[2]].field.push(window.utils.constructObjectWithoutParse(xpath, value));
				}
			}
			else{
				this[setType][setVal[2]].field = [window.utils.constructObjectWithoutParse(xpath, value)];
			}
		}
	},
	checkFields: function(xpath){
		let setVal = xpath.split('$');
		let setType = setVal[1].endsWith('\.')?setVal[1].substring(0,setVal[1].length-1):setVal[1];
		let check = true;
		if (this[setType][setVal[2]]){
			if (this[setType][setVal[2]].field){
				for (let i in this[setType][setVal[2]].field){
					if (this[setType][setVal[2]].field[i].name == setVal[3]){
						check = false;
						break;
					}
				}
			}
			else
				this[setType][setVal[2]].field = [];
			if (check)
				this[setType][setVal[2]].field.push(window.utils.constructObject(xpath, ''));
		}
	}
};

function Loader() {
	this.element = document.getElementById("loadingImage");
	this.element.style.display = "none";
}
Loader.prototype.show = function() {
	this.element.style.display = "block";
};
Loader.prototype.hide = function() {
	this.element.style.display = "none";
};

function Metadata() {
	this.input = new Object();
	this.output = new Object();
}

class AudioPlayer {
  constructor() {
    this.element = document.getElementById("sound");
    if (!this.element) {
      this.element = document.createElement("audio");
      this.element.setAttribute("id", "sound");
      const source = document.createElement("source");
      source.setAttribute("src", clickAudio);
      source.setAttribute("type", "audio/mpeg");
      this.element.appendChild(source);
      document.body.append(this.element);
    }
  }
  play() {
    this.element.play();
  }

  get muted() {
    return this.element.muted;
  }
  set muted(params) {
    this.element.muted = params;
  }
}


export{
	Application,
	UIObject,
	Metadata,
	Parameter,
	Loader
}
